import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import { ReactComponent as HyperFlowAlert } from "../../../../Assets/alerts/hyperFlowAlerts.svg";
import { ReactComponent as DownloadIconFinal } from "../../../../Assets/common/images/iconDownload.svg";

import {
  emptyCurrentAttatchment,
  getAttatchmentData,
  selectCurrentAttatchment,
  selectCurrentRegistration,
  selectAttatchmentsLoadingStatus,
  selectAttatchmentDownloadingStatus,
} from "../../coreid/coreIdSlice";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as TrashCan } from "../../../../Assets/common/images/trashCan.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import Modal, { Styles } from "react-modal";
import { TitleSubtitleBox } from "../../components/Squares";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { ExternalQueryResult, RegistrationDetail } from "../../coreid/coreIdAPI";
import { Attatchment, FilledForm } from "../../magicforms/magicFormsAPI";
import {
  BiometryImages,
  DocumentImages,
  Generalmages,
} from "./components/CoreIdImages";
import { getWholeName } from "./components/helpers/utils";
import { LoadingStatus } from "../../../common/commonSlice";
import { UnifiedAlerts } from "../../alerts/alertsAPI";
import fileDownload from "js-file-download";
import {
  changeCurrentFlowStatus,
  changeStatusById,
  getFlowById,
  getFlowFilledFormPDF,
  getRevisionMode,
  resetFlow,
  changeIndex,
  selectCurrentFlow,
  selectDocumentLoadingFlow,
  selectFlowFiltered,
  selectIndex,
  selectLoadingFlowStatus,
  setIsFlowDetail,
  changePageState,
  selectCurrentPageHyperFlow,
  selectFlowFilteredRevision,
  retryStepManual} from "../hyperFlowSlice";
import { StateComponent } from "./components/StateComponent";
import { ExpandibleComponent } from "./ExpandibleComponent";
import { ExecutedStep } from "../hyperFlowAPI";
import { File } from "../../firma/firmaAPI";
import { ExpandibleComponentList } from "./ExpandibleComponentList";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "40%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const customStylesListDetail: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "25%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");
export const Flowv2 = () => {

  let loadingStatus = useAppSelector(selectLoadingFlowStatus);
  let documentLoading = useAppSelector(selectDocumentLoadingFlow);
  let currentFlow = useAppSelector(selectCurrentFlow);

  let loadingAttatchments = useAppSelector(selectAttatchmentsLoadingStatus);
  let downloadingAttatchment = useAppSelector(
    selectAttatchmentDownloadingStatus
  );
  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let currentAttatchment = useAppSelector(selectCurrentAttatchment);
  let flows = useAppSelector(selectFlowFilteredRevision);
  let index = useAppSelector(selectIndex);
  let currentPage = useAppSelector(selectCurrentPageHyperFlow);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { id } = useParams();
  const [idRevisionCurrent, setidRevisionCurrent] = useState("");



  const [certificado, setCertificado] = useState(false);
  const [servicealerts, setservicealerts] = useState<string[]>([]);
  const [servicealertsn, setservicealertsn] = useState(false);
  const [modoRevision, setmodoRevision] = useState(false);

  useEffect(() => {


    if (id === "flowsrevision") {
      if (!modoRevision || !flows) {
        setmodoRevision(true);
        dispatch(getRevisionMode());
      }

    }

    if ((!currentFlow || (id !== "flowsrevision" && currentFlow.id !== id) || (id === "flowsrevision" && currentFlow.id !== idRevisionCurrent)) && id !== "") {
      if (id === "flowsrevision") {

        if (flows && flows[index] && flows[index].id) {
          let cur = flows[index].id;
          setidRevisionCurrent(cur);
          dispatch(getFlowById(cur || ""));
        }

      } else {
        dispatch(getFlowById(id || ""));
      }

    }

    if (currentAttatchment.fileBase64 !== "") {
      let url = `data:application/pdf;base64,${currentAttatchment.fileBase64}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, currentAttatchment.fileName);
        });
      dispatch(emptyCurrentAttatchment());
    }
    if (currentFlow && currentFlow?.registrationAlerts && currentFlow?.registrationAlerts.alerts && !servicealertsn) {

      let temp = servicealerts;
      for (var i = 0; i < currentFlow?.registrationAlerts.alerts.length; i++) {
        if (!temp.includes(currentFlow?.registrationAlerts.alerts[i].service)) {
          temp.push(currentFlow?.registrationAlerts.alerts[i].service);
        }
      }
      setservicealerts(temp);
      setservicealertsn(true);
    }


    dispatch(setIsFlowDetail(true));



    return () => { };
  }, [modoRevision, idRevisionCurrent, loadingStatus, id, currentFlow, documentLoading, currentRegistration, currentAttatchment]);
  const umbralBloqueo = "70";

  const [currentSectionCoreId, setCurrentSectionCoreId] = useState<
    | "DOCUMENTO DE IDENTIDAD"
    | "BIOMETRÍA"
    | "ESTADO DEL REGISTRO"
    | "MATRIZ DE VERIFICACIÓN"
  >("DOCUMENTO DE IDENTIDAD");
  const [currentSectionMagic, setCurrentSectionMagic] = useState<
    | "FORMULARIO"
    | "CAMPOS"
  >("FORMULARIO");
  const [currentSectionFirma, setCurrentSectionFirma] = useState<
    | "DOCUMENTOS"
    | "FIRMANTES"
    | "HISTORIAL DE ACCIONES"
  >("DOCUMENTOS");
  const [currentSectionAdjuntos, setCurrentSectionAdjuntos] = useState<
    | "ADJUNTOS"
  >("ADJUNTOS");

  let nextIndex = () => {

    if (flows) {
      let new_index = 0;

      if (index < flows.length) {
        new_index = index + 1;
      }
      else {
        dispatch(changePageState(currentPage + 1));
        new_index = 0;
      }
      dispatch(changeIndex(new_index));
      setidRevisionCurrent(flows[new_index].id);
    }


  };

  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm
  ) => {
    switch (loadingPDFStatus) {
      case "idle":
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={`data:application/pdf;base64,${filledForm.document}`}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
    }
  };


  let getDocumentSign = (
    loadingPDFStatus: LoadingStatus,
    sign: File
  ) => {
    switch (loadingPDFStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento

          </div>
        );
      case "resolved":
        return (
          <object
            data={`data:application/pdf;base64,${sign.documentBase64}`}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
          
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">
            Firma
          </div>
        );
    }
  };


  let getDetailData = (value: string) => {
    return (<div
      className="flex-col"
      style={{ alignItems: "center", alignSelf: "end" }}
    >
      <div
        className="text-medium text-bold primary-color-30"
        style={{ width: "145px", textAlign: "center", fontSize: "x-small" }}
      >
        Scope Op. 1
      </div>
      <div className="text-huge text-bold primary-color">
        {value}
      </div>

    </div>);
  }
  let getCurrentContentCoreId = () => {
    switch (currentSectionCoreId) {
      case "DOCUMENTO DE IDENTIDAD":
        return currentFlow?.registration && (<div className="flex-row content-table"
        >
          <div className="col-4 data-colum" style={{ padding: "20px", paddingBottom: "10px" }}>
            {getTextValue("Fecha de nacimiento", currentFlow?.registration ? currentFlow.registration.userDocument.birthDate : "No encontrado", false)}
            {getTextValue("Número de documento", currentFlow?.registration ? currentFlow.registration.userDocument.documentNumber : "No encontrado", false)}
            {getTextValue("Fecha de expedición", currentFlow?.registration ? currentFlow.registration.userDocument.expeditionDate : "No encontrado", false)}
            {getTextValue("Nombre y apellido", currentFlow?.registration ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}` : "No encontrado", currentFlow.registration.multiFactorMatrix ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.length > 0 : false)}
            {getTextValue("Lugar de nacimiento", currentFlow?.registration ? currentFlow.registration.userDocument.birthPlace : "No encontrado", false)}
            {getTextValue("Sexo", currentFlow?.registration ? currentFlow.registration.userDocument.gender : "No encontrado", false)}
            {getTextValue("Tipo de sange (RH)", currentFlow?.registration ? currentFlow.registration.userDocument.bloodType : "No encontrado", false)}

          </div>
          <div className="col-4 data-colum">
            <div className="text-bold text-medium primary-color" style={{ textAlign: "center" }}>
              Documento
              <br />
            </div>
            {
              currentFlow?.registration && <Generalmages currentRegistration={currentFlow?.registration} loadingImages={loadingStatus} />
            }

            <div className="text-medium text-normal primary-color"
              style={{ padding: "10px", textAlign: "center" }}>
              {`OCR: ${currentFlow?.registration.unifiedChecks.ocrCheck.active
                ? currentFlow?.registration.unifiedChecks.ocrCheck.result
                  ? "OK"
                  : "NO OK"
                : "Desactivado"}`}
            </div>


          </div>
          <div className="col-4 data-colum" style={{ padding: "10px" }}>
            {currentFlow?.registration.unifiedChecks.documentValidityCheck
              .active ? <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks
                .documentValidityCheck.description} isActive={false} detail={""} title="Autenticidad de documento"
                content={<div className="flex-row">
                  <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                    {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.veracity.blockingThreshold}`}
                  </div>
                  {getDetailData(currentFlow?.registration.unifiedChecks.documentValidityCheck
                    .active
                    ? currentFlow?.registration.multiFactorMatrix ? `${(currentFlow?.registration.multiFactorMatrix.veracity.score).toFixed(1)}%` : ""

                    : "Desactivado")}
                </div>} /> : <></>
            }
            {currentFlow?.registration.unifiedChecks.documentMatchCheck
              .active ? <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks
                .documentMatchCheck.description} isActive={false} detail={""} title="Match Datos Iniciales"
                content={<div className="flex-row">
                  <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                    {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.initialDataMatch.blockingThreshold}`}
                  </div>
                  {getDetailData(currentFlow?.registration.unifiedChecks.documentMatchCheck
                    .active
                    ? `${currentFlow?.registration.faceMatchPercent.toFixed(1)}%`
                    : "Desactivado")}
                </div>} /> : <></>
            }
            {currentFlow?.registration.unifiedChecks.governmentDataMatchCheck
              .active ? <ExpandibleComponentList alert={currentFlow.registration.multiFactorMatrix ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.length > 0 || currentFlow.registration.multiFactorMatrix?.govEntity.alerts.length > 0 : false}
                alerts={
                  currentFlow.registration.multiFactorMatrix ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.concat(currentFlow.registration.multiFactorMatrix?.govEntity.alerts) : []
                } isActive={false} title="Match Gubernamental"
                content={<div className="flex-row">
                  <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                    {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.govEntity.blockingThreshold}`}
                  </div>
                  {getDetailData(currentFlow?.registration.unifiedChecks.governmentDataMatchCheck
                    .active
                    ? currentFlow?.registration.multiFactorMatrix ? `${(currentFlow?.registration.multiFactorMatrix.govEntity.score).toFixed(1)}%` : ""

                    : "Desactivado")}
                </div>} /> : <></>
            }
          </div>

        </div>);
      case "BIOMETRÍA":
        return currentFlow?.registration ? (<div className="flex-row content-table"
          style={{ width: "80%", margin: "10px" }}>

          <div className="col-6" >
            {
              currentFlow?.registration ? <BiometryImages currentRegistration={currentFlow?.registration} loadingImages={loadingStatus} /> : <div />
            }
          </div>
          <div className="col-6" style={{ padding: "2%", marginLeft: "10%" }}>
            <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks
              .documentValidityCheck.description} isActive={false} detail={""} title="FACE MATCH"
              content={<div className="flex-col" >
                <div className="flex-row">
                  <div className="flex-col" style={{ width: "50%" }} >
                    {getDetailData(currentFlow?.registration.unifiedChecks.facematchCheck.active
                      ? `${currentFlow?.registration.faceMatchPercent.toFixed(1)}%` : "Desactivado")}
                  </div>
                  <div className="flex-col" style={{ width: "50%" }} >
                    {getDetailData(currentFlow?.registration.unifiedChecks.facematchCheck.active
                      ? `${currentFlow?.registration.faceMatchPercent.toFixed(1)}%` : "Desactivado")}
                  </div>
                </div>
                <br />
                <div className="flex-col text-medium primary-color-35 pdd-left" style={{ textAlign: "center", justifySelf: "center", padding: "1%" }}>
                  {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.faceMatch.blockingThreshold}`}
                </div></div>} />


            {/*  <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks
                            .documentValidityCheck.description} isActive={false} detail={""} title="LIVENESS"
            content={<div className="flex-col" >
 <div className="flex-row"> 
 <div className="flex-col" style={{width: "50%"}} > 
 {getDetailData(currentFlow?.registration.unifiedChecks.facematchCheck.active
         ? `${currentFlow?.registration.livenessPercent.toFixed(1)}%`: "Desactivado")}
         </div>
 <div className="flex-col" style={{width: "50%"}} > 
 {getDetailData(currentFlow?.registration.unifiedChecks.facematchCheck.active
         ? `${currentFlow?.registration.livenessPercent.toFixed(1)}%`: "Desactivado")}
         </div>
 </div>
 <br/>             
         <div className="flex-col text-medium primary-color-35 pdd-left" style={{textAlign: "center", justifySelf: "center", padding: "1%"}}>
                       { `Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.liveness.blockingThreshold}`}
 </div></div>}/>*/}


          </div>

        </div>) : <></>;
      case "ESTADO DEL REGISTRO":
        return currentFlow?.registration ? (<div className="flex-row content-table"
          style={{ width: "100%" }}>
          <div className="col-4" style={{ padding: "2%" }}>
            <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks.listsCheck.description} isActive={false} detail={""} title="Listas"
              content={<div className="flex-row primary-color-40" style={{ padding: "10%" }}>
                {currentFlow?.registration.unifiedChecks.listsCheck.result ? "OK" : "NO OK"}
              </div>} />
          </div>
          <div className="col-4" style={{ padding: "2%" }}>
            <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks.gpsCheck.description} isActive={false} detail={""} title="GPS"
              content={<div className="flex-row primary-color-40" style={{ padding: "10%" }}>
                {currentFlow?.registration.unifiedChecks.gpsCheck.result ? "OK" : "NO OK"}
              </div>} />
          </div>
          <div className="col-4" style={{ padding: "2%" }}>
            <ExpandibleComponent alert={false} description={currentFlow?.registration.unifiedChecks.deviceIdCheck.description} isActive={false} detail={""} title="Device ID"
              content={<div className="flex-row primary-color-40" style={{ padding: "10%" }}>
                {currentFlow?.registration.unifiedChecks.deviceIdCheck.result ? "OK" : "NO OK"}
              </div>} />
          </div>

        </div>) : <></>;
      case "MATRIZ DE VERIFICACIÓN":
        return currentFlow?.registration && (<div className="flex-row content-table text-normal text-small"
          style={{ width: "100%" }}>
          <div className="flex-col" style={{ width: "30%" }}>
            <div className="flex-col primary-color" style={{
              width: "70%", borderRadius: "8px", background: "rgba(0,0,0,0.3)",
              padding: "3%", marginLeft: "10px", marginTop: "10%"
            }}>
              <div className="flex-row" style={{ paddingLeft: "20px" }}>
                {`Umbral: ${currentFlow.registration.multiFactorMatrix?.veracity.blockingThreshold}`}

              </div>
              <div className="flex-row" style={{ paddingLeft: "20px" }}>
                {`Score Total: ${currentFlow.registration.multiFactorMatrix?.finalScore}`}
              </div>

            </div>

            <table
              style={{
                width: "90%",
                borderCollapse: "collapse",
                margin: "10px",
                overflow: "hidden",
                borderRadius: "8px",
                padding: "10px",

              }}
            >
              <thead
                className="text-normal text-small primary-color"
                style={{
                  background: "rgba(0,0,0,0.2)",
                  textAlign: "left",

                }}
              >
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                className={`text-normal text-x-small primary-color`}
                style={{
                  background: "rgba(0,0,0,0.2)",
                  textAlign: "left",
                }}
              >

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"OCR"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.ocrCheck ? "OK" : "NO OK"}</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"OTP"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.otpCheck ? "OK" : "NO OK"}</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"Correo"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.emailCheck ? "OK" : "NO OK"}</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"Dispositivos registrados"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.deviceIdCheck ? "OK" : "NO OK"}</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"Localización"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.gpsCheck ? "OK" : "NO OK"}</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: "20px" }}>{"Listas"}</td>
                  <td className="p-1">{currentFlow.registration.unifiedChecks.listsCheck ? "OK" : "NO OK"}</td>
                </tr>


              </tbody>
            </table>

          </div>
          <div className="flex-col" style={{ width: "70%", padding: "2%" }}>
            <table
              style={{
                width: "80%",
                marginLeft: "10%",
                borderCollapse: "collapse",
                overflow: "hidden",

                borderRadius: "12px",
                padding: "10px",

              }}
            >
              <thead
                className="text-normal text-small primary-color"
                style={{
                  background: "rgba(0,0,0,0.2)",
                  height: "30px",
                  textAlign: "center",

                }}
              >
                <tr>
                  <th className="p-1">Factor</th>
                  <th className="p-1">Score (0-100)</th>
                  <th className="p-1">Umbral de Bloqueo</th>
                  <th className="p-1">Admisión</th>
                </tr>
              </thead>
              <tbody
                className={`text-normal text-x-small primary-color-30`}
                style={{
                  background: "rgba(0,0,0,0.2)",
                  textAlign: "center",
                }}
              >

                <tr className="p-1">
                  <td>{"Face match"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.faceMatch.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.faceMatch.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.faceMatch.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Validez-Autenticidad"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.validity.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.validity.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.validity.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Veracidad documento"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.veracity.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.veracity.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.veracity.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Listas"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.lists.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.lists.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.lists.passes ? "SI" : "NO"}</td>

                </tr>
                {/* <tr className="p-1">
                      <td>{"Liveness"}</td>
                      <td>{currentFlow?.registration?.multiFactorMatrix?.liveness.score}</td>
                      <td>{currentFlow?.registration?.multiFactorMatrix?.liveness.blockingThreshold}</td>
                      <td>{currentFlow?.registration?.multiFactorMatrix?.liveness.passes?"SI":"NO"}</td>

                  </tr>*/}
                <tr className="p-1">
                  <td>{"GPS(Geofencin)"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.geolocation.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.geolocation.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.geolocation.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Device ID"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.deviceId.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.deviceId.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.deviceId.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Match datos iniciales"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.initialDataMatch.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.initialDataMatch.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.initialDataMatch.passes ? "SI" : "NO"}</td>

                </tr>
                <tr className="p-1">
                  <td>{"Alertas entidades gubernamentales"}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.govEntity.score}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.govEntity.blockingThreshold}</td>
                  <td>{currentFlow?.registration?.multiFactorMatrix?.govEntity.passes ? "SI" : "NO"}</td>

                </tr>


              </tbody>
            </table>
          </div>

        </div>);
      default:
        return (<></>);
    }
  };
  let getCurrentContentMagic = () => {
    switch (currentSectionMagic) {
      case "FORMULARIO":
        return currentSectionStep && currentSectionStep?.detailMagic && (
          <div className="flex-row content-table">

            <div className="flex-col" style={{ width: "30%", paddingLeft: "20px" }}>
              <div className="text-normal text-small primary-color-35">
                {` Nombre: ${currentSectionStep && currentSectionStep?.detailMagic ? currentSectionStep?.detailMagic.pdfName : ""}`}
              </div>
              <div className="text-normal text-small primary-color-35">
                {` ID Documento: ${currentSectionStep && currentSectionStep?.detailMagic ? currentSectionStep?.detailMagic.id : ""}`}
              </div>
              <div className="text-normal text-small primary-color-35">
                {` Descripción: ${currentSectionStep && currentSectionStep?.detailMagic ? currentSectionStep?.detailMagic.formTemplateId : ""}`}
              </div>
              <div style={{ marginTop: "auto", padding: "1%" }}>
                <button className="flex-row text-small primary-color" style={{ borderRadius: "8px", background: "rgba(0,0,0,0.4)", border: "none", padding: "10px", paddingLeft: "20px", paddingRight: "20px", margin: "10px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (currentSectionStep && currentSectionStep.detailMagic && currentSectionStep.detailMagic.document) {
                      let url = `data:application/pdf;base64,${currentSectionStep.detailMagic.document}`;

                      fetch(url)
                        .then((res) => res.blob())
                        .then((blob) => {
                          fileDownload(blob, currentSectionStep.detailMagic?.pdfName ? currentSectionStep.detailMagic?.pdfName : "Document.pdf");
                        });
                    }
                  }}>
                  {"Descargar "}
                  <div style={{ padding: "2%" }}>
                    <DownloadIcon
                      style={{ margin: "4px" }}

                    />
                  </div>

                </button>
              </div>
            </div>
            <div className="flex-col" style={{ width: "70%" }}>
              {getDocument(loadingStatus, currentSectionStep?.detailMagic)
              }
            </div>



          </div>
        );
      case "CAMPOS":
        return currentSectionStep && currentSectionStep?.detailMagic && (<div className="flex-col primary-color-35" style={{ width: "100%" }}>
          <table
            style={{
              width: "90%",
              borderCollapse: "collapse",
              margin: "5%",
            }}
          >
            <thead
              className="text-normal text-medium"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>

                <th>Información Solicitada</th>
                <th>Respuesta</th>
                <th>Tipo de Campo</th>

              </tr>
            </thead>
            <tbody
              className={`text-normal text-x-small`}
              style={{
                background: "rgba(0,0,0,0.2)",
                textAlign: "center",
              }}
            >

              {currentSectionStep?.detailMagic.requirements.map((e) => {
                return (
                  <tr className="list-hover">
                    <td>{e.source}</td>
                    <td>{e.name}</td>
                    <td>{e.stitchTypeId}</td>

                  </tr>);
              })}

            </tbody>
          </table>
        </div>);
      default:
        return (<></>);
    }
  };

  let getCurrentContentManualRetry = () => {
    let showButton = false;
    if (currentFlow && currentFlow.executedSteps) {
      let currentStep2 = currentFlow?.executedSteps[currentFlow.currentStepId];
      if (currentStep2.stepDescriptionId === "MANUAL_RETRY") {
        showButton = true;
      }
    }
    if (showButton === true) {
      return (
        <div className="flex-row content-table">
          <div className="flex-col">
            <button
              className="flex-row text-small primary-color"
              style={{
                borderRadius: "8px",
                background: "rgba(0,0,0,0.4)",
                border: "none",
                height: "50px",
                textAlign: "center",
                padding: "18px 20px",
                marginLeft: "50px",
              }}
              onClick={() => {
                dispatch(retryStepManual(currentFlow?.id??""))
              }}
            >
              {"Reintentar"}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex-row content-table text-bold primary-color">
          <div className="flex-col" style={{marginLeft:"50px"}}>
            No se encuentra en esta etapa actualmente
          </div>
        </div>
      );
    }
  };

  const [currentSection, setCurrentSection] = useState<string>("");
  const [currentSectionStep, setCurrentSectionStep] = useState<ExecutedStep | null>(null);


  let getCurrentSectionContent = (
    name: String,
  ) => {
    switch (name) {
      case "CORE_ID":
        return (getCurrentContentCoreId());
      case "MAGIC_FORMS":
        return (getCurrentContentMagic());
      case "ELECTRONIC_SIGNATURE":
        return (getCurrentContentFirma());
      case "MANUAL_RETRY":
        return (getCurrentContentManualRetry());
    }
  };

  let getTitle = (
    name: String,
    execStep: ExecutedStep
  ) => {
    switch (name) {
      case "CORE_ID":
        return ("Core ID");
      case "MAGIC_FORMS":
        return ("Magic Forms");
      case "ELECTRONIC_SIGNATURE":
        return ("Firmas");
      case "MANUAL_RETRY":
        return execStep.customName
      default:
        return ("");
    }
  };
  let getCurrentContentFirma = (
  ) => {
    switch (currentSectionFirma) {
      case "DOCUMENTOS":
        return currentSectionStep && currentSectionStep?.detailSig && (<div className="flex-row content-table">
          <div className="flex-col" style={{ width: "30%", paddingLeft: "20px" }}>
            <div className="text-normal text-small primary-color-35">
              {` ID Documento: ${currentSectionStep?.detailSig.documentId}`}
            </div>
            <div className="text-normal text-small primary-color-35">
              {` Descripción: ${currentSectionStep?.detailSig.status}`}
            </div>
            <div style={{ marginTop: "auto", padding: "1%" }}>
              <button className="flex-row text-small primary-color" style={{ borderRadius: "8px", background: "rgba(0,0,0,0.4)", border: "none" }}
                onClick={() => { setCertificado(false); }}>
                {"Documento Firmado"}
              </button>

            </div>
            <div style={{ marginTop: "auto", padding: "1%" }}>
              <button className="flex-row text-small primary-color" style={{ borderRadius: "8px", background: "rgba(0,0,0,0.4)", border: "none" }}
                onClick={() => { setCertificado(true); }}>

                {"Certificado de Firma"}
              </button>

            </div>
            <div style={{ marginTop: "auto", padding: "1%" }}>
              <button className="flex-row text-small primary-color" style={{ borderRadius: "8px", background: "rgba(0,0,0,0.4)", border: "none" }} >
                {"Descargar "}
                <div style={{ padding: "2%" }}>
                  <DownloadIcon
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentSectionStep && currentSectionStep.detailSig && currentSectionStep.detailSig.documents && currentSectionStep.detailSig.documents.finishedSignature) {
                        let url = `data:application/pdf;base64,${certificado ? currentSectionStep?.detailSig.documents?.certificate.documentBase64 : currentSectionStep?.detailSig.documents?.certificate.documentBase64}`;

                        fetch(url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(blob, "Certificado.pdf");
                          });
                      }

                    }}
                  />
                </div>

              </button>

            </div>
          </div>
          <div className="flex-col" style={{ width: "70%" }}>
            {

              currentSectionStep && currentSectionStep.detailSig && currentSectionStep.detailSig.documents && currentSectionStep.detailSig.documents.finishedSignature && getDocumentSign(loadingStatus, certificado ? currentSectionStep?.detailSig.documents?.certificate : currentSectionStep?.detailSig.documents?.certificate)
            }
          </div>



        </div>);
      case "FIRMANTES":
        return currentSectionStep && currentSectionStep?.detailSig && (<div className="flex-col primary-color-35" style={{ width: "100%" }}>
          <table
            style={{
              width: "90%",
              borderCollapse: "collapse",
              margin: "5%",
            }}
          >
            <thead
              className="text-normal text-medium"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>

                <th>Nombre y Apellido</th>
                <th>Fecha de la Firma</th>
                <th>User ID</th>
                <th>Estado</th>
                <th>Método</th>
                <th>Alerta</th>

              </tr>
            </thead>
            <tbody
              className={`text-normal text-x-small`}
              style={{
                background: "rgba(0,0,0,0.2)",
                textAlign: "center",
              }}
            >

              {currentSectionStep?.detailSig.signers.map((e) => {
                return (
                  <tr className="list-hover">
                    <td>{`${e.name} ${e.lastName}`}</td>
                    <td>{e.startedDate}</td>
                    <td>{e.userId}</td>
                    <td>{e.status}</td>
                    <td>{e.validationMethod}</td>
                    <td>{e.numAlerts}</td>

                  </tr>);
              })}

            </tbody>
          </table>
        </div>);
      case "HISTORIAL DE ACCIONES":
        return currentSectionStep && currentSectionStep?.detailSig && (<div className="flex-col primary-color-35" style={{ width: "100%" }}>
          <div className="text-normal text-medium primary-color"
          >Historial del Documento</div>
          <table
            style={{
              width: "90%",
              borderCollapse: "collapse",
              margin: "5%",
            }}
          >
            <thead
              className="text-normal text-medium primary-color"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>

                <th></th>
                <th></th>

              </tr>
            </thead>
            <tbody
              className={`text-normal text-x-small`}
              style={{
                background: "rgba(0,0,0,0.2)",
                textAlign: "center",
              }}
            >

              {currentSectionStep?.detailSig.history && currentSectionStep?.detailSig.history.map((e) => {
                return (
                  <tr className="list-hover">
                    <td>{e.action}</td>
                    <td>{e.date}</td>

                  </tr>);
              })}

            </tbody>
          </table>
        </div>);
      default:
        return (<></>);
    }
  };

  let getTextValue = (
    name: String,
    value: String,
    alert: boolean,
  ) => {
    return (
      <>
        <div className="flex-col">
          <div className="flex-col text-small primary-color-40">
            {name}
          </div>
          <div className={`flex-row text-small ${alert ? "text-bold alert-color" : "primary-color"}`}>
            <div className="col-2">
              {alert ? <HyperFlowAlert /> : ""}
            </div>
            <div className="col-10">
              {value}
            </div>
          </div>
        </div>

      </>

    );
  };
  let getAlert = (
    name: String,
  ) => {

    switch (name) {
      case "CAMPOS":
        return servicealerts.includes("MagicForms");
      case "DOCUMENTO DE IDENTIDAD":
        return servicealerts.includes("coreId");
    }

    return false;
  };
  let getMenuItemCoreId = (
    name: ("DOCUMENTO DE IDENTIDAD"
      | "BIOMETRÍA"
      | "ESTADO DEL REGISTRO"
      | "MATRIZ DE VERIFICACIÓN"),
    first: boolean,
    last: boolean
  ) => {
    return (<div className="flex-row" onClick={() => { setCurrentSectionCoreId(name) }}
      style={{
        background: currentSectionCoreId === name ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.4)",
        borderTopLeftRadius: first ? "8px" : "", borderTopRightRadius: first ? "8px" : "",
        borderBottomLeftRadius: last ? "8px" : "", borderBottomRightRadius: last ? "8px" : "",
        borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)"
      }}>
      <div className="flex-col" style={{ width: "20%" }}>{getAlert(currentSectionCoreId) ? <HyperFlowAlert /> : ""}</div>
      <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
        {name}
      </div>
    </div>
    );
  };


  let getMenuItemMagic = (
    name: ("FORMULARIO"
      | "CAMPOS"),
    first: boolean,
    last: boolean
  ) => {
    return (<div className="flex-row" onClick={() => { setCurrentSectionMagic(name) }}
      style={{
        background: currentSectionMagic === name ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.4)",
        borderTopLeftRadius: first ? "8px" : "", borderTopRightRadius: first ? "8px" : "",
        borderBottomLeftRadius: last ? "8px" : "", borderBottomRightRadius: last ? "8px" : "",
        borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)"
      }}>
      <div className="flex-col" style={{ width: "20%" }}>{getAlert(currentSectionMagic) ? <HyperFlowAlert /> : ""}</div>
      <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
        {name}
      </div>
    </div>
    );
  };


  let getMenuItemFirmas = (
    name: ("DOCUMENTOS"
      | "FIRMANTES"
      | "HISTORIAL DE ACCIONES"),
    first: boolean,
    last: boolean
  ) => {
    return (<div className="flex-row" onClick={() => { setCurrentSectionFirma(name) }}
      style={{
        background: currentSectionFirma === name ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.4)",
        borderTopLeftRadius: first ? "8px" : "", borderTopRightRadius: first ? "8px" : "",
        borderBottomLeftRadius: last ? "8px" : "", borderBottomRightRadius: last ? "8px" : "",
        borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)"
      }}>
      <div className="flex-col" style={{ width: "20%" }}>{getAlert(currentSectionFirma) ? <HyperFlowAlert /> : ""}</div>
      <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
        {name}
      </div>
    </div>
    );
  };

  let getMenuItemAdjuntos = (
    name: ("ADJUNTOS"),
    first: boolean,
    last: boolean
  ) => {
    return (<div className="flex-row" onClick={() => { setCurrentSectionAdjuntos(name) }}
      style={{
        background: currentSectionAdjuntos === name ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.4)",
        borderTopLeftRadius: first ? "8px" : "", borderTopRightRadius: first ? "8px" : "",
        borderBottomLeftRadius: last ? "8px" : "", borderBottomRightRadius: last ? "8px" : "",
        borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)"
      }}>
      <div className="flex-col" style={{ width: "20%" }}>{getAlert(currentSectionAdjuntos) ? <HyperFlowAlert /> : ""}</div>
      <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
        {name}
      </div>
    </div>
    );
  };


  let getCurrentSectionMenu = (
    name: String,
  ) => {
    switch (name) {
      case "CORE_ID":
        return (<div className="flex-col text-bold text-medium primary-color"
          style={{ width: "80%" }}>

          {getMenuItemCoreId("DOCUMENTO DE IDENTIDAD", true, false)}
          {getMenuItemCoreId("BIOMETRÍA", false, false)}
          {getMenuItemCoreId("ESTADO DEL REGISTRO", false, false)}
          {getMenuItemCoreId("MATRIZ DE VERIFICACIÓN", false, true)}

        </div>);
      case "MAGIC_FORMS":
        return (<div className="flex-col text-bold text-medium primary-color"
          style={{ width: "80%" }}>

          {getMenuItemMagic("FORMULARIO", true, false)}
          {getMenuItemMagic("CAMPOS", false, false)}

        </div>);
      case "ELECTRONIC_SIGNATURE":
        return (<div className="flex-col text-bold text-medium primary-color"
          style={{ width: "80%" }}>

          {getMenuItemFirmas("DOCUMENTOS", true, false)}
          {getMenuItemFirmas("FIRMANTES", false, false)}
          {getMenuItemFirmas("HISTORIAL DE ACCIONES", false, true)}

        </div>);
      case "Adjuntos":
        return (<div className="flex-col text-bold text-medium primary-color"
          style={{ width: "80%" }}>

          {getMenuItemAdjuntos("ADJUNTOS", true, true)}

        </div>);

    }
  };


  let getRowInfoClient = (name: String, value: String) => {
    return (<div className="flex-row">
      <div className="flex-col" style={{ width: "50%" }}>
        {name}
      </div>
      <div className="flex-col" style={{ width: "50%" }}>
        {value}
      </div>
    </div>);
  }

  const [selectedDownload, setSelectedDownload] = useState(-1);
  const [currentAlert, setCurrentAlert] = useState<UnifiedAlerts>({
    clientId: "",
    dateTime: "",
    description: "",
    id: "",
    itemId: "",
    resolved: false,
    service: "",
    type: "",
    userId: "",
    details: [],
  });




  let downloadAtatchment = (attatchment: Attatchment, selected: number) => {
    setSelectedDownload(selected);
    dispatch(
      getAttatchmentData({
        filledFormId: attatchment.filledFormId,
        stitchTypeId: attatchment.stitchTypeId,
      })
    );
  };

  let showAttatchments = (
    currentRegistration: RegistrationDetail,
    loadingStatus: LoadingStatus,
    downloadStatus: LoadingStatus
  ) => {
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        );

      case "rejected":
        return (
          <div
            className="flex-col text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar adjuntos
          </div>
        );
      case "resolved":
        if (
          !currentRegistration.attatchments ||
          currentRegistration.attatchments.length === 0
        ) {
          return (
            <div
              className="flex-col text-normal text-medium primary-color"
              style={{
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Este registro no tiene adjuntos
            </div>
          );
        } else {
          return (
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
              }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre adjunto</th>
                    <th>Nombre archivo</th>
                    <th>Descargar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {currentRegistration.attatchments &&
                    currentRegistration.attatchments.map((attatchment, i) => {
                      return (
                        <tr key={i} style={{ height: "30px" }}>
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {attatchment.fieldName || ""}
                          </td>
                          <td>{attatchment.fileName || ""}</td>
                          <td>
                            {downloadStatus === "pending" &&
                              selectedDownload === i ? (
                              <LoadingOverlay
                                active={downloadStatus === "pending"}
                                spinner={
                                  <ClipLoader color="white" size="20px" />
                                }
                              />
                            ) : (
                              <DownloadIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAtatchment(attatchment, i);
                                }}
                              />
                            )}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        }
      default:
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Adjuntos
          </div>
        );
    }
  };

  if (modoRevision && flows && flows.length == 0) {
    return (<LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {modoRevision && flows && flows.length == 0 && <div
        className="flex-col"
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      ><h3 className="text-large text-bold primary-color">
          {"No hay registros en revisión."}
        </h3></div>

      }</LoadingOverlay>);
  }
  else {
    return (
      <LoadingOverlay
        active={loadingStatus === "pending"}
        spinner={<ClipLoader color="white" size="150px" />}
      >

        {!currentFlow || currentFlow.id !== "" ? (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
              paddingLeft: "1%",
            }}
          >
            {currentFlow?.registration && <div
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >


              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex-row"
                style={{
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <LeftArrow style={{ marginRight: "15px" }} />
                <div className="text-bold text-x-large primary-color">
                  Regresar a lista de verificaciones
                </div>
              </div>

            </div>}

            {currentFlow?.registration && <div
              className="flex-row">
              <div className="col-7">


                <div
                  className="flex-row"
                  style={{
                    padding: "10px", paddingLeft: "50px"
                  }}
                >
                  <div className="text-bold text-x-large primary-color">
                    Info Cliente
                  </div>
                </div>


                <div className="text-medium primary-color"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    width: "95%", padding: "1%", paddingLeft: "50px"
                  }}>

                  {getRowInfoClient("Nombre y apellido:", currentFlow?.registration && currentFlow.registration.name ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}` : "")}
                  {getRowInfoClient("ID de registro:", currentFlow?.registration && currentFlow.registration.id ? currentFlow.registration.id : "")}
                  {getRowInfoClient("Email:", currentFlow?.registration && currentFlow.registration.email ? currentFlow.registration.email : "")}
                  {getRowInfoClient("Nº Celular:", currentFlow?.registration && currentFlow.registration.phone ? currentFlow.registration.phone : "")}
                  {getRowInfoClient("Fecha de registro:", currentFlow?.registration && currentFlow.registration.startDatetime ? currentFlow.registration.startDatetime : "")}


                </div>


                <br />
              </div>
              <div className="col-2">
                {modoRevision && <div
                  className="text-medium text-bold primary-color"
                  style={{
                    marginTop: "auto", marginRight: "2%",
                    marginLeft: "auto",
                    background: "rgba(0,0,0,0.4)", paddingRight: "30px",
                    paddingTop: "10px", paddingBottom: "10px",
                    paddingLeft: "30px", borderRadius: "100px", textAlign: "center", cursor: "pointer"

                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/services/hyperFlow/flows/${currentFlow?.id}`);
                    setmodoRevision(false);
                  }}
                >
                  IR AL <br />REGISTRO <br />DETALLADO
                </div>}
              </div>
              <div className="col-3">
                <div style={{ margin: "10px", marginRight: "0%" }}>
                  <StateComponent eventChangeState={(e: string) => {
                    dispatch(changeStatusById(e));
                    if (modoRevision) {
                      nextIndex();
                    }
                  }} state={currentFlow ? currentFlow.flowStatus : ""} updateRe={(id === "flowsrevision" ? idRevisionCurrent : (id ? id : ""))} />

                </div>
        
              </div>
            </div>}

            {currentFlow?.registration ? <div className="flex-row" style={{ marginTop: "20px" }}>
              <div className="flex-col"
                style={{ height: "100%", width: "20%" }}>
               
                {getCurrentSectionMenu(currentSectionStep ? currentSectionStep.stepDescriptionId : "")}
              </div>
              <div className="flex-col"
                style={{ height: "100%", width: "80%" }}>
                  
                {
                  currentFlow && currentFlow.executedSteps && Object.values(currentFlow.executedSteps).map((e: ExecutedStep) => {
                    
                    let show = true;
                    if (modoRevision) {
                      if (!currentSectionStep) {
                        setCurrentSection(e.id);
                        setCurrentSectionStep(e);
                       
                      }
                    }

               
                    if(e.stepDescriptionId=== "MANUAL_RETRY" || ((e.stepDescriptionId==="CORE_ID"||e.stepDescriptionId==="MAGIC_FORMS"||e.stepDescriptionId==="ELECTRONIC_SIGNATURE")  && e.entityId && e.entityId !== "") ) {
                      if (e.detailCore === null && e.detailMagic === null && e.detailSig === null) {
                        <div>
                    
                          <h1 className="text-huge text-bold primary-color">
                            {loadingStatus === "pending" || loadingStatus === "idle"
                              ? ""
                              : "Registro no se puede visualizar."}
                          </h1>
                        </div>
                      } else {
                     
                        return (<ExpandibleContent
                          title={getTitle(e.stepDescriptionId, e)}
                          isActive={currentSection === e.id}
                          onClickFunction={() => {
                            setCurrentSection(e.id);
                            setCurrentSectionStep(e);
                          }}
                          content={
                            <div>{getCurrentSectionContent(e.stepDescriptionId)} </div>}
                        />);
                      }
                    }
                  })
                }
                {currentFlow?.registration && !modoRevision &&
                  <ExpandibleContent
                    title="Adjuntos"
                    isActive={currentSection === "Adjuntos"}
                    contentHeight="50%"
                    onClickFunction={() => {
                      setCurrentSection("Adjuntos");
                      setCurrentSectionStep(null);

                    }}
                    content={
                      <div className="flex-col">

                        <div
                          className="flex-col"
                          style={{
                            border: "1px solid rgba(0,0,0,0.2)",
                            boxSizing: "border-box",
                          }}
                        >
                          {showAttatchments(
                            currentFlow?.registration!,
                            loadingStatus,
                            downloadingAttatchment
                          )}
                        </div>
                      </div>
                    }
                  />}

              </div>
            </div> :
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ><h3 className="text-large text-bold primary-color">
                  {loadingStatus === "pending" || loadingStatus === "idle"
                    ? ""
                    : "Usuario no ha culminado el registro. No se puede visualizar la información."}
                </h3></div>}





          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 className="text-huge text-bold primary-color">
              {loadingStatus === "rejected"
                ? "No existe el flujo con id proporcionado"
                : ""}
            </h1>
          </div>
        )}
      </LoadingOverlay>
    );
  }
};
