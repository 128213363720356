import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct } from "../../../common/commonSlice";
import {
  changePageState,
  getFilteredFlows,
  getHyperFlowStats,
  getRevisionMode,
  resetFlow,
  selectCurrentFilterRequest,
  selectFlowFiltered,
  selectLoadingFlowStatus,
  selectNumPagesHyperFlow,
  setIsFlowDetail,
  selectStatsFlow,
  selectCurrentPageHyperFlow
} from "../hyperFlowSlice";
import { formatDateStandar, getStatusFlow } from "../../../../helpers/utils";

export const FlowsList = () => {
  let numPages = useAppSelector(selectNumPagesHyperFlow);
  let loading = useAppSelector(selectLoadingFlowStatus);
  let flows = useAppSelector(selectFlowFiltered);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let miniDashboardData = useAppSelector(selectStatsFlow);
  let currentPage = useAppSelector(selectCurrentPageHyperFlow);

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  const getFlowStep = (
    step: "CORE_ID" | "MAGIC_FORMS" | "ELECTRONIC_SIGNATURE" | "DONE" | string
  ) => {
    switch (step) {
      case "CORE_ID":
        return "Core ID";
      case "MAGIC_FORMS":
        return "Magic Forms";
      case "ELECTRONIC_SIGNATURE":
        return "Firma Electrónica";
      case "DONE":
        return "Finalizado";
      default:
        return "No Empezado";
    }
  };

  const getEstado = (
    status: "IN_PROGRESS" | "PENDING" | "APPROVED" | "REJECTED" | string
  ) => {
    switch (status) {
      case "IN_PROGRESS":
        return "En Proceso";
      case "PENDING":
        return "Pendiente";
      case "APPROVED":
        return "Aprobado";
      case "REJECTED":
        return "Rechazado";
      default:
        return "No Empezado";
    }
  };
  let getPercentage = (number: number) => {
    {
      let percentageNumber = (number * 100).toFixed(2);
      return `${percentageNumber}%`;
    }
  };
  useEffect(() => {
    dispatch(changeCurrentProduct("HyperFlow"));
    dispatch(getHyperFlowStats());

    dispatch(
      getFilteredFlows({
        ...currentFilteredRequest,
      })
    );
    dispatch(resetFlow());
    return () => {};
  }, [currentFilteredRequest]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {
      flows &&
      flows.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          
         {miniDashboardData && <div
            className="flex-row"
            style={{
              height: "15%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginLeft: "0px",
              marginRight: "20px",
              marginBottom: "2%",
              marginTop: "5%"
            }}
          >
            <div
              className="text-medium text-bold primary-color"
              style={{ marginTop: "auto", marginRight: "2%",
              marginLeft: "10px",
              background: "rgba(0,0,0,0.4)", paddingRight: "30px", 
              paddingTop: "10px",paddingBottom: "10px",
              paddingLeft : "30px",  borderEndEndRadius: "8px", borderStartEndRadius: "8px"

            }}
            >
              LISTA DE FLUJOS
            </div>
            <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end", marginRight: "5%" }}
            >
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.total}
              </div>
              <div
                className="text-medium text-bold primary-color-30"
                style={{ width: "145px", textAlign: "center" }}
              >
                Registros <br/> Totales
              </div>
              </div>
              <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end" }}
            >
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.rejected}
              </div>
              <div
                className="text-medium text-bold primary-color-30"
                style={{ width: "145px", textAlign: "center" }}
              >
                Registros <br/> Rechazados
              </div>
              </div>
              <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end" }}
            >
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.accepted}
              </div>
              <div
                className="text-medium text-bold primary-color-30"
                style={{ width: "145px", textAlign: "center" }}
              >
                Registros <br/> Aceptados
              </div>
              </div>
            <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end" }}
            >
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.pending}
              </div>
              <div
                className="text-medium text-bold primary-color-30"
                style={{ width: "145px", textAlign: "center" }}
              >
                Registros <br/>Pendientes
              </div>
              </div>
            <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end" }}
            >
              <div className="text-huge text-bold primary-color">
                {(miniDashboardData.new)}
              </div>
              <div
                className="text-medium text-bold primary-color-30"
                style={{ width: "145px", textAlign: "center" }}
              >
                Nuevos <br/>Registros
              </div>
            </div>
          </div>}
          {(flows.length>0) ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>ID de registro</th>
                      <th>Fecha de registro</th>
                      <th>Nombre flujo</th>
                      <th>Estado</th>
                      <th>Alerta</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {flows.map((flow, i) => {
                      if(flow){
                      return (
                       <tr
                        key={i}
                        className={`list-hover ${(flow.registration&&flow.registration.hasAlerts )?"alert-color": "primary-color"}`}
                        style={{
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/services/hyperFlow/flows/${flow.id}`);

                        }}
                      >
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {flow.registration&&flow.registration.name?`${flow.registration.name} ${flow.registration.lastName}`:""}
                        </td>
                        <td>{flow.registration&&flow.registration.id?flow.registration.id:""}</td>
                        <td>{flow.registration&&flow.registration.startDatetime?formatDateStandar(flow.registration.startDatetime):""}</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {flow.id?flow.id:""}
                        </td>
                        <td>{flow.flowStatus?getStatusFlow(flow.flowStatus):""}</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {flow.registration&&flow.registration.hasAlerts ? "SI" : "NO"}
                        </td>
                        <td></td>
                      </tr>
                      );
                        }
                        else{
                          return(<tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>);
                        }
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
              {!(loading === "pending" || loading === "idle") && "No hay más registros en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{
                marginRight: "100px",
                fontSize: "13px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {"<"}
              </button>
              Página {currentPage + 1} de {numPages}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
          <div className="flex-row">
          <div
              className="text-medium text-bold primary-color"
              style={{ marginTop: "auto", marginRight: "2%",
              marginLeft: "auto",
              background: "rgba(0,0,0,0.4)", paddingRight: "30px", 
              paddingTop: "10px",paddingBottom: "10px",
              paddingLeft : "30px",  borderRadius: "8px", textAlign: "center", cursor:"pointer"

            }}
            onClick={(e)=>{
              e.preventDefault();
                      dispatch(changeCurrentProduct("HyperFlow"));
                      navigate("/services/hyperFlow/flows/flowsrevision");
            }}
            >
              INGRESAR A <br/> MODO REVISIÓN
            </div>
          </div>
          
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
          {(loading !== "pending") &&flows&& flows.length===0&& "No hay más registros en esta página"}

          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
