import { LoadingStatus } from "../../../../common/commonSlice";
import { VerificationImage } from "../../firmaAPI";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";

export interface FirmaImagesProps {
  currentImage: VerificationImage;
  loadingImages: LoadingStatus;
  verificationMethod: string;
  userId?: string;
}

let getImages = (image: string) => {
  let imageList: { original: string; originalHeight: number }[] = [];
  imageList.push({
    original: `data:image/png;base64,${image}`,
    originalHeight: 270,
  });
  return imageList;
};

export const FirmarImages = (props: FirmaImagesProps) => {
  let navigate = useNavigate();
  let getFirmaImages = (
    image: VerificationImage,
    loadingImages: LoadingStatus,
    verificationMethod: string
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        if (verificationMethod === "CoreId") {
          return (
            <div
              onClick={() => {
                navigate(`/services/coreId/registrations/${props.userId}`);
              }}
              className="flex-row hover-white "
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Se autenticó con verificación de identidad. Para mas información
              click aquí
            </div>
          );
        } else if (verificationMethod !== "Biometría Facial") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se autenticó con Biometría Facial
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar las imagenes
            </div>
          );
        }
      case "resolved":
        if (verificationMethod !== "Biometría Facial") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se autenticó con Biometría Facial
            </div>
          );
        } else {
          return (
            <div className="flex-row" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                  infinite
                  showThumbnails={false}
                  showBullets
                  items={getImages(image.validationImage.documentBase64)}
                />
              </div>
            </div>
          );
        }
      default:
        return (
          <div className="flex-row" style={{ width: "100%" }}>
            Imagenes de prueba
          </div>
        );
    }
  };

  return (
    <>
      {getFirmaImages(
        props.currentImage,
        props.loadingImages,
        props.verificationMethod
      )}
    </>
  );
};
