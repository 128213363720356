import { LoadingStatus } from "../../../../common/commonSlice";
import { RegistrationDetail } from "../../../coreid/coreIdAPI";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import ImageGallery from "react-image-gallery";

export interface ImagesProps {
  currentRegistration: RegistrationDetail;
  loadingImages: LoadingStatus;
}

let getImages = (images: Array<string>) => {
  let imageList: { original: string; originalHeight: number }[] = [];
  for (const image of images) {
    imageList.push({
      original: `data:image/png;base64,${image}`,
      originalHeight: 270,
    });
  }
  return imageList;
};

export const Generalmages = (props: ImagesProps) => {
  let getGeneralImages = (
    currentRegistration: RegistrationDetail,
    loadingImages: LoadingStatus
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            No pudimos cargar las imagenes
          </div>
        );
      case "resolved":
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "space-between",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div style={{ width: "50%" }}>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                infinite
                showThumbnails={false}
                showBullets
                items={getImages(currentRegistration.pictures.docPics)}
              />
            </div>
            <div style={{ width: "40%" }}>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                infinite
                showThumbnails={false}
                showBullets
                items={getImages(currentRegistration.pictures.facePics)}
              />
            </div>
          </div>
        );
      default:
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            Imagenes de prueba
          </div>
        );
    }
  };
  return (
    <>{getGeneralImages(props.currentRegistration, props.loadingImages)}</>
  );
};

export const BiometryImages = (props: ImagesProps) => {
  let getBiometryImages = (
    currentRegistration: RegistrationDetail,
    loadingImages: LoadingStatus
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "282px",
            }}
          >
            No pudimos cargar las imagenes
          </div>
        );
      case "resolved":
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "282px",
            }}
          >
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              showNav={false}
              infinite
              showThumbnails={false}
              showBullets
              items={getImages(currentRegistration.pictures.facePics)}
            />
          </div>
        );
      default:
        return (
          <div
            className="flex-row"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "282px",
            }}
          >
            Imagenes de prueba
          </div>
        );
    }
  };
  return (
    <>{getBiometryImages(props.currentRegistration, props.loadingImages)}</>
  );
};

export const DocumentImages = (props: ImagesProps) => {
  let getDocumentImages = (
    currentRegistration: RegistrationDetail,
    loadingImages: LoadingStatus
  ) => {
    switch (loadingImages) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingImages === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "300px",
            }}
          >
            No pudimos cargar las imagenes
          </div>
        );
      case "resolved":
        return (
          <div
            className="flex-col"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "300px",
            }}
          >
            <ImageGallery
              showPlayButton={false}
              showNav={false}
              infinite
              showThumbnails={false}
              showBullets={true}
              showFullscreenButton={false}
              items={getImages(currentRegistration.pictures.docPics)}
            />
          </div>
        );
      default:
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              textAlign: "center",
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid rgba(0,0,0,0.2)",
              width: "300px",
            }}
          >
            Imagenes de prueba
          </div>
        );
    }
  };
  return (
    <>{getDocumentImages(props.currentRegistration, props.loadingImages)}</>
  );
};
