import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { getAlertsByItemIdAPI } from "../alerts/alertsAPI";
import {  getImagesRegistrationAPI, getRegistrationByIdAPI } from "../coreid/coreIdAPI";
import { File } from "../firma/firmaAPI";
import { getGeneratedDocumentsNewAPI, getSignatureByIdAPI } from "../firmanew/firmaNewAPI";
import { getGeneratedDocumentsNew } from "../firmanew/firmaNewSlice";
import {
  FilledForm,
  getFilledFormByIdAPI,
  getPdfFilledFormsAPI,
  getUserAttatchmentsAPI,
} from "../magicforms/magicFormsAPI";
import {
  getMiniDashboardDataAPI,
  changeFlowStatusAPI,
  hyperFlow,
  GetFilteredHyperFlowRequest,
  FlowFilteredOptions,
  getFilteredFlowAPI,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  FlowFiltered,
  GetStatsHyperFlowResponse,
  ExecutedStep,
  getRevisionFlowAPI,
  getRetryStep,
} from "./hyperFlowAPI";

export interface HyperFlowState {
  loadingStatus: LoadingStatus;
  documentLoading: LoadingStatus;
  currentFilteredRequest: GetFilteredHyperFlowRequest;
  page: number;
  index: number;
  numPages: number;
  flowFiltered: hyperFlow[] | null;
  flowFilteredRevision: hyperFlow[] | null;
  filteredOptions: FlowFilteredOptions | null;
  currentFlow: hyperFlow | null;
  isFlowDetail: boolean;
  currentFlowStatus: string;
  miniDashboarData: GetStatsHyperFlowResponse;
  filledForm:FilledForm | null;

}

const initialState: HyperFlowState = {
  loadingStatus: "idle",
  documentLoading: "idle",
  currentFilteredRequest: {
    type: null,
    searchQuery: null,
    endDate: null,
    startDate: null,
    status: null,
    hyperFlowId: null,
    page: 0,
  },
  flowFiltered: null,
  flowFilteredRevision: null,
  page: 0,
  index:0,
  numPages: 0,
  filteredOptions: {
    hyperFlows: {}
  },
  
  miniDashboarData: {
    total: 0,
    rejected: 0,
    accepted: 0,
    pending: 0,
    new: 0
  },
  currentFlow: null,
  isFlowDetail: false,
  currentFlowStatus: "IN_PROGRESS",
  filledForm: null
};

let getCoreId = (value: hyperFlow)=>{

  let steps =value.executedSteps ?value.executedSteps:{};
  let registrationId=null;

  Object.keys(steps).forEach(element => {

if(steps[element].stepDescriptionId==="CORE_ID"){
  registrationId= steps[element].entityId;
}
    
  });

  return registrationId?registrationId:"";

}

let getDataEachStep = async (value: hyperFlow)=>{

  let steps =value.executedSteps ?value.executedSteps:{};
  let stepsdata= Object.keys(steps);

for(var i=0; i<stepsdata.length;i++){

let element = stepsdata[i];
let id=steps[element].entityId;

if(id!=""){
  switch(steps[element].stepDescriptionId){
    case "CORE_ID":
      let core= await getRegistrationByIdAPI(id);
      steps[element].detailCore= core.registration;
      break;
    case "MAGIC_FORMS":
      let magic= await getFilledFormByIdAPI(id);
      let magic_pdf= await getPdfFilledFormsAPI(id);
      let filled=magic.filledForm;

      if(filled){
        filled.document=magic_pdf.pdf?.pdfBase64;
      }
      steps[element].detailMagic= filled;
      break;
    case "ELECTRONIC_SIGNATURE":
      let firma= await getSignatureByIdAPI(id);
      let firma_docs= await getGeneratedDocumentsNewAPI(id);

      let firma_data=firma.signature;
      if(firma_data){
        firma_data.documents=firma_docs.documents;
      }
      steps[element].detailSig= firma_data;
      break;
  
  }
}
};
  return steps;

}

export const getFilterOptions = createAsyncThunk(
  "hyperflow/filterOptions",
  async (_, { rejectWithValue }) => {
    const filterOptions = await getFilterOptionsFlowAPI();

    if (filterOptions.error && filterOptions.error !== "") {
      return rejectWithValue(filterOptions.error);
    } else {
      return filterOptions;
    }
  }
);


export const getFlowById = createAsyncThunk(
  "hyperFlow/getFlowById",
  async (flowId: string, { rejectWithValue }) => {
    let flow = await getFlowByIdAPI(flowId);
    if (!flow.flow || (flow.error && flow.error !== "")) {
      return rejectWithValue(flow.error);
    } else {
      try {
        let registrationId = getCoreId(flow.flow);

        if(registrationId!=""){
        let registration = await getRegistrationByIdAPI(registrationId);
        let registrationAlerts = await getAlertsByItemIdAPI(registrationId);
        let attatchments = await getUserAttatchmentsAPI(registrationId);
        let images = await getImagesRegistrationAPI(registrationId);


        flow.flow.registration=registration.registration;    
        flow.flow.registrationAlerts  =registrationAlerts.alerts;

        if(flow.flow.registration){
          flow.flow.registration.attatchments=attatchments;
          if(images.pictures){
            flow.flow.registration.pictures  =images.pictures;

          }

        }
        flow.flow.executedSteps= await getDataEachStep(flow.flow);
      }

      } catch {}
      
      return flow;
    }
  }
);


export const getFilteredFlows = createAsyncThunk(
  "hyperFlow/getFilteredFlows",
  async (params: GetFilteredHyperFlowRequest, { rejectWithValue }) => {
   

    try {
      let filteredRegistrations = await getFilteredFlowAPI(params);

      if (filteredRegistrations.flows) {
          
        for(var i=0; i<filteredRegistrations.flows.flows.length;i++){
          let registrationId = getCoreId(filteredRegistrations.flows.flows[i]);
          if(registrationId!=""){
            let registration = await getRegistrationByIdAPI(registrationId);

            filteredRegistrations.flows.flows[i].registration=registration.registration;
          }

         
        }
        return filteredRegistrations;}
       else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const getRevisionMode = createAsyncThunk(
  "hyperFlow/getRevisionMode",
  async (_, { rejectWithValue }) => {
    try {
      let filteredRegistrations = await getRevisionFlowAPI();

      if (filteredRegistrations.flows) {
         
        for(var i=0; i<filteredRegistrations.flows.flows.length;i++){
          let registrationId = getCoreId(filteredRegistrations.flows.flows[i]);
          if(registrationId!=""){
            let registration = await getRegistrationByIdAPI(registrationId);

            filteredRegistrations.flows.flows[i].registration=registration.registration;
          }

         
        }

          return filteredRegistrations;
        
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const changeStatusById = createAsyncThunk(
  "hyperFlow/changeFlowStatusById",
  async (newStatus: string, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow = currentState.hyperFlow.currentFlow;
    if (flow  ) {
      const updatedFlow = await changeFlowStatusAPI(newStatus, flow.id, flow.currentStepId, "");
      if (updatedFlow.error && updatedFlow.error !== "") {
        return rejectWithValue(updatedFlow.error);
      } else {
        return updatedFlow.flow;
      }
    } else {
      return;
    }
  }
);

export const retryStepManual = createAsyncThunk(
  "hyperFlow/retryStepManual",
  async (test: string, { getState, rejectWithValue }) => {
    console.log(test)
    const currentState = getState() as RootState;
    const flow2 = currentState.hyperFlow.currentFlow;
    if (flow2) {
      const response = await getRetryStep(test)
      if (response.error && response.error !== "") {
        return rejectWithValue(response.error);
      } else {
        return;      
      }
    } else {
      return;
    }
  }
);

export const getFlowFilledFormPDF = createAsyncThunk(
  "hyperFlow/getFlowFormPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);
export const getHyperFlowStats = createAsyncThunk(
  "hyperFlow/getHyperFlowStats",
  async (_, { rejectWithValue }) => {
    try {
      const hyperFlowStats = await getMiniDashboardDataAPI();

      if (hyperFlowStats.error && hyperFlowStats.error !== "") {
        rejectWithValue("error");

      }else{
        return hyperFlowStats.miniDasboardData;

      }

    } catch {
      rejectWithValue("error");
    }
  }
);

export const hyperFlowSlice = createSlice({
  name: "hyperFlow",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredHyperFlowRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };

    },
    changeIndex: (state, action: PayloadAction<number>) => {
      state.index =  action.payload ;
      

    },
    resetFlow: (state) => {
      state.currentFlow = null;
      state.documentLoading = "idle";
    },
    setIsFlowDetail: (state, action: PayloadAction<boolean>) => {
      state.isFlowDetail = action.payload;
    },
    changeCurrentFlowStatus: (state, action: PayloadAction<string>) => {
      if (state.currentFlow) {
        state.currentFlowStatus = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getFilteredFlows.fulfilled, (state, action) => {
      let registrations = action.payload;

      if (registrations&&registrations.flows) {
        state.flowFiltered = registrations.flows.flows;
        state.page = registrations.flows.page?registrations.flows.page:0;
        state.numPages = registrations.flows.numPages;
        state.loadingStatus = "resolved";
      } else {
        state.loadingStatus = "rejected";
      }
    })
    .addCase(getFilteredFlows.rejected, (state) => {
      state.loadingStatus = "rejected";
      state.page = 0;
      state.numPages = 0;
    })
    .addCase(getFilteredFlows.pending, (state) => {
      state.loadingStatus = "pending";
    }).addCase(getRevisionMode.fulfilled, (state, action) => {
      let registrations = action.payload;

      if (registrations&&registrations.flows) {
        state.flowFilteredRevision = registrations.flows.flows;
        state.page = registrations.flows.page?registrations.flows.page:0;
        state.numPages = registrations.flows.numPages;
        state.index=0;
        state.loadingStatus = "resolved";
      } else {
        state.loadingStatus = "rejected";
      }
    })
    .addCase(getRevisionMode.rejected, (state) => {
      state.loadingStatus = "rejected";
      state.page = 0;
      state.numPages = 0;
    })
    .addCase(getRevisionMode.pending, (state) => {
      state.loadingStatus = "pending";
    })
    
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filteredOptions = filterOptions.filterOptions;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.filteredOptions = {
          hyperFlows : {}
        };
      })
      .addCase(getFlowById.fulfilled, (state, action) => {
        let flow = action.payload;
        if (flow&&flow.flow) {
          state.currentFlow = flow.flow;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFlowById.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFlowById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentFlow = null;
      })
      .addCase(changeStatusById.fulfilled, (state, action) => {
        let flow = action.payload;
         state.loadingStatus = "resolved";
        
      })
      .addCase(changeStatusById.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(changeStatusById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentFlow = null;
      })
      .addCase(getFlowFilledFormPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        let flow = state.currentFlow;
        if (flow && pdf) {
         // flow.filledForm.document = pdf.pdfBase64;
          state.documentLoading = "resolved";
          state.currentFlow = flow;
        } else {
          state.documentLoading = "rejected";
        }
      })
      .addCase(getFlowFilledFormPDF.pending, (state) => {
        state.documentLoading = "pending";
      })
      .addCase(getFlowFilledFormPDF.rejected, (state) => {
        state.documentLoading = "rejected";
      }) .addCase(getHyperFlowStats.fulfilled, (state, action) => {
        let dashboardItems = action.payload;
        if (dashboardItems) {
          state.miniDashboarData = dashboardItems;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getHyperFlowStats.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(getHyperFlowStats.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(retryStepManual.fulfilled, (state, action) => {
        console.log("RetryStep Fullfilled")
      })
      .addCase(retryStepManual.pending, (state) => {
        console.log("RetryStep Pending")
      })
      .addCase(retryStepManual.rejected, (state) => {
        console.log("RetryStep Rejected")
      })
  },
});

export const {
  changeCurrentFilters,
  changePageState,
  resetFlow,
  setIsFlowDetail,
  changeCurrentFlowStatus,
  changeIndex,
} = hyperFlowSlice.actions;

export const selectNumPagesHyperFlow = (state: RootState) =>
  state.hyperFlow.numPages;
export const selectIndex = (state: RootState) =>
  state.hyperFlow.index;
export const selectFlowFiltered = (state: RootState) =>
  state.hyperFlow.flowFiltered;
  export const selectFlowFilteredRevision = (state: RootState) =>
  state.hyperFlow.flowFilteredRevision;
export const selectFilteredOptions = (state: RootState) =>
  state.hyperFlow.filteredOptions;
export const selectCurrentFlow = (state: RootState) =>
  state.hyperFlow.currentFlow;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.hyperFlow.currentFilteredRequest;
export const selectLoadingFlowStatus = (state: RootState) =>
  state.hyperFlow.loadingStatus;
export const selectDocumentLoadingFlow = (state: RootState) =>
  state.hyperFlow.documentLoading;
export const selectIsFlowDetail = (state: RootState) =>
  state.hyperFlow.isFlowDetail;
export const selectCurrentFlowStatus = (state: RootState) =>
  state.hyperFlow.currentFlowStatus;
export const selectStatsFlow = (state: RootState) =>
  state.hyperFlow.miniDashboarData;
export const selectCurrentPageHyperFlow = (state: RootState) =>
  state.hyperFlow.currentFilteredRequest.page;

export default hyperFlowSlice.reducer;
