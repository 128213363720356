import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FooterServices } from "../common/components/texts/FooterServices";
import "./introStyles.scss";
import "./introdStyles.scss";

import {
  changeActiveComponent,
  selectActiveCoreIdComponent,
} from "../services/coreid/coreIdSlice";
import { emptyTokenString, selectTokenString } from "../login/loginSlice";
import {
  getClientAndUserData,
  selectActiveServices,
  selectClientName,
  selectInactiveServices,
  selectLoading,
  selectRoles,
  Service,
} from "../common/commonSlice";
import { getServiceName, getServicePath } from "../../helpers/utils";
import { ListService } from "./components/ListService";
import { TopPanel } from "../services/components/TopPanel";
import { SidePanel } from "../services/components/sidePanel/SidePanel";
import { Category } from "../services/components/sidePanel/categories/Category";
import { CategoriesServicesComponent } from "../services/components/sidePanel/categories/CategoriesServicesComponent";

export const Introv2 = () => {
  //Selectors
  let tokenString = useAppSelector(selectTokenString);
  let loading = useAppSelector(selectLoading);
  let activeServices = useAppSelector(selectActiveServices);
  let inactiveServices = useAppSelector(selectInactiveServices);
  let clientName = useAppSelector(selectClientName);
  let roles = useAppSelector(selectRoles);
  const [selectedService, setSelectedService] = useState<Service>("");
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);

  // Dispatcher
  let dispatch = useAppDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      dispatch(emptyTokenString());
    }
    if (activeServices.length === 0 && inactiveServices.length === 0) {
      dispatch(getClientAndUserData());
    }
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("Registration"));
    }
  });

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />

      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel
          categories={
            <CategoriesServicesComponent
              categories={activeServices.map((service, index) => {
                if (service == "Pagares") {
                  if (
                    roles.includes("pagare.view") ||
                    roles.includes("Admin")
                  ) {
                    return (
                      <Category
                        key={index}
                        name={getServiceName(service)}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(getServicePath(service));
                        }}
                        selected={selectedService === service}
                      />
                    );
                  }
                  return null;
                } else {
                  return (
                    <Category
                      key={index}
                      name={getServiceName(service)}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(getServicePath(service));
                      }}
                      selected={selectedService === service}
                    />
                  );
                }
              })}
            />
          }
        />

        <div
          className="flex-col"
          style={{ justifyContent: "flex-end", width: "80%" }}
        >
          <div className="flex-row" style={{ justifyContent: "space-around" }}>
            <ListService
              serviceName="Magic Forms"
              margin={"5%"}
              size={"text-xxx-large"} //text-xxx-large
              color={"primary-color-40"} //primary-color-40
              activo={activeServices.includes("magicForms")}
            />
            <ListService
              serviceName="Core ID"
              margin={"0%"}
              size={"text-xxx-large"} //text-xxx-large
              color={"primary-color-40"} //primary-color-40
              activo={activeServices.includes("coreId")}
            />
<ListService 
  serviceName="Firma Electrónica"
  margin={"5%"}
  size={"text-xxx-large"} //text-xxx-large
  color={"primary-color-40"} //primary-color-40
  activo={activeServices.includes("electronicSignature")}/>
</div>
<div className="flex-row"
        style={{ justifyContent: "space-around" }}
        >
<ListService 
  serviceName="HyperFlow"
  margin={"0%"}
  size={"text-xxxx-large"} //text-xxx-large
  color={"primary-color-40"} //primary-color-40
  activo={activeServices.includes("hyperFlow")}/>
</div>
<div className="flex-row"
        style={{ justifyContent: "space-around",        marginBottom: "10vh"      }}
        >
<ListService 
  serviceName="Smart Contracts"
  margin={"0%"}
  size={"text-xxx-large"} //text-xxx-large
  color={"primary-color-40"} //primary-color-40
  activo={activeServices.includes("Smart Contracts")}/>
            <ListService
              serviceName="Authenticator"
              margin={"5%"}
              size={"text-xxx-large"} //text-xxx-large
              color={"primary-color-40"} //primary-color-40
              activo={activeServices.includes("Authenticator")}
            />

            <ListService
              serviceName="Biometrix Pay"
              margin={"0%"}
              size={"text-xxx-large"} //text-xxx-large
              color={"primary-color-40"} //primary-color-40
              activo={activeServices.includes("Biometrix Pay")}
            />
          </div>

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
