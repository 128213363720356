import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as TrashCan } from "../../../../Assets/common/images/trashCan.svg";
import { ReactComponent as FirmaNetLogo } from "../../../../Assets/common/images/firmaNetLogo.svg";
import { ReactComponent as CoreIdNetLogo } from "../../../../Assets/common/images/huellaNetLogo.svg";
import { ReactComponent as MagicNetLogo } from "../../../../Assets/common/images/magicNetLogo.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  getFilledFormPDF,
  getFormById,
  selectCurrentFilledForm,
  selectFilledFormsDetailLoading,
  selectFormPDFLoading,
  selectNotFoundMessageMagicForms,
} from "../magicFormsSlice";
import ClipLoader from "react-spinners/ClipLoader";
import "../magicFormsStyles.scss";
import { LoadingStatus } from "../../../common/commonSlice";
import { FilledForm } from "../magicFormsAPI";

export const FilledFormsDetail = () => {
  let dispatch = useAppDispatch();
  let currentFilledForm = useAppSelector(selectCurrentFilledForm);
  let loadingStatus = useAppSelector(selectFilledFormsDetailLoading);
  let pdfLoadingStatus = useAppSelector(selectFormPDFLoading);
  let notFoundMessage = useAppSelector(selectNotFoundMessageMagicForms);

  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm
  ) => {
    switch (loadingPDFStatus) {
      case "idle":
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={`data:application/pdf;base64,${filledForm.document}`}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
    }
  };

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if ((!currentFilledForm || currentFilledForm.id !== id) && id !== "") {
      dispatch(getFormById(id || ""));
    }
    if (currentFilledForm) {
      dispatch(getFilledFormPDF(id!));
    }
    dispatch(changeActiveComponent("none"));
  }, [currentFilledForm]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {notFoundMessage && notFoundMessage !== "" ? (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "idle"
              ? ""
              : "No hay más documentos en esta página"}
          </h1>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            paddingLeft: "2%",
            paddingTop: "1%",
            height: "98%",
            width: "98%",
          }}
        >
          <div className="flex-row" style={{ height: "100%" }}>
            <div className="flex-col" style={{ width: "100%" }}>
              <div
                className="flex-row"
                style={{
                  height: "15%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "5%",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "start",
                      width: "70%",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        alignContent: "center",
                        height: "28px",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex-row"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <LeftArrow style={{ marginRight: "15px" }} />
                  <div className="text-bold text-xx-large primary-color">
                    Detalle formulario
                  </div>
                </div>
              </div>
              <div
                className="flex-row"
                style={{
                  height: "24%",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <div className="flex-col" style={{ marginRight: "5%" }}>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "450px",
                      height: "29px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Datos del Usuario
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "450px",
                      height: "159px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <div
                      className="flex-col text-normal text-medium primary-color-35"
                      style={{
                        marginLeft: "25px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        justifyContent: "space-evenly",
                        height: "100%",
                      }}
                    >
                      <div>
                        Nombre:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          `${currentFilledForm?.userData.name} ${currentFilledForm.userData.lastName}`}
                      </div>
                      <div>Id de Registro: {currentFilledForm?.userId}</div>
                      <div>
                        E-mail:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          currentFilledForm?.userData.email}
                      </div>
                      <div>
                        No. de celular:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          currentFilledForm?.userData.phone}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col">
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "450px",
                      height: "29px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Red de documentos
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "450px",
                      height: "117px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <div
                      className="flex-row text-normal text-medium primary-color-35"
                      style={{
                        justifyContent: "space-evenly",
                        marginTop: "15px",
                        marginBottom: "15px",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="flex-col"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/services/coreId/registrations/${currentFilledForm?.userId}`
                          );
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          border: "none",
                          background: "none",
                          width: "25%",
                        }}
                      >
                        <CoreIdNetLogo />
                        <div className="text-normal text-small primary-color">
                          Core ID
                        </div>
                      </button>
                      <div className="text-normal text-small primary-color">
                        +
                      </div>
                      <button
                        className="flex-col"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          border: "none",
                          background: "none",
                          width: "25%",
                        }}
                      >
                        <MagicNetLogo />
                        <div className="text-normal text-small primary-color">
                          Magic Forms
                        </div>
                      </button>

                      {currentFilledForm?.electronicSignatureId &&
                      currentFilledForm.electronicSignatureId !== "" ? (
                        <>
                          <div className="text-normal text-small primary-color">
                            +
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${currentFilledForm?.electronicSignatureId}`
                              );
                            }}
                            className="flex-col"
                            style={{
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              border: "none",
                              background: "none",
                              width: "25%",
                            }}
                          >
                            <FirmaNetLogo />
                            <div className="text-normal text-small primary-color">
                              Firma electrónica
                            </div>
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex-col"
                style={{
                  marginTop: "24px",
                  alignItems: "center",
                }}
              >
                <div className="flex-col" style={{ width: "67%" }}>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "100%",
                      height: "29px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Documento
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "100%",
                      height: "370px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    {getDocument(pdfLoadingStatus, currentFilledForm!)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
