import axios from "axios";

let baseUrl = process.env.REACT_APP_MANUAL_URL || "";
let user = process.env.REACT_APP_MANUAL_USER || "";
let password = process.env.REACT_APP_MANUAL_PASSWORD || "";

const getManualInfoAPI = async (info: DataManualInfo) => {
  try {
    let authInfo = await axios.get<AuthInfo>(`${baseUrl}/api/authenticate`, {
      auth: { username: user, password },
    });
    let bearerAuth = {
      Authorization: `Bearer ${authInfo.data.token}`,
    };
    let request = {
      identification: info.identificacion,
      uniqueIdentifier: info.identificacion,
      identificationType: info.tipoIdentificacion,
      configurationId: "VigiaRechazado",
    };
    let manualInfo = await axios.post<PersonaResponse>(
      `${baseUrl}/api/transportes/getInfoConductor`,
      request,
      { headers: bearerAuth }
    );
    return manualInfo.data;
  } catch {
    return null;
  }
};

export { getManualInfoAPI };

export interface DataManualInfo {
  identificacion: string;
  tipoIdentificacion: string;
}

export interface PersonaResponse {
  nombreCompleto: string;
  tipoDocumento: string;
  documento: string;
  fechaNacimiento: string;
  alertaProcuraduria: boolean;
  alertaContraloria: boolean;
  alertaJudicialPenal: boolean;
  alertaJudicialCivil: boolean;
  alertaJudicialLaboral: boolean;
  alertaJudicialAltasCortes: boolean;
  alertaComparendo: boolean;
  alertaListas: boolean;
  alertaPep: boolean;
  alertaRunt: boolean;
  alertaPolicia: boolean;
  alertaFosyga: boolean;
  alertaInpec: boolean;
  alertaSofiaPlus: boolean;
  comparendos: Comparendo[];
  registrosProduraduria: RegistrosProduraduria;
  contraloria: ContraloriaData;
  procesosJudiciales: ProcesosJudiciales;
  listasRestrictivas: ListaRestrictiva[];
  detallePep: PEP[];
  runt: RuntInfo;
  policia: Policia[];
  fosyga: Fosyga[];
  inpec: Inpec[];
  sofiaPlus: SofiaPlus[];
  fechaConsulta: Date;
}

export interface RuntInfo {
  solicitudesSicov: Array<SolicitudSicov>;
  solicitudesAptitud: Array<SolicitudAptitud>;
  datosBasicos: RuntDatosBasicos | null;
  certificadosAptitud: Array<CertificadoAptitud>;
  licencias: Array<Licencia>;
}

export interface Licencia {
  numeroLicencia: string;
  otExpide: string;
  fechaExpedicion: string;
  estadoLicencia: string;
  restricciones: string;
  detalleLicencia: Array<DetalleLicencia>;
}

export interface DetalleLicencia {
  fechaExpedicion: string;
  fechaVencimientoExamen: string;
  fechaVencimiento: string;
  categoria: string;
  categoriaAntigua: string;
}
export interface RuntDatosBasicos {
  apellidos: string;
  noInscrito: boolean;
  estadoConductor: string;
  nroInscripcion: string;
  fechaInscripcion: string;
  nombres: string;
  tipoDocumento: string;
  tieneLicencias: boolean;
  estadoPersona: string;
}

export interface CertificadoAptitud {
  fechaExpedicion: string;
  estado: string;
  fechaSolicitud: string;
  nombreCea: string;
  categoria: string;
  numeroCertificado: string;
  nroFupa: string;
  tramiteSolicitado: string;
  tipoCertificado: string;
}

export interface SolicitudSicov {
  numeroSolicitud: string;
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  descripcionTipoValidacion: string;
  tramitesRealizados: string;
}

export interface SolicitudAptitud {
  numeroSolicitud: string;
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  tramitesRealizados: string;
  identificador: string;
}

export interface Comparendo {
  numeroComparendo: string;
  secretaria: string;
  fecha: string;
  fechaNotificacion: string;
  nombreInfractor: string;
  estado: string;
  infraccion: string;
  valorMulta: string;
  valorAdicional: string;
  interesMora: string;
  valorPagar: string;
}

export interface ListaRestrictiva {
  nombre: string;
  tieneRiesgo: boolean;
  descripcion: string;
}

export interface PEP {
  nombre: string;
  descripcion: string;
}

export interface Policia {
  id: string;
  idDatoConsultado: string;
  mensaje: string;
  mensajeRespuesta: string;
}
export interface Fosyga {
  id: string;
  idDatoConsultado: string;
  estado: string;
  fechaAfiliacion: string;
  entidad: string;
  regimen: string;
  tipoAfiliado: string;
  nombres: string;
  apellidos: string;
  fechaNacimiento: string;
  numIdentificacion: string;
  tipoIdentificacion: string;
  municipio: string;
  departamento: string;
}
export interface Inpec {
  id: string;
  idDatoConsultado: string;
  numeroUnico: string;
  establecimientoACargo: string;
  situacionJuridica: string;
  estadoIngreso: string;
  genero: string;
  identificacion: string;
  nombre: string;
}
export interface SofiaPlus {
  id: string;
  idDatoConsultado: string;
  fechaCertificado: string;
  descarga: string;
  programa: string;
  tipoCertificado: string;
  estadoAprendiz: string;
  estadoCertificacion: string;
}

export interface ProcesosJudiciales {
  penales: ProcesoJudicial[];
  civiles: ProcesoJudicial[];
  laborales: ProcesoJudicial[];
  altasCortes: ProcesoJudicial[];
}

export interface ProcesoJudicial {
  proceso: string;
  entidad: string;
  fecha: string;
  clase: string;
  ponente: string;
  demandantes: string;
  demandados: string;
}

export interface ContraloriaData {
  descripcion: string;
}

export interface RegistrosProduraduria {
  sanciones: SancionProcuraduria[];
  delitos: DelitoProcuraduria[];
  instancias: InstanciaProcuraduria[];
  inhabilidad: InhabilidadProcuraduria[];
}

export interface SancionProcuraduria {
  sancion: string;
  termino: string;
  clase: string;
  suspendida: string;
}

export interface DelitoProcuraduria {
  descripcion: string;
}

export interface InstanciaProcuraduria {
  nombre: string;
  autoridad: string;
  fecha: string;
}

export interface InhabilidadProcuraduria {
  siri: string;
  modulo: string;
  inhabilidad: string;
  fechaInicio: string;
  fechaFin: string;
}

export interface AuthInfo {
  token: string;
  expiresIn: number;
}
