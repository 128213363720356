import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  getAuthenticationImage,
  getGeneratedDocuments,
  getSignatureById,
  selectCurrentDocuments,
  selectCurrentFirma,
  selectCurrentImage,
  selectDocumentsLoading,
  selectImageLoading,
  selectLoading,
} from "../firmaSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { MediumSquare } from "./components/Squares";
import { getStatusName } from "../helpers/utils";
import { formatDateStandar } from "../../../../helpers/utils";
import { FirmarImages } from "./components/FirmaImages";
import { LoadingStatus } from "../../../common/commonSlice";
import { GeneratedDocuments, VerificationImage } from "../firmaAPI";
export const DetailFirma = () => {
  let loadingStatus = useAppSelector(selectLoading);
  let documentsLoading = useAppSelector(selectDocumentsLoading);
  let imageLoading = useAppSelector(selectImageLoading);
  let currentSignature = useAppSelector(selectCurrentFirma);
  let currentImage = useAppSelector(selectCurrentImage);
  let documents = useAppSelector(selectCurrentDocuments);

  const [documentToVisualize, setDocumentToVisualize] = useState<{
    documentBase64: string;
    documentName: string;
  }>({
    documentBase64: "",
    documentName: "",
  });

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let getDocument = (
    loadingDocuments: LoadingStatus,
    generatedDocuments: GeneratedDocuments,
    documentType: "Certificate" | "SignedDocument",
    status: string,
    setDocumentVisualizer: React.Dispatch<
      React.SetStateAction<{
        documentBase64: string;
        documentName: string;
      }>
    >
  ) => {
    switch (loadingDocuments) {
      case "pending":
        return (
          <LoadingOverlay
            active={documentsLoading === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "resolved":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          if (documentType === "Certificate") {
            return (
              <>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.certificate.documentBase64,
                      documentName: generatedDocuments.certificate.documentName,
                    });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "2%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `data:application/pdf;base64,${generatedDocuments.certificate.documentBase64}`
                    );
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.document.documentBase64,
                      documentName: generatedDocuments.document.documentName,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "2%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `data:application/pdf;base64,${generatedDocuments.document.documentBase64}`
                    );
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          }
        }
      case "rejected":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar los documentos
            </div>
          );
        }
      default:
        return (
          <>
            <div
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: "2%",
              }}
            >
              <div style={{ marginRight: "5%" }}>Visualizar</div>
              <EyeIcon />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: "5%" }}>Descargar</div>
              <EyeIcon />
            </div>
          </>
        );
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if ((!currentSignature || currentSignature.id !== id) && id !== "") {
      dispatch(getSignatureById(id || ""));
    }

    if (currentSignature && documentsLoading === "idle") {
      dispatch(getGeneratedDocuments(currentSignature.id));
    }

    if (currentSignature && imageLoading === "idle") {
      dispatch(getAuthenticationImage(currentSignature.id));
    }

    dispatch(changeActiveComponent("none"));

    return () => {};
  }, [id, currentSignature]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {currentSignature &&
      currentSignature.signers.length > 0 &&
      loadingStatus === "resolved" ? (
        <div className="flex-col" style={{ height: "100%", overflow: "auto" }}>
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a lista de verificaciones
              </div>
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              height: "85%",
              width: "100%",
              justifyContent: "start",
              padding: "1%",
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: "start",
                height: "20%",
              }}
            >
              <MediumSquare
                parentWidth="23%"
                title="Estado de la firma"
                content={
                  <div>{getStatusName(currentSignature.signers[0].status)}</div>
                }
              />
              <MediumSquare
                parentWidth="23%"
                title="Fecha de la firma"
                content={
                  <div>
                    {formatDateStandar(currentSignature.signers[0].date)}
                  </div>
                }
              />
              <MediumSquare
                parentWidth="23%"
                title="Alertas"
                content={
                  <div>
                    {currentSignature.signers[0].alerts === 0
                      ? "Ninguna"
                      : currentSignature.signers[0].alerts.toString()}
                  </div>
                }
              />
              <MediumSquare
                parentWidth="23%"
                title="Método de verificación"
                content={
                  <div>{currentSignature.signers[0].validationMethod}</div>
                }
              />
            </div>
            <div
              className="flex-row"
              style={{
                height: "60%",
                justifyContent: "start",
              }}
            >
              <div
                className="flex-col"
                style={{ width: "47%", marginRight: "1%" }}
              >
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Datos del usuario
                </div>
                <div
                  className="flex-col text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    height: "85%",
                    marginBottom: "3%",
                    boxSizing: "border-box",
                    padding: "5%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      Nombre :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {currentSignature.signers[0].name}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      Apellido :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {currentSignature.signers[0].lastName}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      Id de registro :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {currentSignature.signers[0].registrationId}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      Estado :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {getStatusName(currentSignature.signers[0].status)}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      E-mail :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {currentSignature.signers[0].email}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div className="text-normal text-medium primary-color">
                      No. de celular :
                    </div>
                    <div className="text-normal text-medium primary-color">
                      {currentSignature.signers[0].phoneNumber}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col" style={{ width: "47%" }}>
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Imagen del firmante
                </div>
                <div
                  className="flex-col text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    height: "85%",
                    marginBottom: "3%",
                    boxSizing: "border-box",
                    padding: "5%",
                  }}
                >
                  <FirmarImages
                    currentImage={currentImage!}
                    verificationMethod={
                      currentSignature.signers[0].validationMethod
                    }
                    loadingImages={imageLoading}
                  />
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                height: "20%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  width: "50%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <MediumSquare
                  parentWidth="46%"
                  title="Documento Firmado"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "SignedDocument",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
                <MediumSquare
                  parentWidth="46%"
                  title="Certificado de firma"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "Certificate",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
              </div>
            </div>
          </div>
          {documentToVisualize.documentBase64 !== "" ? (
            <object
              data={`data:application/pdf;base64,${documentToVisualize.documentBase64}`}
              type="application/pdf"
              width="100%"
              height="100%"
            ></object>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
