import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { getRegistrationByIdAPI } from "../coreid/coreIdAPI";
import {
  FilledForm,
  FilledFormFiltered,
  getFilledFormByIdAPI,
  getFilledFormsFilteredAPI,
  getAllTemplatesAPI,
  getTemplateByIdAPI,
  Template,
  FilledFormFilteredRequest,
  FilledFormFilterOptions,
  getFilledFormsFilterOptionsAPI,
  getPdfFilledFormsAPI,
} from "./magicFormsAPI";

export type MagicActiveComponent = "Filledforms" | "Templates" | "none";

export interface MagicFormsState {
  loadingFilledFormsStatus: LoadingStatus;
  loadingTemplatesStatus: LoadingStatus;
  loadingFilledFormDetailStatus: LoadingStatus;
  loadingTemplateDetailStatus: LoadingStatus;
  loadingFilterOptionsStatus: LoadingStatus;
  loadingFilledFormPDFStatus: LoadingStatus;
  filledForms: FilledFormFiltered | null;
  templates: Array<Template> | null;
  notFoundMessage: string;
  currentPage: number;
  currentNumPages: number;
  activeComponent: MagicActiveComponent;
  currentFilledForm: FilledForm | null;
  currentTemplate: Template | null;
  dateStart: string | null;
  dateEnd: string | null;
  currentFillFormRequest: FilledFormFilteredRequest;
  filterOptionsFilledForm: FilledFormFilterOptions | null;
}

const initialState: MagicFormsState = {
  loadingFilledFormDetailStatus: "idle",
  loadingFilledFormsStatus: "idle",
  loadingFilterOptionsStatus: "idle",
  loadingTemplateDetailStatus: "idle",
  loadingTemplatesStatus: "idle",
  loadingFilledFormPDFStatus: "idle",
  filledForms: null,
  templates: null,
  notFoundMessage: "",
  currentPage: 1,
  currentNumPages: 0,
  activeComponent: "none",
  currentFilledForm: null,
  currentTemplate: null,
  dateStart: new Date().toISOString(),
  dateEnd: null,
  currentFillFormRequest: { templateId: null, page: 0 },
  filterOptionsFilledForm: null,
};

export const getFilterOptions = createAsyncThunk(
  "magicForms/getFilterOptions",
  async (_, { rejectWithValue }) => {
    try {
      const filterOptions = await getFilledFormsFilterOptionsAPI();
      if (filterOptions.filterOptions) {
        return filterOptions.filterOptions;
      } else {
        rejectWithValue(filterOptions.error);
      }
    } catch (err: any) {
      rejectWithValue(err.message);
    }
  }
);

export const getFilteredForms = createAsyncThunk(
  "magicForms/getFilteredForms",
  async (params: FilledFormFilteredRequest, { rejectWithValue }) => {
    try {
      const filteredForms = await getFilledFormsFilteredAPI(params);
      if (filteredForms.forms) {
        for (let form of filteredForms.forms.filledForms) {
          try {
            let userInfo = await getRegistrationByIdAPI(form.userId);
            if (userInfo.registration) {
              form.userData = userInfo.registration;
            } else {
              form.userData = null;
            }
          } catch {
            form.userData = null;
          }
        }
        return filteredForms.forms;
      } else {
        rejectWithValue(filteredForms.error);
      }
    } catch (err: any) {
      rejectWithValue(err.message);
    }
  }
);

export const getFilteredTemplates = createAsyncThunk(
  "magicForms/getFilteredTemplates",
  async () => {
    const filteredTemplates = await getAllTemplatesAPI();
    return filteredTemplates;
  }
);

export const getFormById = createAsyncThunk(
  "magicForms/getFormById",
  async (id: string) => {
    const filledForm = await getFilledFormByIdAPI(id);

    if (filledForm.filledForm) {
      try {
        const userInfo = await getRegistrationByIdAPI(
          filledForm.filledForm.userId
        );
        if (userInfo.registration) {
          filledForm.filledForm.userData = userInfo.registration;
        } else {
          filledForm.filledForm.userData = null;
        }
      } catch {
        filledForm.filledForm.userData = null;
      }
    }

    return filledForm;
  }
);

export const getFilledFormPDF = createAsyncThunk(
  "magicForms/getFormPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getTemplateById = createAsyncThunk(
  "magicForms/getTemplateById",
  async (id: string) => {
    const template = await getTemplateByIdAPI(id);
    return template;
  }
);

export const magicFormsSlice = createSlice({
  name: "magicFormsSlice",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<MagicActiveComponent>
    ) => {
      state.activeComponent = action.payload;
      state.currentPage = 1;
      state.currentNumPages = 0;
      state.dateEnd = null;
      state.dateStart = new Date().toISOString();
    },
    changeEndDate: (state, action: PayloadAction<string | null>) => {
      state.dateEnd = action.payload;
    },
    changeStartDate: (state, action: PayloadAction<string | null>) => {
      state.dateStart = action.payload;
      state.dateEnd = null;
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFillFormRequest;
      state.currentFillFormRequest = { ...current, page: action.payload };
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<FilledFormFilteredRequest>
    ) => {
      state.currentFillFormRequest = { ...action.payload, page: 0 };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredForms.fulfilled, (state, action) => {
        let forms = action.payload;
        if (forms) {
          state.filledForms = forms;
          state.currentPage = forms.currentPage;
          state.currentNumPages = forms.numPages;
        }
        state.loadingFilledFormsStatus = "resolved";
      })
      .addCase(getFilteredForms.pending, (state) => {
        state.loadingFilledFormsStatus = "pending";
      })
      .addCase(getFilteredForms.rejected, (state) => {
        state.loadingFilledFormsStatus = "rejected";
        state.notFoundMessage = "";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFilteredTemplates.fulfilled, (state, action) => {
        let templates = action.payload;
        state.templates = templates.templates;
        state.loadingTemplatesStatus = "resolved";
      })
      .addCase(getFilteredTemplates.pending, (state) => {
        state.loadingTemplatesStatus = "pending";
      })
      .addCase(getFilteredTemplates.rejected, (state) => {
        state.loadingTemplatesStatus = "rejected";
        state.notFoundMessage = "";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFormById.fulfilled, (state, action) => {
        let filledForm = action.payload;
        state.currentFilledForm = filledForm.filledForm;
        if (filledForm === null) {
          state.notFoundMessage === "Registro no encontrado";
        }
        state.loadingFilledFormDetailStatus = "resolved";
        state.activeComponent = "none";
      })
      .addCase(getFormById.pending, (state) => {
        state.loadingFilledFormDetailStatus = "pending";
      })
      .addCase(getFormById.rejected, (state) => {
        state.notFoundMessage === "Registro no encontrado";
        state.loadingFilledFormDetailStatus = "rejected";
        state.activeComponent = "none";
      })
      .addCase(getTemplateById.fulfilled, (state, action) => {
        let filledForm = action.payload;
        state.currentTemplate = filledForm.template;
        if (filledForm === null) {
          state.notFoundMessage === "Registro no encontrado";
        }
        state.loadingTemplateDetailStatus = "resolved";
        state.activeComponent = "none";
      })
      .addCase(getTemplateById.pending, (state) => {
        state.loadingTemplateDetailStatus = "pending";
      })
      .addCase(getTemplateById.rejected, (state) => {
        state.notFoundMessage === "Registro no encontrado";
        state.loadingTemplateDetailStatus = "rejected";
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filterOptionsFilledForm = filterOptions;
        }
        state.loadingFilterOptionsStatus = "resolved";
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingFilterOptionsStatus = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.notFoundMessage === "Registro no encontrado";
        state.loadingFilterOptionsStatus = "rejected";
      })
      .addCase(getFilledFormPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        let filledFormData = state.currentFilledForm;
        if (filledFormData && pdf) {
          filledFormData.document = pdf.pdfBase64;
        }
        state.currentFilledForm = filledFormData;
        state.loadingFilledFormPDFStatus = "resolved";
      })
      .addCase(getFilledFormPDF.pending, (state) => {
        state.loadingFilledFormPDFStatus = "pending";
      })
      .addCase(getFilledFormPDF.rejected, (state) => {
        state.loadingFilledFormPDFStatus = "rejected";
      });
  },
});

export const {
  changeActiveComponent,
  changeEndDate,
  changeStartDate,
  changePageState,
  changeCurrentFilters,
} = magicFormsSlice.actions;

export const selectMagicFormsLoadingFilteredOptions = (state: RootState) =>
  state.magicForms.loadingFilterOptionsStatus;
export const selectFilledFormsLoading = (state: RootState) =>
  state.magicForms.loadingFilledFormsStatus;
export const selectFilledFormsDetailLoading = (state: RootState) =>
  state.magicForms.loadingFilledFormDetailStatus;
export const selectTemplatesLoading = (state: RootState) =>
  state.magicForms.loadingTemplatesStatus;
export const selectTemplateDetailsLoading = (state: RootState) =>
  state.magicForms.loadingTemplateDetailStatus;
export const selectFormPDFLoading = (state: RootState) =>
  state.magicForms.loadingFilledFormPDFStatus;
export const selectNotFoundMessageMagicForms = (state: RootState) =>
  state.magicForms.notFoundMessage;
export const selectFilledForms = (state: RootState) =>
  state.magicForms.filledForms;
export const selectCurrentPageMagic = (state: RootState) =>
  state.magicForms.currentPage;
export const selectCurrentNumPages = (state: RootState) =>
  state.magicForms.currentNumPages;
export const selectActiveMagicComponent = (state: RootState) =>
  state.magicForms.activeComponent;
export const selectTemplatesMagic = (state: RootState) =>
  state.magicForms.templates;
export const selectCurrentFilledForm = (state: RootState) =>
  state.magicForms.currentFilledForm;
export const selectCurrentTemplate = (state: RootState) =>
  state.magicForms.currentTemplate;
export const selectEndDateMagic = (state: RootState) =>
  state.magicForms.dateEnd;
export const selectStartDateMagic = (state: RootState) =>
  state.magicForms.dateStart;
export const selectFilteredFormRequest = (state: RootState) =>
  state.magicForms.currentFillFormRequest;
export const selectFilterOptionsFilledForms = (state: RootState) =>
  state.magicForms.filterOptionsFilledForm;

export default magicFormsSlice.reducer;
