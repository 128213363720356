import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import "../coreIdStyles.scss";
import {
  changeActiveComponent,
  changePageState,
  getDashboardItems,
  getFilteredRegistrations,
  resetRegistration,
  selectActiveCoreIdComponent,
  selectCoreIdLoading,
  selectCurrentFilterRequest,
  selectCurrentPageCoreId,
  selectFilteredRegistrations,
  selectMiniDashboardData,
  selectNumPagesCoreId,
} from "../coreIdSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct } from "../../../common/commonSlice";
import { formatDateStandar } from "../../../../helpers/utils";

export const Registrations = () => {
  let currentPage = useAppSelector(selectCurrentPageCoreId);
  let numPages = useAppSelector(selectNumPagesCoreId);
  let loading = useAppSelector(selectCoreIdLoading);
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let miniDashboardData = useAppSelector(selectMiniDashboardData);
  let registrations = useAppSelector(selectFilteredRegistrations);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const getStatusName = (status: string) => {
    switch (status) {
      case "ACCEPTED":
        return "Aprobado";

      case "IN_PROGRESS":
        return "En progreso";
      case "REJECTED":
        return "Rechazado";
      case "REVISION":
        return "Por revisar";
      default:
        return "";
    }
  };

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };
  let getPercentage = (number: number) => {
    {
      let percentageNumber = (number * 100).toFixed(2);
      return `${percentageNumber}%`;
    }
  };

  useEffect(() => {
    if (activeComponent !== "Registration") {
      dispatch(changeActiveComponent("Registration"));
    }
    dispatch(changeCurrentProduct("Core ID"));

    if (!miniDashboardData || miniDashboardData.totalRegs <= 0) {
      dispatch(getDashboardItems());
    }

    dispatch(
      getFilteredRegistrations({
        ...currentFilteredRequest,
      })
    );
    dispatch(resetRegistration());

    return () => {};
  }, [activeComponent, currentFilteredRequest]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {miniDashboardData &&
      registrations &&
      registrations.registrations.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "13%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          <div
            className="flex-row"
            style={{
              height: "15%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "2%",
            }}
          >
            <div
              className="text-medium text-bold primary-color"
              style={{ alignSelf: "start", marginRight: "5%" }}
            >
              Autenticaciones hoy
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Nuevos registros:
              </div>
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.newRegs}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros totales
              </div>
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.totalRegs}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros Completados
              </div>
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.regsSuccess}
              </div>
            </div>
            <div className="flex-row" style={{ marginRight: "5%" }}>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "min-content", marginRight: "20px" }}
              >
                Registros Fallidos
              </div>
              <div className="text-huge text-bold primary-color">
                {miniDashboardData.regsFailed}
              </div>
            </div>
            <div
              className="flex-col"
              style={{ alignItems: "center", alignSelf: "end" }}
            >
              <div className="text-huge text-bold primary-color">
                {getPercentage(miniDashboardData.fraudRate)}
              </div>
              <div
                className="text-medium text-bold primary-color"
                style={{ width: "145px", textAlign: "center" }}
              >
                Tasa de intento de fraude detectado
              </div>
            </div>
          </div>
          {registrations.registrations ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Fecha y Hora</th>
                      <th>E-mail</th>
                      <th>Estado</th>
                      <th>Alertas</th>
                      <th>ID Core ID</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {registrations.registrations.map((reg, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/coreId/registrations/${reg.id}`
                            );
                          }}
                        >
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {reg.fullName}
                          </td>
                          <td>{formatDateStandar(reg.dateTime)}</td>
                          <td>{reg.email}</td>
                          <td style={{ textTransform: "uppercase" }}>
                            {reg.status ? getStatusName(reg.status) : ""}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {reg.alerts === 0 ? "Ninguna" : reg.alerts}
                          </td>
                          <td>{reg.id}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay más registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{
                marginRight: "100px",
                fontSize: "13px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {"<"}
              </button>
              Página {currentPage + 1} de {numPages}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle"
              ? ""
              : "No hay más registros en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
