import format from "date-fns/format";
import { Service } from "../features/common/commonSlice";

let formatDateStandar = (dateString: string) => {
  let date = new Date(dateString);
  if (date) {
    let formatedDate = format(date, "dd/MM/yyyy");
    let formatedTime = format(date, "hh:mm a");
    return `${formatedDate} | ${formatedTime}`;
  } else {
    return "Sin Fecha";
  }
};

let getServiceName = (name: Service) => {
  switch (name) {
    case "coreId":
      return "Core ID";
    case "electronicSignature":
      return "Firma Electrónica";
    case "firmaNew":
      return "Firma Electrónica";
    case "magicForms":
      return "Magic Forms";
    case "hyperFlow":
      return "HyperFlow";
    case "smartFlow":
      return "Smart Flow";
    case "Pagares":
      return "Pagarés";
    default:
      return name;
  }
};

let getServicePath = (name: Service) => {
  switch (name) {
    case "coreId":
      return "/services/coreId/registrations";
    case "electronicSignature":
      return "/services/electronicSignature/signatures";
    case "firmaNew":
      return "/services/electronicSignatureNew/signatures";
    case "magicForms":
      return "/services/magicForms/filledForms";
    case "hyperFlow":
      return "/services/hyperFlow/flows";
    case "smartFlow":
      return "/services/smartFlow/flows";
    case "Pagares":
      return "/services/pagares/list";
    default:
      return "";
  }
};

let getStatusFlow = (
  name: String,
) => {
  switch (name) {
    case "IN_PROGRESS":
      return ("En proceso");
    case "DONE":
      return ("Completado");
    case "AWAITING_MANUAL_INTERACTION":
      return ("En revisión");
    case "AWAITING_USER_INTERACTION":
      return ("En espera usuario");
    default:
      return (name);
  }
};

let getServicePathData = (name: string) => {
  switch (name) {
    case "Core ID":
      return "/services/coreId/registrations";
    case "Adjuntos":
      return "/services/electronicSignature/signatures";
    case "Firma":
      return "/services/electronicSignatureNew/signatures";
    case "Firma Electrónica":
      return "/services/electronicSignatureNew/signatures";
    case "Magic Forms":
      return "/services/magicForms/filledForms";
    case "HyperFlow":
      return "/services/hyperFlow/flows";
    case "Smart Flow":
      return "/services/smartFlow/flows";
    default:
      return "";
  }
};

export {
  formatDateStandar,
  getServiceName,
  getServicePath,
  getServicePathData,getStatusFlow
};
