import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectCurrentFilterRequest,
  getFilterOptions,
  selectActiveCoreIdComponent,
  selectFilterOptions,
  changeCurrentProject,
  selectCurrentCoreIdAlertsRequest,
  changeCurrentAlertFilters,
  selectCurrentRegistration,
  changeStatus,
} from "./coreIdSlice";
import "./coreIdStyles.scss";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { DropDown } from "../../common/components/Btns/DropDown";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { ChooseState } from "./detail/components/buttons/ChooseState";
import axios from "axios";

export const CoreId = () => {
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let filterOptions = useAppSelector(selectFilterOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let alertsFilteredRequest = useAppSelector(selectCurrentCoreIdAlertsRequest);
  let currentAlertsRequest = useAppSelector(selectCurrentCoreIdAlertsRequest);
  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let roles = useAppSelector(selectRoles);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Registration") {
      let projectOptions: { value: string; label: string }[] = [];
      let projects = filterOptions?.projects;
      if (projects) {
        for (const project in projects) {
          let label = projects[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }
      let projectDropdown = (
        <DropDown
          key={"Projects"}
          title="Proyecto"
          options={projectOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                projectId: e?.value!,
              })
            );
          }}
        />
      );
      let estadoDropdown = (
        <DropDown
          key={"Status"}
          title="Estado"
          options={[
            { label: "Aprobado", value: "ACCEPTED" },
            { label: "Rechazado", value: "REJECTED" },
            { label: "Por revisar", value: "REVISION" },
            { label: "En proceso", value: "IN_PROGRESS" },
          ]}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                result: e?.value!,
              })
            );
          }}
        />
      );
      let searchInput = (
        <SearchInputFilter
          key={"SearchRegistrations"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "80%", alignSelf: "center" }}
          placeHolder="No. de documento"
        />
      );
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={true}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      endDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      startDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            resetFunction={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  endDate: null,
                  startDate: null,
                })
              );
            }}
            items={[projectDropdown, estadoDropdown, searchInput]}
          />
        </>
      );
    } else if (activeComponent === "Alerts") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          items={[
            <div
              key={0}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por categoría
            </div>,
            <div key={1} style={{ alignSelf: "center" }}>
              <RadioButton
                label="Compliance"
                onClick={() => {
                  if (currentAlertsRequest.type === "COMPLIANCE") {
                    dispatch(
                      changeCurrentAlertFilters({
                        ...alertsFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                checked={alertsFilteredRequest.type === "COMPLIANCE"}
                onChange={() => {
                  dispatch(
                    changeCurrentAlertFilters({
                      ...alertsFilteredRequest,
                      type: "COMPLIANCE",
                    })
                  );
                }}
              />
              <RadioButton
                label="Fechas"
                onClick={() => {
                  if (currentAlertsRequest.type === "DATES") {
                    dispatch(
                      changeCurrentAlertFilters({
                        ...alertsFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                checked={alertsFilteredRequest.type === "DATES"}
                onChange={() => {
                  dispatch(
                    changeCurrentAlertFilters({
                      ...alertsFilteredRequest,
                      type: "DATES",
                    })
                  );
                }}
              />
              <RadioButton
                label="Incongruencias"
                onClick={() => {
                  if (currentAlertsRequest.type === "INCONGRUENCES") {
                    dispatch(
                      changeCurrentAlertFilters({
                        ...alertsFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                checked={alertsFilteredRequest.type === "INCONGRUENCES"}
                onChange={() => {
                  dispatch(
                    changeCurrentAlertFilters({
                      ...alertsFilteredRequest,
                      type: "INCONGRUENCES",
                    })
                  );
                }}
              />
              <RadioButton
                label="Adjuntos"
                onClick={() => {
                  if (currentAlertsRequest.type === "ATTACHMENTS") {
                    dispatch(
                      changeCurrentAlertFilters({
                        ...alertsFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                checked={currentAlertsRequest.type === "ATTACHMENTS"}
                onChange={() => {
                  dispatch(
                    changeCurrentAlertFilters({
                      ...currentAlertsRequest,
                      type: "ATTACHMENTS",
                    })
                  );
                }}
              />
              <RadioButton
                label="Autenticidad"
                onClick={() => {
                  if (currentAlertsRequest.type === "AUTHENTICITY") {
                    dispatch(
                      changeCurrentAlertFilters({
                        ...alertsFilteredRequest,
                        type: null,
                      })
                    );
                  }
                }}
                checked={currentAlertsRequest.type === "AUTHENTICITY"}
                onChange={() => {
                  dispatch(
                    changeCurrentAlertFilters({
                      ...currentAlertsRequest,
                      type: "AUTHENTICITY",
                    })
                  );
                }}
              />
            </div>,
          ]}
        />
      );
    } else if (activeComponent === "Flow") {
      let projectOptions: { value: string; label: string }[] = [];
      let projects = filterOptions?.projects;
      if (projects) {
        for (const project in projects) {
          let label = projects[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }
      let projectDropdown = (
        <DropDown
          key={"Projects"}
          title="Proyecto"
          options={projectOptions}
          onChange={(e) => {
            if (e?.value) {
              dispatch(changeCurrentProject(e?.value));
            }
          }}
        />
      );
      return (
        <FilterComponent
          key={2}
          hasCalendar={false}
          items={[projectDropdown]}
        />
      );
    } else if (activeComponent === "Manual") {
      return;
    } else if (activeComponent === "none") {
      return;
    } else if (activeComponent === "RegistrationDetail") {
      if (roles.includes("coreid.admin") || roles.includes("coreid.editor")) {
        return (
          <ChooseState
            status={currentRegistration?.status!}
            onConfirm={(e: "ACCEPTED" | "REJECTED" | "REVISION" | "") => {
              if (e !== "") {
                dispatch(
                  changeStatus({
                    registrationId: currentRegistration?.id!,
                    newStatus: e,
                  })
                );
              }
            }}
          />
        );
      } else {
        return;
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("Registration"));
    }

    if (!filterOptions) {
      dispatch(getFilterOptions());
    }
  }, [activeComponent, filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={
            <CategoriesComponent
              categories={[
                <Category
                  key={0}
                  name="Registros"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changeCurrentProduct("Core ID"));
                    navigate("/services/coreId/registrations");
                  }}
                  selected={activeComponent === "Registration"}
                />,
                <Category
                  key={2}
                  name="Alertas"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changeCurrentProduct("Alertas"));
                    navigate("/services/coreId/alerts");
                  }}
                  selected={activeComponent === "Alerts"}
                />,
              ]}
            />
          }
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
