import "./titles.scss";
export interface TitleProps {
  text: string;
  order?: number;
  multiple?: boolean;
  list?: string;
  bootom?: string;

}
export const ProductTitleText = (props: TitleProps) => {
  let styleOrder = "first";
  switch (props.order) {
    case 0:
      break;
    case 1:
      styleOrder = "second";
      break;
    case 2:
      styleOrder = "third";
      break;
    case 3:
      styleOrder = "fourth";
      break;
    case 4:
      styleOrder = "fifth";
      break;
    case 5:
      styleOrder = "sixth";
      break;
    default:
      break;
  }
  return (
    <h3
      className={`product-titles ${styleOrder} ${(props.multiple) ? "multiple" : "one"} ${
        props.list} ${props.bootom}`} 
    >
      {props.text}
    </h3>
  );
};

export interface SecondaryProductTitleProps {
  title?: string;
}

export const SecondaryProductTitle = (props: SecondaryProductTitleProps) => {
  return (
    <h3 className="secondary-product-title">
      {props.title ? props.title : "anteia"}
    </h3>
  );
};

export interface TerciaryProductTitleProps {
  title?: string;
  subtitle?: string;
}
export const TerciaryProductTitle = (props: TerciaryProductTitleProps) => {
  return (
    <div>
      <h6 className="terciary-product-title first">
        {props.title ? props.title : "identidad digital"}
      </h6>
      <p className="terciary-product-subtitle first">
        {props.title ? props.title : "sobre COMPUTACION VISUAL"}
      </p>
    </div>
  );
};
