import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { login, recoverEmail, Token, TokenRecovery } from "./loginAPI";
import localforage from "localforage";

// Tipos del estado del login
export interface LoginState {
  username: string;
  password: string;
  email: string;
  loading: boolean;
  errorMessage: string;
  tokenString: string;
  emailRecovery: string;

}

// Estado inicial del login
const initialState: LoginState = {
  username: "",
  password: "",
  email: "",
  loading: false,
  errorMessage: "",
  tokenString: "",
  emailRecovery: ""
};

// Thunks que permite realizar funciones asincronicas y poder definir que
// pasa segun el resultado (rechazado, aceptado, pendiente).
export const tryLogin = createAsyncThunk(
  "login/tryLogin",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const username = currentState.login.username;
    const password = currentState.login.password;

    const token = await login(username, password);
    if (!token.loggedIn) {
      return rejectWithValue(token);
    } else {
      await localforage.setItem("token", token.token);
      return token;
    }
  }
);
export const recovery = createAsyncThunk(
  "login/recovery",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const email = currentState.login.emailRecovery;

    const token = await recoverEmail(email);
    return token;
    
  }
);

// Slice, contiene los reducers que se ejecutan sincronicamente
// tambien extraReducers que contienen casos segun la respuesta de los thunk que se
// ejecutan asincronicamente
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    emptyTokenString: (state) => {
      state.tokenString = "";
    },
    emptyErrorMessage: (state) => {
      state.errorMessage = "";
    },
    changeEmailRecovery: (state, action: PayloadAction<string>) => {
      state.emailRecovery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryLogin.fulfilled, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          if (token.loggedIn) {
            state.username = "";
            state.password = "";
            state.tokenString = token.token;
            state.errorMessage = "";
          }
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          state.errorMessage = token.errorMessage;
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(tryLogin.pending, (state) => {
        state.loading = true;
      }).addCase(recovery.fulfilled, (state, action) => {
        let token = action.payload as TokenRecovery;
        if (token) {
          
            state.errorMessage = token.errorMessage;
          
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.loading = false;
      })
      .addCase(recovery.rejected, (state, action) => {
        let token = action.payload as TokenRecovery;
        if (token) {
          state.errorMessage = token.errorMessage;
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.loading = false;
      })
      .addCase(recovery.pending, (state) => {
        state.loading = true;
      });
  },
});

// Se exponen las acciones que se pueden realizar en este slice.
export const {
  changePassword,
  changeUsername,
  changeEmail,
  changeEmailRecovery,
  emptyTokenString,
  emptyErrorMessage,
  
} = loginSlice.actions;

// Selectors: Se exportan los valores que contiene el slice.
export const selectUsername = (state: RootState) => state.login.username;
export const selectPassword = (state: RootState) => state.login.password;
export const selectEmail = (state: RootState) => state.login.email;
export const selectLoginLoading = (state: RootState) => state.login.loading;
export const selectLoginErrorMessage = (state: RootState) =>
  state.login.errorMessage;
export const selectTokenString = (state: RootState) => state.login.tokenString;
export const selectEmailRecovery = (state: RootState) => state.login.emailRecovery;

// Se exporta el reducer para poder ser utilizado en el store global
export default loginSlice.reducer;
