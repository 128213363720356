import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct } from "../../../common/commonSlice";
import {
  changePageState,
  getFilteredFlow,
  resetFlow,
  selectCurrentFilterRequest,
  selectCurrentPageSmartFlow,
  selectFlowFiltered,
  selectLoadingFlowStatus,
  selectNumPagesSmartFlow,
  setIsFlowDetail,
} from "../smartFlowSlice";
import { formatDateStandar } from "../../../../helpers/utils";

export const Flows = () => {
  let currentPage = useAppSelector(selectCurrentPageSmartFlow);
  let numPages = useAppSelector(selectNumPagesSmartFlow);
  let loading = useAppSelector(selectLoadingFlowStatus);
  let flows = useAppSelector(selectFlowFiltered);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  const getFlowStep = (
    step: "CORE_ID" | "MAGIC_FORMS" | "ELECTRONIC_SIGNATURE" | "DONE" | string
  ) => {
    switch (step) {
      case "CORE_ID":
        return "Core ID";
      case "MAGIC_FORMS":
        return "Magic Forms";
      case "ELECTRONIC_SIGNATURE":
        return "Firma Electrónica";
      case "DONE":
        return "Finalizado";
      default:
        return "No Empezado";
    }
  };

  const getEstado = (
    status: "IN_PROGRESS" | "PENDING" | "APPROVED" | "REJECTED" | string
  ) => {
    switch (status) {
      case "IN_PROGRESS":
        return "En Proceso";
      case "PENDING":
        return "Pendiente";
      case "APPROVED":
        return "Aprobado";
      case "REJECTED":
        return "Rechazado";
      default:
        return "No Empezado";
    }
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Smart Flow"));
    dispatch(
      getFilteredFlow({
        ...currentFilteredRequest,
      })
    );
    dispatch(resetFlow());
    dispatch(setIsFlowDetail(false));
    return () => {};
  }, [currentFilteredRequest]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {flows && flows.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "13%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Flujos Realizados
            </div>
          </div>

          {flows ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Fecha y Hora</th>
                      <th>E-mail</th>
                      <th>Estado</th>
                      <th>Paso</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td> <td></td>
                    </tr>
                    {flows.map((flow, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/services/smartFlow/flows/${flow.id}`);
                          }}
                        >
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {flow.registration ? (
                              <div>{`${flow.registration.name || ""} ${
                                flow.registration.lastName || ""
                              }`}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{formatDateStandar(flow.startDateTime)}</td>
                          <td>
                            {flow.registration ? (
                              <div>{flow.registration.email || ""}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getEstado(flow.status)}
                          </td>
                          <td>{getFlowStep(flow.step)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay más registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{
                marginRight: "100px",
                fontSize: "13px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {"<"}
              </button>
              Página {currentPage + 1} de {numPages}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle"
              ? ""
              : "No hay más flujos en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
