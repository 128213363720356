import axiosApiInstance from "../../../helpers/interceptor";
import { UnifiedAlertsFiltered } from "../alerts/alertsAPI";
import { RegistrationDetail } from "../coreid/coreIdAPI";
import { GetSignatureResponse } from "../firmanew/firmaNewAPI";
import { FilledForm } from "../magicforms/magicFormsAPI";

let getFiltered = "hyperflow/getFiltered";
let getRevisionMode = "hyperflow/revisionModeFiltered";
let getFilterOptions = "hyperflow/filterOptions";
let getDashboardDataMethod = "hyperflow/stats";
let getFlowProcess = (flowId: string) => `hyperflow/execFlow/${flowId}`;
let changeStatus = (flowId: string) => `hyperflow/execFlow/${flowId}/updateStep`;
let retryStep = (flowId: string) => `hyperflow/execFlow/${flowId}/manualRetry`;

let getMiniDashboardDataAPI = async (): Promise<{
  miniDasboardData: GetStatsHyperFlowResponse | null;
  error: string;
}> => {
  let dashboardData = await axiosApiInstance.get<GetStatsHyperFlowResponse>(
    `/${getDashboardDataMethod}`
  );
  if (dashboardData.status !== 200) {
    return {
      miniDasboardData: null,
      error: `Se generó un código ${dashboardData.status}`,
    };
  } else {
    return { miniDasboardData: dashboardData.data, error: "" };
  }
};


let getFilteredFlowAPI = async (
  params: GetFilteredHyperFlowRequest
): Promise<{ flows: FlowFiltered | null; error: string }> => {
 
    try {
    let registrationsResponse = await axiosApiInstance.post(
      `/${getFiltered}`,
      params
    );
    return {
      flows: registrationsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flows: null,
    };
  }
};

let getRevisionFlowAPI = async (
): Promise<{ flows: FlowFiltered | null; error: string }> => {
  let   params: GetFilteredHyperFlowRequest= {
    "startDate": null,
    "endDate": null,
    "hyperFlowId":null,
    "searchQuery": null,
    "status": null,
    "type": null,
    "page": 0
  
  }

  try {
    let registrationsResponse = await axiosApiInstance.post(
      `/${getRevisionMode}`,
      params
    );
    return {
      flows: registrationsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flows: null,
    };
  }
};

let getFilterOptionsFlowAPI = async (): Promise<{
  filterOptions: FlowFilteredOptions | null;
  error: string;
}> => {
  try {
    let filterOptionsResponse = await axiosApiInstance.get(
      `/${getFilterOptions}`
    );

    return {
      filterOptions: filterOptionsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      filterOptions: null,
    };
  }
};

let getFlowByIdAPI = async (
  flowId: string
): Promise<{ flow: hyperFlow | null; error: string }> => {
  try {
    let flowResponse = await axiosApiInstance.get(`/${getFlowProcess(flowId)}`);
    return {
      flow: flowResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flow: null,
    };
  }
};

let getRetryStep = async (
  flowId: string
): Promise<{ error: string }> => {
  try {
    let flowResponse = await axiosApiInstance.get(`/${retryStep(flowId)}`);
    return {
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
    };
  }
};



let changeFlowStatusAPI = async (
  newStatus: string,
  flowId: string,
  stepId: string,
  comment: string
): Promise<{ flow: string | null; error: string }> => {
  try {
    let updatedFlow = await axiosApiInstance.post(`/${changeStatus(flowId)}`, {
      "stepId": stepId,
      "status": newStatus,
      "comment": comment
    });
    return {
      flow: updatedFlow.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      flow: null,
    };
  }
};

export interface GetFilteredHyperFlowRequest {
  searchQuery: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  hyperFlowId: string | null;
  page: number;
  type: string | null;
}

export interface GetStatsHyperFlowResponse {
  total: number;
  rejected: number;
  accepted: number;
  pending: number;
  new: number;
}

export interface FlowFiltered {
  flows: hyperFlow[];
  numPages: number;
  page?: number;
}

export interface ExecutedStep {
id	:	string;
status:	("DONE"| "FAILED_AUTO_RETRY"| "FAILED_MANUAL_RETRY"| "IN_PROGRESS"| "PENDING"| "PENDING_MANUAL_REVISION");
errorData	:	string;
customName	:	string;
entityId	:	string;
stepDescriptionId	:	string;
resultData:	string[];
nextStepId	:	string;
detailCore?: RegistrationDetail | null;
detailMagic?: FilledForm | null;
detailSig?:  GetSignatureResponse|  null;

}

export interface hyperFlow {
clientId: string;
hyperFlowId:	string;
id:	string;
executedSteps?:	{ [key: string]: ExecutedStep };
stateId:	string;
userId:	string;
flowStatus : ( "AWAITING_MANUAL_INTERACTION"| "DONE"| "IN_PROGRESS" );
metadata: { [key: string]: string };
currentAssignees: string[];
currentStepId	:	string;
registrationAlerts?: UnifiedAlertsFiltered | null;
registration?: RegistrationDetail | null;

}

export interface FlowFilteredOptions {
  hyperFlows: { [key: string]: string };
}
export {
  getFilteredFlowAPI,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  changeFlowStatusAPI,
  getMiniDashboardDataAPI,
  getRevisionFlowAPI,
  getRetryStep
};
