import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

import {
  getUnifiedAlertsFilteredAPI,
  UnifiedAlertsFiltered,
  UnifiedAlertsFilteredRequest,
} from "./alertsAPI";

export type AlertSeverity = "none" | "medium" | "high";

export interface AlertsState {
  loading: boolean;
  unifiedAlertsFiltered: UnifiedAlertsFiltered | null;
  currentAlertsRequest: UnifiedAlertsFilteredRequest;
  currentPage: number;
  numPages: number;
  notFoundMessage: string;
  categoryAlerts: {
    compliance: AlertSeverity;
    dates: AlertSeverity;
    incongruences: AlertSeverity;
    attachments: AlertSeverity;
    authenticity: AlertSeverity;
  };
}

const initialState: AlertsState = {
  loading: false,
  currentAlertsRequest: { page: 0, service: "", type: "", endDate: null, startDate: null  },
  currentPage: 0,
  notFoundMessage: "",
  numPages: 0,
  unifiedAlertsFiltered: null,
  categoryAlerts: {
    attachments: "none",
    authenticity: "none",
    compliance: "none",
    dates: "none",
    incongruences: "none",
  },
};

export const getUnifiedAlerts = createAsyncThunk(
  "alerts/getUnifiedAlerts",
  async (params: UnifiedAlertsFilteredRequest, { rejectWithValue }) => {
    try {
      const alerts = await getUnifiedAlertsFilteredAPI(params);
      if (alerts.alerts) {
        return alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const alertsSlice = createSlice({
  name: "alertsSlice",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<UnifiedAlertsFilteredRequest>
    ) => {
      state.currentAlertsRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentAlertsRequest;
      state.currentPage = action.payload;
      state.currentAlertsRequest = { ...current, page: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnifiedAlerts.fulfilled, (state, action) => {
        let alertsResponse = action.payload;
        if (alertsResponse && alertsResponse.alerts) {
          state.unifiedAlertsFiltered = alertsResponse.alerts;
          let categoryAlertsTemp: {
            compliance: AlertSeverity;
            dates: AlertSeverity;
            incongruences: AlertSeverity;
            attachments: AlertSeverity;
            authenticity: AlertSeverity;
          } = {
            attachments: "none",
            authenticity: "none",
            compliance: "none",
            dates: "none",
            incongruences: "none",
          };
          for (const alert of alertsResponse.alerts.alerts) {
            if (alert.type === "COMPLIANCE") {
              categoryAlertsTemp.compliance = "high";
            }
            if (alert.type === "DATES") {
              categoryAlertsTemp.dates = "high";
            }
            if (alert.type === "AUTHENTITICY") {
              categoryAlertsTemp.authenticity = "high";
            }
            if (alert.type === "INCONGRUENCES") {
              categoryAlertsTemp.incongruences = "high";
            }
            if (alert.type === "ATTACHMENTS") {
              categoryAlertsTemp.attachments = "high";
            }
          }

          state.categoryAlerts = categoryAlertsTemp;
          state.numPages = alertsResponse.alerts.numPages;
        }
        state.loading = false;
      })
      .addCase(getUnifiedAlerts.rejected, (state) => {
        state.loading = false;
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getUnifiedAlerts.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { changeCurrentFilters, changePageState } = alertsSlice.actions;

export const selectCurrentAlertsRequest = (state: RootState) =>
  state.alerts.currentAlertsRequest;
export const selectUnifiedAlerts = (state: RootState) =>
  state.alerts.unifiedAlertsFiltered;
export const selectAlertsLoading = (state: RootState) => state.alerts.loading;
export const selectNotFoundMessageAlerts = (state: RootState) =>
  state.alerts.notFoundMessage;
export const selectCurrentPageAlerts = (state: RootState) =>
  state.alerts.currentPage;
export const selectNumPagesAlerts = (state: RootState) => state.alerts.numPages;
export const selectAlertsSeverity = (state: RootState) =>
  state.alerts.categoryAlerts;
export default alertsSlice.reducer;
