import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  getAlertsByItemIdAPI,
  getUnifiedAlertsFilteredAPI,
  UnifiedAlertsFiltered,
  UnifiedAlertsFilteredRequest,
} from "../alerts/alertsAPI";
import { AlertSeverity } from "../alerts/alertsSlice";
import {
  DataManualInfo,
  getManualInfoAPI,
  PersonaResponse,
} from "../consultaManual/consultaManualAPI";
import {
  AttatchmentData,
  getAttatchmentAPI,
  getUserAttatchmentsAPI,
} from "../magicforms/magicFormsAPI";
import {
  changeStatusCoreIdAPI,
  CoreIdFilterOptions,
  FilteredRegistrationRequest,
  getFilteredRegistrationsAPI,
  getFilterOptionsAPI,
  getImagesRegistrationAPI,
  getMiniDashboardDataAPI,
  getProjectsAPI,
  getRegistrationByIdAPI,
  MiniDashboardData,
  Project,
  RegistrationDetail,
  RegistrationsFiltered,
  updateProjectAPI,
} from "./coreIdAPI";

export type CoreIdActiveComponent =
  | "Registration"
  | "GraphicMonitoring"
  | "Flow"
  | "Alerts"
  | "Manual"
  | "RegistrationDetail"
  | "none";

export interface AttatchmentDataRequest {
  filledFormId: string;
  stitchTypeId: string;
}

export interface CoreIdState {
  loadingStatus: LoadingStatus;
  loadingImagesStatus: LoadingStatus;
  loadingAttatchmentsStatus: LoadingStatus;
  downloadingAttatchmentStatus: LoadingStatus;
  loadingProjects: LoadingStatus;
  loadingAlertsStatus: LoadingStatus;
  loadingRegistrationAlerts: LoadingStatus;
  manualConsultLoading: LoadingStatus;
  miniDashboarData: MiniDashboardData | null;
  registrationsFiltered: RegistrationsFiltered | null;
  currentFilteredRequest: FilteredRegistrationRequest;
  currentRegistration: RegistrationDetail | null;
  currentPage: number;
  numPages: number;
  activeComponent: CoreIdActiveComponent;
  projects: Array<Project>;
  currentProject: Project | null;
  filterOptions: CoreIdFilterOptions | null;
  currentAttatchment: AttatchmentData;
  alerts: UnifiedAlertsFiltered | null;
  registrationAlerts: UnifiedAlertsFiltered | null;
  currentAlertsRequest: UnifiedAlertsFilteredRequest;
  categoryAlerts: {
    compliance: AlertSeverity;
    dates: AlertSeverity;
    incongruences: AlertSeverity;
    attachments: AlertSeverity;
    authenticity: AlertSeverity;
  };
  manualResult: PersonaResponse | null;
}

const initialState: CoreIdState = {
  loadingStatus: "idle",
  loadingImagesStatus: "idle",
  downloadingAttatchmentStatus: "idle",
  loadingAttatchmentsStatus: "idle",
  loadingAlertsStatus: "idle",
  loadingProjects: "idle",
  loadingRegistrationAlerts: "idle",
  manualConsultLoading: "idle",
  activeComponent: "none",
  currentPage: 0,
  numPages: 0,
  miniDashboarData: {
    fraudRate: 0,
    newRegs: 0,
    regsFailed: 0,
    regsSuccess: 0,
    totalRegs: 0,
  },

  registrationsFiltered: null,
  projects: [],
  currentProject: null,
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    projectId: null,
    searchQuery: null,
    startDate: null,
    result: null,
  },
  filterOptions: null,
  currentRegistration: null,
  currentAttatchment: { fileBase64: "", fileName: "" },
  alerts: null,
  currentAlertsRequest: {
    page: 0,
    service: "coreId",
    type: null,
    startDate: null,
    endDate: null,
  },
  categoryAlerts: {
    attachments: "none",
    authenticity: "none",
    compliance: "none",
    dates: "none",
    incongruences: "none",
  },
  registrationAlerts: null,
  manualResult: null,
};

export const getFilteredRegistrations = createAsyncThunk(
  "coreId/getFilteredRegistrations",
  async (params: FilteredRegistrationRequest, { rejectWithValue }) => {
    try {
      const filteredRegistrations = await getFilteredRegistrationsAPI(params);
      if (filteredRegistrations.registrations) {
        return filteredRegistrations;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAttatchmentData = createAsyncThunk(
  "coreId/getAttatchmentData",
  async (
    AttatchmentDataParams: AttatchmentDataRequest,
    { rejectWithValue }
  ) => {
    try {
      const Attatchment = await getAttatchmentAPI(
        AttatchmentDataParams.filledFormId,
        AttatchmentDataParams.stitchTypeId
      );
      if (Attatchment) {
        return Attatchment;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFilterOptions = createAsyncThunk(
  "coreId/getFilterOptions",
  async (_, { rejectWithValue }) => {
    try {
      const filteredOptions = await getFilterOptionsAPI();
      if (filteredOptions.filterOptions) {
        return filteredOptions;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDashboardItems = createAsyncThunk(
  "coreId/getDashboardItems",
  async (_, { rejectWithValue }) => {
    try {
      const dashboardItems = await getMiniDashboardDataAPI();
      if (dashboardItems.miniDasboardData) {
        return dashboardItems.miniDasboardData;
      } else {
        rejectWithValue(dashboardItems.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getRegistrationById = createAsyncThunk(
  "coreId/getRegistrationById",
  async (id: string, { rejectWithValue }) => {
    try {
      const registration = await getRegistrationByIdAPI(id);
      return registration.registration;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getImagesRegistration = createAsyncThunk(
  "coreId/getImages/RegistraionById",
  async (id: string, { rejectWithValue }) => {
    try {
      const images = await getImagesRegistrationAPI(id);

      return images.pictures;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAttatchments = createAsyncThunk(
  "coreId/getAttatchments",
  async (id: string, { rejectWithValue }) => {
    try {
      const attatchments = await getUserAttatchmentsAPI(id);

      return attatchments;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFlowItems = createAsyncThunk(
  "coreId/getFlowItems",
  async (_, { rejectWithValue }) => {
    try {
      const projects = await getProjectsAPI();
      if (projects.projects) {
        return projects;
      } else {
        rejectWithValue(projects.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getCoreIdAlerts = createAsyncThunk(
  "coreId/getCoreIdAlerts",
  async (params: UnifiedAlertsFilteredRequest, { rejectWithValue }) => {
    try {
      const alerts = await getUnifiedAlertsFilteredAPI(params);
      if (alerts.alerts) {
        return alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateProject = createAsyncThunk(
  "coreId/updateProject",
  async (project: Project, { rejectWithValue }) => {
    try {
      const projectUpdated = await updateProjectAPI(project);
      if (projectUpdated.project) {
        return projectUpdated;
      } else {
        rejectWithValue(projectUpdated.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAlertsByRegistrationId = createAsyncThunk(
  "coreId/getAlertsByRegistrationId",
  async (regId: string, { rejectWithValue }) => {
    try {
      const alerts = await getAlertsByItemIdAPI(regId);
      if (alerts.alerts) {
        return alerts.alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getManualInfo = createAsyncThunk(
  "coreId/getManualInfo",
  async (info: DataManualInfo, { rejectWithValue }) => {
    try {
      let conductorInfo = await getManualInfoAPI(info);
      if (conductorInfo) {
        return conductorInfo;
      } else {
        return rejectWithValue("Error");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "coreId/changeStatus",
  async (
    params: { registrationId: string; newStatus: string },
    { rejectWithValue }
  ) => {
    try {
      let changed = await changeStatusCoreIdAPI(
        params.registrationId,
        params.newStatus
      );
      return changed;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const coreIdSlice = createSlice({
  name: "coreIdSlice",
  initialState,
  reducers: {
    resetRegistration: (state) => {
      state.currentRegistration = null;
      state.loadingAttatchmentsStatus = "idle";
      state.loadingImagesStatus = "idle";
      state.currentAttatchment = { fileBase64: "", fileName: "" };
    },
    changeActiveComponent: (
      state,
      action: PayloadAction<CoreIdActiveComponent>
    ) => {
      state.activeComponent = action.payload;
      state.currentPage = 0;
      state.numPages = 0;
    },
    changeCurrentProject: (state, action: PayloadAction<string>) => {
      let projects = state.projects;
      for (const project of projects) {
        if (project.id === action.payload) {
          state.currentProject = project;
        }
      }
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<FilteredRegistrationRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changeCurrentAlertFilters: (
      state,
      action: PayloadAction<UnifiedAlertsFilteredRequest>
    ) => {
      state.currentAlertsRequest = {
        ...action.payload,
        page: 0,
        service: "coreId",
      };
    },

    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    changeAlertPageState: (state, action: PayloadAction<number>) => {
      let current = state.currentAlertsRequest;
      state.currentPage = action.payload;
      state.currentAlertsRequest = { ...current, page: action.payload };
    },
    emptyCurrentAttatchment: (state) => {
      state.currentAttatchment = { fileBase64: "", fileName: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredRegistrations.fulfilled, (state, action) => {
        let registrations = action.payload;

        if (registrations.registrations) {
          state.registrationsFiltered = registrations.registrations;
          state.currentPage = registrations.registrations.currentPage;
          state.numPages = registrations.registrations.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredRegistrations.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getFilteredRegistrations.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getDashboardItems.fulfilled, (state, action) => {
        let dashboardItems = action.payload;
        if (dashboardItems) {
          state.miniDashboarData = dashboardItems;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getDashboardItems.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(getDashboardItems.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFlowItems.fulfilled, (state, action) => {
        let projects = action.payload;
        if (projects && projects.projects) {
          state.projects = projects.projects;
          state.loadingProjects = "resolved";
        } else {
          state.loadingProjects = "rejected";
        }
      })
      .addCase(getFlowItems.rejected, (state) => {
        state.loadingProjects = "rejected";
      })
      .addCase(getFlowItems.pending, (state) => {
        state.loadingProjects = "pending";
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filterOptions = filterOptions.filterOptions;
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getRegistrationById.fulfilled, (state, action) => {
        let registration = action.payload;
        if (registration) {
          state.currentRegistration = registration;
          state.loadingStatus = "resolved";
          state.loadingImagesStatus = "idle";
          state.loadingAttatchmentsStatus = "idle";
          state.downloadingAttatchmentStatus = "idle";
        } else {
          state.loadingStatus = "rejected";
          state.loadingImagesStatus = "rejected";
        }
      })
      .addCase(changeStatus.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(changeStatus.fulfilled, (state) => {
        state.currentRegistration = null;
      })
      .addCase(changeStatus.rejected, (state) => {
        state.currentRegistration = null;
      })
      .addCase(getRegistrationById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.loadingImagesStatus = "rejected";
        state.loadingAttatchmentsStatus = "rejected";
        state.downloadingAttatchmentStatus = "rejected";
      })
      .addCase(getRegistrationById.pending, (state) => {
        state.loadingImagesStatus = "pending";
        state.loadingStatus = "pending";
        state.loadingAttatchmentsStatus = "pending";
        state.downloadingAttatchmentStatus = "pending";
      })
      .addCase(getImagesRegistration.fulfilled, (state, action) => {
        let images = action.payload;
        let registration = state.currentRegistration;
        if (images && registration) {
          registration.pictures = images;
          state.currentRegistration = registration;
          state.loadingImagesStatus = "resolved";
        } else {
          state.loadingImagesStatus = "rejected";
        }
      })
      .addCase(getImagesRegistration.rejected, (state) => {
        state.loadingImagesStatus = "rejected";
      })
      .addCase(getImagesRegistration.pending, (state) => {
        state.loadingImagesStatus = "pending";
      })
      .addCase(getAttatchments.fulfilled, (state, action) => {
        let attatchments = action.payload;
        let registration = state.currentRegistration;
        if (attatchments && registration) {
          registration.attatchments = attatchments;
          state.currentRegistration = registration;
          state.loadingAttatchmentsStatus = "resolved";
        } else {
          state.loadingAttatchmentsStatus = "rejected";
        }
      })
      .addCase(getAttatchments.rejected, (state) => {
        state.loadingAttatchmentsStatus = "rejected";
      })
      .addCase(getAttatchments.pending, (state) => {
        state.loadingAttatchmentsStatus = "pending";
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        let updatedProject = action.payload;
        if (updatedProject && updatedProject.project) {
          state.currentProject = updatedProject.project;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(updateProject.rejected, (state) => {
        state.loadingStatus = "rejected";
      })
      .addCase(updateProject.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getAttatchmentData.fulfilled, (state, action) => {
        let attatchment = action.payload;
        if (attatchment) {
          state.currentAttatchment = attatchment;
          state.downloadingAttatchmentStatus = "resolved";
        } else {
          state.downloadingAttatchmentStatus = "rejected";
        }
      })
      .addCase(getAttatchmentData.rejected, (state) => {
        state.downloadingAttatchmentStatus = "rejected";
      })
      .addCase(getAttatchmentData.pending, (state) => {
        state.downloadingAttatchmentStatus = "pending";
      })
      .addCase(getCoreIdAlerts.fulfilled, (state, action) => {
        let alerts = action.payload;
        if (alerts && alerts.alerts) {
          state.alerts = alerts.alerts;
          let categoryAlertsTemp: {
            compliance: AlertSeverity;
            dates: AlertSeverity;
            incongruences: AlertSeverity;
            attachments: AlertSeverity;
            authenticity: AlertSeverity;
          } = {
            attachments: "none",
            authenticity: "none",
            compliance: "none",
            dates: "none",
            incongruences: "none",
          };
          for (const alert of alerts.alerts.alerts) {
            if (alert.type === "COMPLIANCE") {
              categoryAlertsTemp.compliance = "high";
            }
            if (alert.type === "DATES") {
              categoryAlertsTemp.dates == "high";
            }
            if (alert.type === "AUTHENTICITY") {
              categoryAlertsTemp.authenticity = "high";
            }
            if (alert.type === "INCONGRUENCES") {
              categoryAlertsTemp.incongruences = "high";
            }
            if (alert.type === "ATTACHMENTS") {
              categoryAlertsTemp.attachments = "high";
            }
          }
          state.categoryAlerts = categoryAlertsTemp;
          state.numPages = alerts.alerts.numPages;

          state.loadingAlertsStatus = "resolved";
        } else {
          state.loadingAlertsStatus = "rejected";
        }
      })
      .addCase(getCoreIdAlerts.rejected, (state) => {
        state.loadingAlertsStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getCoreIdAlerts.pending, (state) => {
        state.loadingAlertsStatus = "pending";
      })
      .addCase(getAlertsByRegistrationId.fulfilled, (state, action) => {
        let alerts = action.payload;
        if (alerts) {
          state.registrationAlerts = alerts;
          state.loadingRegistrationAlerts = "resolved";
        } else {
          state.loadingRegistrationAlerts = "rejected";
        }
      })
      .addCase(getAlertsByRegistrationId.rejected, (state) => {
        state.loadingRegistrationAlerts = "rejected";
      })
      .addCase(getAlertsByRegistrationId.pending, (state) => {
        state.loadingRegistrationAlerts = "pending";
      })
      .addCase(getManualInfo.fulfilled, (state, action) => {
        state.manualResult = action.payload;
        state.manualConsultLoading = "resolved";
      })
      .addCase(getManualInfo.pending, (state) => {
        state.manualConsultLoading = "pending";
      })
      .addCase(getManualInfo.rejected, (state) => {
        state.manualResult = null;
        state.manualConsultLoading = "rejected";
      });
  },
});

export const {
  changeActiveComponent,
  changeCurrentProject,
  changeCurrentFilters,
  changePageState,
  emptyCurrentAttatchment,
  changeAlertPageState,
  changeCurrentAlertFilters,
  resetRegistration,
} = coreIdSlice.actions;

export const selectCurrentPageCoreId = (state: RootState) =>
  state.coreId.currentPage;
export const selectNumPagesCoreId = (state: RootState) => state.coreId.numPages;
export const selectActiveCoreIdComponent = (state: RootState) =>
  state.coreId.activeComponent;
export const selectCoreIdLoading = (state: RootState) =>
  state.coreId.loadingStatus;
export const selectRegistrationImagesLoading = (state: RootState) =>
  state.coreId.loadingImagesStatus;
export const selectMiniDashboardData = (state: RootState) =>
  state.coreId.miniDashboarData;
export const selectFilteredRegistrations = (state: RootState) =>
  state.coreId.registrationsFiltered;
export const selectCoreIdProjects = (state: RootState) => state.coreId.projects;
export const selectCoreIdCurrentProject = (state: RootState) =>
  state.coreId.currentProject;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.coreId.currentFilteredRequest;
export const selectFilterOptions = (state: RootState) =>
  state.coreId.filterOptions;
export const selectCurrentRegistration = (state: RootState) =>
  state.coreId.currentRegistration;
export const selectCurrentAttatchment = (state: RootState) =>
  state.coreId.currentAttatchment;
export const selectAttatchmentsLoadingStatus = (state: RootState) =>
  state.coreId.loadingAttatchmentsStatus;
export const selectAttatchmentDownloadingStatus = (state: RootState) =>
  state.coreId.downloadingAttatchmentStatus;
export const selectAlertsLoadingStatus = (state: RootState) =>
  state.coreId.loadingAlertsStatus;
export const selectFilteredCoreIdAlerts = (state: RootState) =>
  state.coreId.alerts;
export const selectCurrentCoreIdAlertsRequest = (state: RootState) =>
  state.coreId.currentAlertsRequest;
export const selectCoreIdAlertsSeverity = (state: RootState) =>
  state.coreId.categoryAlerts;
export const selectProjectsLoading = (state: RootState) =>
  state.coreId.loadingProjects;
export const selectRegistrationAlerts = (state: RootState) =>
  state.coreId.registrationAlerts;
export const selectRegistrationAlertsLoading = (state: RootState) =>
  state.coreId.loadingRegistrationAlerts;
export const selectCurrentManual = (state: RootState) =>
  state.coreId.manualResult;
export const selectManualLoading = (state: RootState) =>
  state.coreId.manualConsultLoading;

export default coreIdSlice.reducer;
