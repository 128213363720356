import { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as HighAlert } from "../../../Assets/alerts/highLevelAlerts.svg";

import {
  changeCurrentFilters,
  changePageState,
  selectCurrentAlertsRequest,
  selectUnifiedAlerts,
  selectAlertsLoading,
  selectCurrentPageAlerts,
  selectNumPagesAlerts,
  getUnifiedAlerts,
  AlertSeverity,
  selectAlertsSeverity,
} from "./alertsSlice";
import { formatDateStandar } from "../../../helpers/utils";
import { AlertDashboardElement } from "./components/AlertDashboardElement";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { TopPanel } from "../components/TopPanel";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { UnifiedAlerts as Alert } from "./alertsAPI";
import { changeCurrentProduct } from "../../common/commonSlice";

export const UnifiedAlerts = () => {
  let loading = useAppSelector(selectAlertsLoading);
  let unifiedAlerts = useAppSelector(selectUnifiedAlerts);
  let currentPage = useAppSelector(selectCurrentPageAlerts);
  let numPages = useAppSelector(selectNumPagesAlerts);
  let currentAlertsRequest = useAppSelector(selectCurrentAlertsRequest);
  let alertsSeverity = useAppSelector(selectAlertsSeverity);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  let alertNavigate = (alert: Alert) => {
    switch (alert.service) {
      case "coreId":
        return `/services/coreId/registrations/${currentAlert.itemId}`;
      case "magicForms":
        return `/services/magicForms/filledForms/${currentAlert.itemId}`;
      case "electronicSignature":
        return `/services/electronicSignature/signatures/${currentAlert.itemId}`;
      default:
        return "/services/alerts";
    }
  };

  let getColorDot = (levelAlert: AlertSeverity) => {
    switch (levelAlert) {
      case "high":
        return <HighAlert />;
      case "medium":
        return <HighAlert />;
      case "none":
        return <div></div>;
      default:
        return <div></div>;
    }
  };

  let getCategoryName = (category: string) => {
    switch (category) {
      case "COMPLIANCE":
        return "COMPLIANCE";
      case "INCONGRUENCES":
        return "INCONGRUENCIAS";
      case "DATES":
        return "FECHAS";
      case "ATTACHMENTS":
        return "ADJUNTOS";
      case "AUTHENTICITY":
        return "AUTENTICIDAD";
      default:
        return "";
    }
  };

  const [currentAlert, setCurrentAlert] = useState<Alert>({
    clientId: "",
    dateTime: "",
    description: "",
    id: "",
    itemId: "",
    resolved: false,
    service: "",
    type: "",
    userId: "",
    details: [],
  });

  let getFilters = () => {
    return (
      <FilterComponent
      key={0}
      hasCalendar={true}
      endDate={
        currentAlertsRequest.endDate
          ? new Date(currentAlertsRequest.endDate)
          : null
      }
      startDate={
        currentAlertsRequest.startDate
          ? new Date(currentAlertsRequest.startDate)
          : null
      }
      setEndDate={(date: Date) => {
        if (date) {
          if (date) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                endDate: date.toISOString(),
              })
            );
          }
        }
      }}
      setStartDate={(date: Date) => {
        if (date) {
          if (date) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                startDate: date.toISOString(),
              })
            );
          }
        }
      }}
      resetFunction={() => {
        dispatch(
          changeCurrentFilters({
            ...currentAlertsRequest,
            endDate: null,
            startDate: null,
          })
        );
      }}
        items={[
          <div
            key={0}
            className="text-normal text-small primary-color"
            style={{
              alignSelf: "start",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            Por categoría
          </div>,
          <div key={1} style={{ alignSelf: "center" }}>
            <RadioButton
              label="Compliance"
              checked={currentAlertsRequest.type === "COMPLIANCE"}
              onClick={() => {
                if (currentAlertsRequest.type === "COMPLIANCE") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentAlertsRequest,
                      type: null,
                    })
                  );
                }
              }}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentAlertsRequest,
                    type: "COMPLIANCE",
                  })
                );
              }}
            />
            <RadioButton
              label="Fechas"
              checked={currentAlertsRequest.type === "DATES"}
              onClick={() => {
                if (currentAlertsRequest.type === "DATES") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentAlertsRequest,
                      type: null,
                    })
                  );
                }
              }}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentAlertsRequest,
                    type: "DATES",
                  })
                );
              }}
            />
            <RadioButton
              label="Incongruencias"
              onClick={() => {
                if (currentAlertsRequest.type === "INCONGRUENCES") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentAlertsRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentAlertsRequest.type === "INCONGRUENCES"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentAlertsRequest,
                    type: "INCONGRUENCES",
                  })
                );
              }}
            />
            <RadioButton
              label="Adjuntos"
              onClick={() => {
                if (currentAlertsRequest.type === "ATTACHMENTS") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentAlertsRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentAlertsRequest.type === "ATTACHMENTS"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentAlertsRequest,
                    type: "ATTACHMENTS",
                  })
                );
              }}
            />
            <RadioButton
              label="Autenticidad"
              onClick={() => {
                if (currentAlertsRequest.type === "AUTHENTICITY") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentAlertsRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentAlertsRequest.type === "AUTHENTICITY"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentAlertsRequest,
                    type: "AUTHENTICITY",
                  })
                );
              }}
            />
          </div>,
        ]}
      />
    );
  };

  useEffect(() => {
    dispatch(getUnifiedAlerts({ ...currentAlertsRequest }));
    dispatch(changeCurrentProduct("Alertas"));
  }, [currentPage, currentAlertsRequest]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel filter={getFilters()} />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <LoadingOverlay
            active={loading}
            spinner={<ClipLoader color="white" size="150px" />}
          >
            {unifiedAlerts && unifiedAlerts.alerts.length > 0 ? (
              <div
                className="flex-col"
                style={{ width: "100%", height: "100%" }}
              >
                <div
                  className="flex-row"
                  style={{
                    height: "12%",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="flex-row"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <LeftArrow style={{ marginRight: "15px" }} />
                    <div className="text-bold text-xx-large primary-color">
                      Selecciona una alerta
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    height: "15%",
                    justifyContent: "center",
                    alignItems: "start",
                    marginBottom: "1%",
                  }}
                >
                  <AlertDashboardElement
                    dot={getColorDot(alertsSeverity.compliance)}
                    title="Compliance"
                    subtitle="Revisa el estado del cumplimiento normativo"
                  />
                  <AlertDashboardElement
                    dot={getColorDot(alertsSeverity.dates)}
                    title="Fechas"
                    subtitle="Revisa si se vence una fecha"
                  />
                  <AlertDashboardElement
                    dot={getColorDot(alertsSeverity.incongruences)}
                    title="Incongruencias"
                    subtitle="Revisa si hay incongruencias"
                  />
                  <AlertDashboardElement
                    dot={getColorDot(alertsSeverity.attachments)}
                    title="adjuntos"
                    subtitle="Revisa si hay problemas con adjuntos"
                  />
                  <AlertDashboardElement
                    dot={getColorDot(alertsSeverity.authenticity)}
                    title="autenticidad"
                    subtitle="Revisa si falló una prueba de autenticidads"
                    last
                  />
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "start",
                    height: "40%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{ width: "60%", overflow: "auto", height: "90%" }}
                  >
                    <table style={{ borderCollapse: "collapse" }}>
                      <thead
                        className="text-normal text-medium primary-color-35"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "30px",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <th></th>
                          <th>Servicio</th>
                          <th>Fecha y Hora</th>
                          <th>Categoría</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-normal text-x-small primary-color"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {unifiedAlerts?.alerts.map((alert, i) => {
                          return (
                            <tr
                              key={i}
                              className="list-hover"
                              style={{ height: "30px", cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentAlert(alert);
                              }}
                            >
                              <td></td>
                              <td style={{ textTransform: "uppercase" }}>
                                {alert.service}
                              </td>
                              <td>{formatDateStandar(alert.dateTime)}</td>
                              <td style={{ textTransform: "uppercase" }}>
                                {getCategoryName(alert.type)}
                              </td>
                              <td></td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "2%",
                  }}
                >
                  <div
                    className="flex-row text-bold primary-color-40"
                    style={{ fontSize: "13px" }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changePage("before");
                      }}
                      style={{
                        width: "30px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "15px",
                        height: "30px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {"<"}
                    </div>
                    Página {currentPage + 1} de {numPages}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changePage("after");
                      }}
                      style={{
                        width: "30px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "15px",
                        height: "30px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {">"}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    marginTop: "2%",
                    height: "20%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{ width: "60%", alignItems: "center" }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        alignItems: "start",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                      }}
                    >
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          marginRight: "3%",
                        }}
                      >
                        <div className="text-normal text-small primary-color-40">
                          Descripción de la alerta
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "rgba(0,0,0,0.2)",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            paddingBottom: "6px",
                            borderRadius: "8px",
                            paddingLeft: "5%",
                            paddingTop: "2%",
                            paddingRight: "2%",
                          }}
                        >
                          {currentAlert.id !== "" ? (<div
                            className="text-normal primary-color "
                            style={{ fontSize: "13px", height: "100%" }}
                          >
                            {currentAlert.description}
                          </div>): (<div
                            className="text-normal primary-color-40 "
                            style={{ fontSize: "13px", height: "100%", paddingTop: "5%", textAlign: "center" }}
                          >
SELECCIONE UNA ALERTA PARA VER SU DESCRIPCIÓN                          
</div>)}
                        </div>
                      </div>
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          overflow: "auto",
                        }}
                      >
                        <div className="text-normal text-x-small primary-color-40">
                          Detalle de la alerta
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "rgba(0,0,0,0.2)",
                            justifyContent: "space-evenly",
                            alignItems: "start",
                            boxSizing: "border-box",

                            paddingBottom: "6px",
                            borderRadius: "8px",
                            overflow: "auto",
                            paddingLeft: "5%",
                            paddingTop: "2%",
                            paddingRight: "2%",
                          }}
                        >
                          {currentAlert.id !== "" ? (currentAlert.details.map((detail, i) => {
                            return (
                              <div
                                key={i}
                                className="flex-col"
                                style={{
                                  marginBottom: "5px",
                                }}
                              >
                                <div
                                  className="flex-col text-normal text-x-small"
                                  style={{ color: "#C7FF4F" }}
                                >
                                  {detail.title}
                                </div>
                                <div
                                  className="flex-col text-normal text-x-small primary-color"
                                  style={{}}
                                >
                                  {detail.description}
                                </div>
                              </div>
                            );
                          })):(<div
                            className="text-normal primary-color-40 "
                            style={{ fontSize: "13px", height: "100%", paddingTop: "5%", textAlign: "center" }}
                          >
SELECCIONE UNA ALERTA PARA VER EL DETALLE
</div>)}
                        </div>
                      </div>
                    </div>
                    {currentAlert.id !== "" ? (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(alertNavigate(currentAlert));
                        }}
                        className="flex-col text-bold text-medium primary-color-35"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          textTransform: "uppercase",
                          cursor: "pointer",
                          paddingTop: "4%"
                        }}
                      >
                        Ir al servicio de origen
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="text-huge text-bold primary-color">
                  {loading ? "" : "No existen alertas actualmente"}
                </h1>
              </div>
            )}
          </LoadingOverlay>

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
