import { useAppSelector } from "../../../../../app/hooks";
import { selectProductTitle } from "../../../../common/commonSlice";
import "./categoryStyles.scss";

export interface CategoriesProps {
  categories: Array<JSX.Element>;
}
export const CategoriesComponent = (props: CategoriesProps) => {
  let productTitle = useAppSelector(selectProductTitle);
  return (
    <div className="flex-col" style={{ width: "95%" }}>
      <div
        className="flex-col text-medium text-bold primary-color"
        style={{
          border: "none",
          borderRadius: "8px 8px 0px 0px",
          height: "30px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1px",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        Componentes de {productTitle === "Alertas" ? "Core ID" : productTitle}
      </div>
      {props.categories &&
        props.categories.map((category) => {
          return category;
        })}
    </div>
  );
};
