import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import "../magicFormsStyles.scss";
import {
  changeActiveComponent,
  changePageState,
  getFilteredForms,
  selectActiveMagicComponent,
  selectCurrentNumPages,
  selectCurrentPageMagic,
  selectFilledForms,
  selectFilledFormsLoading,
  selectFilteredFormRequest,
} from "../magicFormsSlice";
import ClipLoader from "react-spinners/ClipLoader";

export const FilledFormsComponent = () => {
  let filledForms = useAppSelector(selectFilledForms);
  let currentPage = useAppSelector(selectCurrentPageMagic);
  let currentNumPages = useAppSelector(selectCurrentNumPages);
  let loadingStatus = useAppSelector(selectFilledFormsLoading);
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let filterRequest = useAppSelector(selectFilteredFormRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  useEffect(() => {
    if (activeComponent !== "Filledforms") {
      dispatch(changeActiveComponent("Filledforms"));
    }

    dispatch(getFilteredForms({ ...filterRequest }));

    return () => {};
  }, [filterRequest, activeComponent]);
  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {filledForms && filledForms.filledForms.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {filledForms?.filledForms ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Form ID</th>
                      <th>E-mail</th>
                      <th>Esquema</th>
                      <th>ID Core ID</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {filledForms.filledForms.map((form, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/magicForms/filledForms/${form.id}`
                            );
                          }}
                        >
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {form &&
                              form.userData &&
                              `${form.userData.name || ""} ${
                                form.userData.lastName || ""
                              }`}
                          </td>
                          <td>{form.id}</td>
                          <td style={{ textTransform: "uppercase" }}>
                            {form && form.userData && form.userData.email}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {form.formTemplateId}
                          </td>
                          <td>{form.userId}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "resolved"
                  ? ""
                  : "No hay más formularios en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{
                marginRight: "100px",
                fontSize: "13px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                  boxSizing: "border-box",
                }}
              >
                {"<"}
              </button>
              Página {currentPage + 1} de {currentNumPages}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  border: "none",
                  background: "rgba(0,0,0,0.0)",
                  color: "white",
                }}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending" || loadingStatus === "idle"
              ? ""
              : "No hay más formularios en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
