import axios from "axios";

let baseUrl = process.env.REACT_APP_BASE_URL || "";
let loginMethod = "auth";

export interface Token {
  token: string;
  loggedIn: boolean;
  errorMessage: string;
}
export interface TokenRecovery {
  mensage: string;
  errorMessage: string;
}

let login = async (username: string, password: string): Promise<Token> => {
  try {
    let loginRequest = { username, password };
    let loginData = await axios.post<Token>(
      `${baseUrl}/${loginMethod}`,
      loginRequest
    );

    if (loginData.status === 200) {
      return { loggedIn: true, token: loginData.data.token, errorMessage: "" };
    } else {
      return {
        loggedIn: false,
        token: "",
        errorMessage: "Hubo un error conectandote",
      };
    }
  } catch (err: any) {
    return {
      loggedIn: false,
      token: "",
      errorMessage: "El usuario o la contraseña NO son correctas",
    };
  }
};

let recoverEmail = async (email: string): Promise<TokenRecovery> => {
  try {
    let loginRequest = { email };
    let loginData = await axios.post<TokenRecovery>(
      `${baseUrl}/users/recoverPassword`,
      loginRequest
    );

    if (loginData.status === 200) {
      return { mensage:  "", errorMessage: "Se envió a tu correo electrónico un enlace para la recuperación de la contraseña." };
    } else {
      return {
        mensage: "",
        errorMessage: "Hubo un error conectandote",
      };
    }
  } catch (err: any) {
    return {
      mensage: "",
      errorMessage: "El usuario NO existe",
    };
  }
};

export { login , recoverEmail};
