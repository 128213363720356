import React, { useEffect } from "react";
import "./FirmaStyles.scss";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct } from "../../common/commonSlice";
import { Outlet, useNavigate } from "react-router-dom";
import { FooterServices } from "../../common/components/texts/FooterServices";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectActiveFirmaComponent,
  selectCurrentFilterRequest,
} from "./firmaSlice";
import { Category } from "../components/sidePanel/categories/Category";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
export const FirmaElectronica = () => {
  let activeComponent = useAppSelector(selectActiveFirmaComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Firmas") {
      return (
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                startDate: null,
                endDate: null,
              })
            );
          }}
          items={[
            <div
              key={0}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por método de autenticación
            </div>,
            <div key={1} style={{ alignItems: "center" }}>
              <RadioButton
                label="Biometría"
                checked={
                  currentFilteredRequest.verificationMethod ===
                  "Biometría Facial"
                }
                onClick={() => {
                  if (
                    currentFilteredRequest.verificationMethod ===
                    "Biometría Facial"
                  ) {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        verificationMethod: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      verificationMethod: "Biometría Facial",
                    })
                  );
                }}
              />
              <RadioButton
                label="OTP"
                checked={currentFilteredRequest.verificationMethod === "OTP"}
                onClick={() => {
                  if (currentFilteredRequest.verificationMethod === "OTP") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        verificationMethod: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      verificationMethod: "OTP",
                    })
                  );
                }}
              />
            </div>,
            <div
              key={2}
              className="text-normal text-small primary-color"
              style={{
                alignSelf: "start",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Por estado
            </div>,
            <div key={3} style={{ alignItems: "center" }}>
              <RadioButton
                label="Firmado"
                checked={currentFilteredRequest.status === "Signed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Signed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Signed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Empezo proceso"
                checked={currentFilteredRequest.status === "Confirmed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Confirmed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Confirmed",
                    })
                  );
                }}
              />
              <RadioButton
                label="Fallido"
                checked={currentFilteredRequest.status === "Failed"}
                onClick={() => {
                  if (currentFilteredRequest.status === "Failed") {
                    dispatch(
                      changeCurrentFilters({
                        ...currentFilteredRequest,
                        status: null,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      status: "Failed",
                    })
                  );
                }}
              />
            </div>,
          ]}
        />
      );
    } else {
      return;
    }
  };

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(changeCurrentProduct("Firma Electrónica"));
    dispatch(changeActiveComponent("Firmas"));
  }, []);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={
            <CategoriesComponent
              categories={[
                <Category
                  key={0}
                  name="Firmas realizadas"
                  last
                  selected={activeComponent === "Firmas"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/services/electronicSignature/signatures");
                  }}
                />,
              ]}
            />
          }
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
          <FooterServices />
        </div>
      </div>
    </div>
  );
};
