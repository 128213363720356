import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getClientDataAPI, getClientUserDataAPI } from "./commonApi";

// State Type
export type Service =
  | ""
  | "coreId"
  | "magicForms"
  | "electronicSignature"
  | "firmaNew"
  | "Biometrix Pay"
  | "Smart Contracts"
  | "hyperFlow"
  | "Authenticator"
  | "smartFlow"
  | "Pagares";

export type LoadingStatus = "idle" | "resolved" | "rejected" | "pending";

export type ProductTitle =
  | ""
  | "Core ID"
  | "Magic Forms"
  | "Firma Electrónica"
  | "HyperFlow"
  | "Alertas"
  | "Smart Contracts"
  | "Authenticator"
  | "Biometrix Pay"
  | "Smart Flow"
  | "Pagarés";
export interface CommonState {
  clientName: string;
  currentProduct: ProductTitle;
  userName: string;
  roles: Array<string>;
  activeServices: Array<Service>;
  inactiveServices: Array<Service>;
  loading: boolean;
  requestToken: string;
  tokenExpiryTimestamp: number;
  errorMessage: string;
  userEmail: string;
  menuEmergenteOpen: boolean;
}
// State
const initialState: CommonState = {
  clientName: "",
  roles: [],
  userName: "",
  activeServices: [],
  inactiveServices: [],
  loading: false,
  requestToken: "",
  tokenExpiryTimestamp: -1,
  currentProduct: "",
  errorMessage: "",
  userEmail: "",
  menuEmergenteOpen: false,
};

// Thunks
export const getClientAndUserData = createAsyncThunk(
  "common/getClientUserData",
  async (_, { rejectWithValue }) => {
    try {
      const clientData = await getClientDataAPI();
      if (clientData.error) {
        return rejectWithValue(clientData.error);
      }
      const clientUserData = await getClientUserDataAPI();
      if (clientUserData.error) {
        return rejectWithValue(clientData.error);
      }
      if (!clientData.data || !clientUserData.data) {
        return rejectWithValue(clientData.error);
      } else {
        return { clientUserData, clientData };
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Reducers
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setTokenInfo: (
      state,
      action: PayloadAction<{ token: string; expiry: number }>
    ) => {
      state.requestToken = action.payload.token;
      state.tokenExpiryTimestamp = action.payload.expiry;
    },
    changeCurrentProduct: (state, action: PayloadAction<ProductTitle>) => {
      state.currentProduct = action.payload;
    },
    resetState: () => {},
    changeMenuEmergente: (state, action: PayloadAction<boolean>) => {
      state.menuEmergenteOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientAndUserData.fulfilled, (state, action) => {
        let clientData = action.payload.clientData.data;
        let userData = action.payload.clientUserData.data;
        let active: Array<Service> = [];
        let inactive: Array<Service> = [];
        if (clientData?.activeServices && userData) {
          let service: keyof typeof clientData.activeServices;

          for (service in clientData?.activeServices) {
            let isActive = clientData?.activeServices[service];
            if (isActive) {
              active.push(service);
            } else {
              inactive.push(service);
            }
          }
          active.push("Pagares");
          if (
            active.includes("firmaNew") &&
            inactive.includes("electronicSignature")
          ) {
            inactive.splice(inactive.indexOf("electronicSignature"), 1);
          } else if (
            active.includes("electronicSignature") &&
            inactive.includes("firmaNew")
          ) {
            inactive.splice(inactive.indexOf("firmaNew"), 1);
          } else if (
            inactive.includes("firmaNew") &&
            inactive.includes("electronicSignature")
          ) {
            inactive.splice(inactive.indexOf("firmaNew"), 1);
          }
          inactive.push("Biometrix Pay");
          inactive.push("Smart Contracts");
          inactive.push("Authenticator");
          state.clientName = clientData.name;
          state.roles = userData.roles;
          state.userName = userData.name;
          state.activeServices = active;
          state.inactiveServices = inactive;
          state.userEmail = userData.email;
        } else {
          state.errorMessage = "No hay información sobre el cliente";
        }
        state.loading = false;
      })
      .addCase(getClientAndUserData.rejected, (state) => {
        state.errorMessage =
          "No pudimos obtener información, intentemos de nuevo";
      });
  },
});

export const {
  setTokenInfo,
  changeCurrentProduct,
  resetState,
  changeMenuEmergente,
} = commonSlice.actions;

export const selectClientName = (state: RootState) => state.common.clientName;
export const selectActiveServices = (state: RootState) =>
  state.common.activeServices;
export const selectInactiveServices = (state: RootState) =>
  state.common.inactiveServices;
export const selectRequestToken = (state: RootState) =>
  state.common.requestToken;
export const selectTokenExpiryTimestamp = (state: RootState) =>
  state.common.tokenExpiryTimestamp;
export const selectLoading = (state: RootState) => state.common.loading;
export const selectProductTitle = (state: RootState) =>
  state.common.currentProduct;
export const selectUsername = (state: RootState) => state.common.userName;
export const selectRoles = (state: RootState) => state.common.roles;
export const selectUserEmail = (state: RootState) => state.common.userEmail;
export const selectMenuEmergente = (state: RootState) =>
  state.common.menuEmergenteOpen;

export default commonSlice.reducer;
