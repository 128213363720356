import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changePageState,
  getFilteredPagares,
  selectActivePagareComponent,
  selectCurrentFilterPagareRequest,
  selectCurrentNumPagesPagare,
  selectCurrentPagePagare,
  selectPagares,
  selectLoadingPagares,
} from "../pagareSlice";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { getStatusPagareName } from "../../firma/helpers/utils";
import Modal, { Styles } from "react-modal";
import { selectRoles } from "../../../common/commonSlice";

Modal.setAppElement("#root");

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const PagaresList = () => {
  let pagares = useAppSelector(selectPagares);
  let currentPage = useAppSelector(selectCurrentPagePagare);
  let currentNumPages = useAppSelector(selectCurrentNumPagesPagare);
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterPagareRequest);
  let roles = useAppSelector(selectRoles);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };
  useEffect(() => {
    if (activeComponent !== "Pagares") {
      dispatch(changeActiveComponent("Pagares"));
    }

    dispatch(getFilteredPagares({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {pagares && pagares.filteredPagares.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Pagares
            </div>
          </div>
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "5px",
              marginRight: "2%",
              marginLeft: "1%",
              marginBottom: "2%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "180px",
              visibility: roles.includes("pagare.create")
                ? "visible"
                : "collapse",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/pagares/create");
            }}
          >
            CREAR NUEVO PAGARÉ
          </div>
          {pagares.filteredPagares ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>No. Pagaré</th>
                      <th>Deudor</th>
                      <th>Tipo de pagaré</th>
                      <th>Valor</th>
                      <th>Fecha Última versión</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {pagares.filteredPagares.map((pagare, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/pagares/list/${pagare.pagareRootId}`
                            );
                          }}
                        >
                          <td></td>
                          <td>{pagare.noPagare}</td>
                          <td>{pagare.deudoresName[0]}</td>
                          <td>
                            {pagare.pagareType == "Blank"
                              ? "En blanco con carta de instrucciones"
                              : "Diligenciado"}
                          </td>
                          <td>{`${
                            pagare.value === "0" ? "N/A" : pagare.value
                          } ${
                            pagare.currency && pagare.currency !== ""
                              ? pagare.currency
                              : ""
                          }`}</td>

                          <td>{formatDateStandar(pagare.versionDate)}</td>
                          <td>{getStatusPagareName(pagare.status)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más pagarés en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ marginRight: "100px", fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {currentNumPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "5px",
              marginRight: "2%",
              marginLeft: "1%",
              marginBottom: "2%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "180px",
              visibility: roles.includes("pagare.create")
                ? "visible"
                : "collapse",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/pagares/create");
            }}
          >
            CREAR NUEVO PAGARÉ
          </div>
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más pagarés en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
