import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import { getRegistrationByIdAPI, getImagesRegistrationAPI } from "../coreid/coreIdAPI";
import { getGeneratedDocumentsNewAPI, getSignatureByIdAPI } from "../firmanew/firmaNewAPI";
import { File } from "../firma/firmaAPI";
import {
  getFilledFormByIdAPI,
  getPdfFilledFormsAPI,
} from "../magicforms/magicFormsAPI";
import {
  changeFlowStatusAPI,
  Flow,
  FlowFilteredOptions,
  getFilteredFlowAPI,
  GetFilteredFlowRequest,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
} from "./smartFlowAPI";

export interface SmartFlowState {
  loadingStatus: LoadingStatus;
  documentLoading: LoadingStatus;
  currentFilteredRequest: GetFilteredFlowRequest;
  currentPage: number;
  numPages: number;
  flowFiltered: Flow[];
  filteredOptions: FlowFilteredOptions;
  currentFlow: Flow | null;
  isFlowDetail: boolean;
  currentFlowStatus: string;
}

const initialState: SmartFlowState = {
  loadingStatus: "idle",
  documentLoading: "idle",
  currentFilteredRequest: {
    page: 0,
    searchQuery: null,
    endDate: null,
    startDate: null,
    flowId: null,
  },
  flowFiltered: [],
  currentPage: 0,
  numPages: 0,
  filteredOptions: {},
  currentFlow: null,
  isFlowDetail: false,
  currentFlowStatus: "IN_PROGRESS",
};

export const getFilteredFlow = createAsyncThunk(
  "smartFlow/getFilteredFlow",
  async (params: GetFilteredFlowRequest, { rejectWithValue }) => {
    const filteredFlow = await getFilteredFlowAPI(params);
    if (
      !filteredFlow.flows ||
      (filteredFlow.error && filteredFlow.error !== "")
    ) {
      return rejectWithValue(filteredFlow.error);
    } else {
      for (let flow of filteredFlow.flows.flows) {
        try {
          let reg = await getRegistrationByIdAPI(flow.userId);
          if (reg.registration) {
            flow.registration = reg.registration;
          }
        } catch (err) { }
      }
      return filteredFlow;
    }
  }
);

export const getFilterOptions = createAsyncThunk(
  "smartFlow/getFilterOptions",
  async (_, { rejectWithValue }) => {
    const filterOptions = await getFilterOptionsFlowAPI();
    if (filterOptions.error && filterOptions.error !== "") {
      return rejectWithValue(filterOptions.error);
    } else {
      return filterOptions;
    }
  }
);





export const getFlowById = createAsyncThunk(
  "smartFlow/getFlowById",
  async (flowId: string, { rejectWithValue }) => {
    const flow = await getFlowByIdAPI(flowId);
    if (!flow.flow || (flow.error && flow.error !== "")) {
      return rejectWithValue(flow.error);
    } else {
      try {
        let reg = await getRegistrationByIdAPI(flow.flow.userId!);
        let images = await getImagesRegistrationAPI(flow.flow.userId);
        flow.flow.registration = reg.registration!;

        if (images.pictures) {
          flow.flow.registration.pictures = images.pictures;


        }
      } catch { }
      try {

        let form = await getFilledFormByIdAPI(flow.flow.filledFormId!);
        let firma = await getSignatureByIdAPI(flow.flow.electronicSignatureId);
        let firma_docs = await getGeneratedDocumentsNewAPI(flow.flow.electronicSignatureId);
        flow.flow.filledForm = form.filledForm!;
        let firma_data = firma.signature;


        if (firma_data) {

          firma_data.documents = firma_docs.documents;
          flow.flow.signaturedata = firma_data;

        }
      } catch { }

      return {
        ...flow,

      }
    }
  }
);

export const changeStatusById = createAsyncThunk(
  "smartFlow/changeFlowStatusById",
  async (newStatus: string, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const flow = currentState.smartFlow.currentFlow;
    if (flow && flow.status) {
      const updatedFlow = await changeFlowStatusAPI(newStatus, flow.id);
      if (updatedFlow.error && updatedFlow.error !== "") {
        return rejectWithValue(updatedFlow.error);
      } else {
        return updatedFlow.flow;
      }
    } else {
      return;
    }
  }
);

export const getFlowFilledFormPDF = createAsyncThunk(
  "smartFlow/getFlowFormPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);

export const smartFlowSlice = createSlice({
  name: "smartFlow",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredFlowRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      state.currentFilteredRequest.page = action.payload;
    },
    resetFlow: (state) => {
      state.currentFlow = null;
      state.documentLoading = "idle";
    },
    setIsFlowDetail: (state, action: PayloadAction<boolean>) => {
      state.isFlowDetail = action.payload;
    },
    changeCurrentFlowStatus: (state, action: PayloadAction<string>) => {
      if (state.currentFlow) {
        state.currentFlowStatus = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredFlow.fulfilled, (state, action) => {
        let flow = action.payload.flows;
        if (flow) {
          state.flowFiltered = flow.flows;
          state.currentPage = flow.currentPage;
          state.numPages = flow.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredFlow.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilteredFlow.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filteredOptions = filterOptions;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.filteredOptions = {};
      })
      .addCase(getFlowById.fulfilled, (state, action) => {
        let flow = action.payload.flow;
        if (flow) {
          state.currentFlow = flow;
          state.currentFlowStatus = flow.status;
          state.loadingStatus = "resolved";
        }
      })
      .addCase(getFlowById.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFlowById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentFlow = null;
      })
      .addCase(changeStatusById.fulfilled, (state, action) => {
        let flow = action.payload;
        if (flow && state.currentFlow) {
          state.currentFlow.status = flow.status;
          state.currentFlowStatus = flow.status;
          state.loadingStatus = "resolved";
        } else if (flow) {
          state.currentFlow = flow;
          state.currentFlowStatus = flow.status;
          state.loadingStatus = "resolved";
        }
      })
      .addCase(changeStatusById.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(changeStatusById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentFlow = null;
      })
      .addCase(getFlowFilledFormPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        let flow = state.currentFlow;
        if (flow && pdf) {
          flow.filledForm.document = pdf.pdfBase64;
          state.documentLoading = "resolved";
          state.currentFlow = flow;
        } else {
          state.documentLoading = "rejected";
        }
      })
      .addCase(getFlowFilledFormPDF.pending, (state) => {
        state.documentLoading = "pending";
      })
      .addCase(getFlowFilledFormPDF.rejected, (state) => {
        state.documentLoading = "rejected";
      });
  },
});

export const {
  changeCurrentFilters,
  changePageState,
  resetFlow,
  setIsFlowDetail,
  changeCurrentFlowStatus,
} = smartFlowSlice.actions;



export const selectCurrentPageSmartFlow = (state: RootState) =>
  state.smartFlow.currentFilteredRequest.page;
export const selectNumPagesSmartFlow = (state: RootState) =>
  state.smartFlow.numPages;
export const selectFlowFiltered = (state: RootState) =>
  state.smartFlow.flowFiltered;
export const selectFilteredOptions = (state: RootState) =>
  state.smartFlow.filteredOptions;
export const selectCurrentFlow = (state: RootState) =>
  state.smartFlow.currentFlow;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.smartFlow.currentFilteredRequest;
export const selectLoadingFlowStatus = (state: RootState) =>
  state.smartFlow.loadingStatus;
export const selectDocumentLoadingFlow = (state: RootState) =>
  state.smartFlow.documentLoading;
export const selectIsFlowDetail = (state: RootState) =>
  state.smartFlow.isFlowDetail;
export const selectCurrentFlowStatus = (state: RootState) =>
  state.smartFlow.currentFlowStatus;

export default smartFlowSlice.reducer;
