import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changePageState,
  getFilteredSignatures,
  selectActiveFirmaComponent,
  selectCurrentFilterRequest,
  selectCurrentNumPagesFirma,
  selectCurrentPageFirma,
  selectFirmas,
  selectLoading,
} from "../firmaNewSlice";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { getStatusName } from "../../firma/helpers/utils";
import Modal, { Styles } from "react-modal";

Modal.setAppElement("#root");

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const FirmasNew = () => {
  let firmas = useAppSelector(selectFirmas);
  let currentPage = useAppSelector(selectCurrentPageFirma);
  let currentNumPages = useAppSelector(selectCurrentNumPagesFirma);
  let loadingStatus = useAppSelector(selectLoading);
  let activeComponent = useAppSelector(selectActiveFirmaComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSignersNames, setCurrentSignersNames] = useState<Array<string>>(
    []
  );
  const [currentIdModal, setCurrentIdModal] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  let getSignersName = (signersNames: Array<string>, id: string) => {
    if (signersNames.length < 2) {
      return (
        <div>
          {signersNames.map((signer, index) => {
            return <div key={index}>{`${signer}`}</div>;
          })}
        </div>
      );
    } else {
      let result: Array<JSX.Element> = [];
      for (let i = 0; i < signersNames.length; i++) {
        result.push(<div key={i}>{`#${i + 1} ${signersNames[i]} - `}</div>);
      }
      result.push(
        <button
          onClick={(e) => {
            e.preventDefault();
            setCurrentSignersNames(signersNames);
            setCurrentIdModal(id);
            setIsOpen(true);
          }}
          style={{
            background: "rgba(0,0,0,0.4)",
            fontWeight: "600",
            fontSize: "12px",
            color: "white",
            border: "none",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "5px",
          }}
        >
          **{"  "} Ver Más {"  "} **{" "}
        </button>
      );
      return (
        <div className="flex-row" style={{ justifyContent: "space-evenly" }}>
          {result}
        </div>
      );
    }
  };

  useEffect(() => {
    if (activeComponent !== "FirmasNew") {
      dispatch(changeActiveComponent("FirmasNew"));
    }

    dispatch(getFilteredSignatures({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {firmas && firmas.filteredSignatures.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStylesModal}
            contentLabel="Error de login"
            shouldCloseOnOverlayClick={true}
          >
            <div
              className="flex-col"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="flex-col">
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  FIRMANTES TOTALES
                </div>
                <div
                  className="text-medium text-normal"
                  style={{
                    color: "black",
                    marginBottom: "14px",
                    fontWeight: "normal",
                  }}
                >
                  {currentSignersNames.map((value, i) => {
                    return <div key={i}>{`${i + 1}) ${value}`}</div>;
                  })}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "15px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/services/electronicSignatureNew/signatures/${currentIdModal}`
                    );
                  }}
                >
                  VER DETALLE DEL DOCUMENTO
                </div>
              </div>
              <button
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  closeModal();
                }}
              >
                Cerrar
              </button>
            </div>
          </Modal>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {firmas.filteredSignatures ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre de documento</th>
                      <th># Firmantes</th>
                      <th>Nombres de Firmantes</th>
                      <th>Fecha y Hora</th>
                      <th>Estado</th>
                      <th>Alertas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {firmas.filteredSignatures.map((firma, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                        >
                          <td></td>
                          <td
                            style={{ textTransform: "uppercase" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${firma.documentId}`
                              );
                            }}
                          >
                            {firma.documentName
                              ? firma.documentName.endsWith(".pdf")
                                ? firma.documentName.substring(
                                    0,
                                    firma.documentName.length - 4
                                  )
                                : firma.documentName
                              : ""}
                          </td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${firma.documentId}`
                              );
                            }}
                          >
                            {firma.numSigners}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getSignersName(firma.signers, firma.documentId)}
                          </td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${firma.documentId}`
                              );
                            }}
                          >
                            {formatDateStandar(firma.startedDate)}
                          </td>

                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${firma.documentId}`
                              );
                            }}
                          >
                            {getStatusName(firma.status)}
                          </td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/electronicSignatureNew/signatures/${firma.documentId}`
                              );
                            }}
                          >
                            {firma.numAlerts === 0
                              ? "Ninguna"
                              : firma.numAlerts}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más firmas en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ marginRight: "100px", fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {currentNumPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más firmas en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
