import { useEffect, useState } from "react";
import "../../../servicesCommonStyles.scss";
import { RadioButton } from "../../../../common/components/Btns/RadioButton";

export interface StateComponentProps {
  state: string;
  eventChangeState: Function;
  updateRe?: string;
}

export const StateComponent = (props: StateComponentProps) => {
  const [state, setState] = useState("");
  const [currentId, setcurrentId] = useState("");

  useEffect(() => {
    if (state === "") {
      setState(props.state);
    }
    if (currentId !== props.updateRe) {
      setcurrentId(props.updateRe ? props.updateRe : "");

      setState(props.state);
    }

    return () => {};
  }, [state, props.state, currentId, props.updateRe]);
  if (state === "DONE") {
    return (
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          borderRadius: "8px",
          padding: "2%",
        }}
      >
        <div className="flex-row">
          <div
            className="text-normal text-medium alert-color"
            style={{
              textAlign: "center",
              borderBottom: "1px solid  rgba(255,255,255,0.5)",
              width: "100%",
              textTransform: "uppercase",
              marginBottom: "10px",
            }}
          >
            Estado de verificación
          </div>
        </div>

        <div
          className="flex-row"
          style={{
            width: "100%",
            alignContent: "center",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <div
            className="flex-col"
            style={{
              width: "100%",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <div
              className="text-medium text-bold primary-color"
              style={{
                marginTop: "auto",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "20px",
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              COMPLETADO
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    state === "AWAITING_MANUAL_INTERACTION" ||
    state === "FAILED_MANUAL_RETRY" ||
    state === "DONE-Y"
  ) {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.1)",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className="flex-row">
            <div
              className="text-normal text-medium alert-color"
              style={{
                textAlign: "right",
                borderBottom: "1px solid white",
                width: "100%",
              }}
            >
              DEFINIENDO ESTADO{" "}
            </div>
          </div>

          <div className="flex-row">
            <div className="flex-col" style={{ width: "60%" }}>
              <RadioButton
                checked={state === "AWAITING_MANUAL_INTERACTION"}
                label="PARA REVISAR"
                onClick={() => {
                  setState("AWAITING_MANUAL_INTERACTION");
                }}
              />
              <RadioButton
                checked={state === "DONE-Y"}
                label="ACEPTAR"
                onClick={() => {
                  setState("DONE-Y");
                }}
              />
              <RadioButton
                checked={state === "FAILED_MANUAL_RETRY"}
                label="RECHAZAR"
                onClick={() => {
                  setState("FAILED_MANUAL_RETRY");
                }}
              />
            </div>
            <div className="flex-col" style={{ width: "40%" }}>
              <button
                className="text-medium text-bold primary-color"
                style={{
                  marginTop: "auto",
                  marginRight: "2%",
                  marginLeft: "auto",
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "8%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "8%",
                  borderRadius: "8px",
                  textAlign: "center",
                  border: "none",
                }}
                onClick={() => {
                  props.eventChangeState(state.split("-")[0]);
                  setState(state.split("-")[0]);
                }}
              >
                CONFIRMAR <br /> CAMBIO
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (state === "APPROVED" || state === "REJECTED") {
    return (
      <>
        <div
          style={{
            background: "rgba(0,0,0,0.1)",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className="flex-row">
            <div
              className="text-normal text-medium alert-color"
              style={{
                textAlign: "right",
                borderBottom: "1px solid white",
                width: "100%",
              }}
            >
              DEFINIENDO ESTADO{" "}
            </div>
          </div>

          <div className="flex-row">
            <div className="flex-col" style={{ width: "60%" }}>
              <RadioButton
                checked={state === "APPROVED"}
                label="ACEPTAR"
                onClick={() => {
                  setState("APPROVED");
                }}
              />
              <RadioButton
                checked={state === "REJECTED"}
                label="RECHAZAR"
                onClick={() => {
                  setState("REJECTED");
                }}
              />
            </div>
            <div className="flex-col" style={{ width: "40%" }}>
              <button
                className="text-medium text-bold primary-color"
                style={{
                  marginTop: "auto",
                  marginRight: "2%",
                  marginLeft: "auto",
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "8%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "8%",
                  borderRadius: "8px",
                  textAlign: "center",
                  border: "none",
                }}
                onClick={() => {
                  props.eventChangeState(state.split("-")[0]);
                  setState(state.split("-")[0]);
                }}
              >
                CONFIRMAR <br /> CAMBIO
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
