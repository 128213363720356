import format from "date-fns/format";

import es from "date-fns/locale/es";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ReactComponent as NotificationIcon } from "../../../Assets/common/images/notification.svg";
import { ReactComponent as NotificationIconV2 } from "../../../Assets/common/images/notification2.svg";

import { ReactComponent as SearchIcon } from "../../../Assets/common/images/search.svg";
import { ReactComponent as LeftIcon } from "../../../Assets/common/images/leftIcon.svg";
import { ReactComponent as SettingIcon } from "../../../Assets/common/images/settingIcon.svg";
import { ReactComponent as UserIcon } from "../../../Assets/common/images/userIcon.svg";
import { ReactComponent as ArrowMenuIcon } from "../../../Assets/common/images/arrowMenu.svg";
import {
  changeCurrentProduct,
  resetState,
  selectActiveServices,
  selectClientName,
  selectProductTitle,
  selectRoles,
  selectUsername,
  Service,
  selectUserEmail,
  selectMenuEmergente,
  changeMenuEmergente,
} from "../../common/commonSlice";
import "../servicesCommonStyles.scss";
import "./TopPanelStyle.scss";
import { useNavigate } from "react-router-dom";
import { getServiceName, getServicePath } from "../../../helpers/utils";
import { useCookies } from "react-cookie";
import axiosApiInstance from "../../../helpers/interceptor";
import localforage from "localforage";
import {
  getNumAlerts,
  selectAlerts,
  selectGeneralLoading,
} from "./GeneralSlice";
import { Badge } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Modal, { Styles } from "react-modal";

export interface TopPanelProps {
  searchValue?: string | number | readonly string[] | undefined;
  searchOnChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const useStyles = makeStyles(() =>
  createStyles({
    customBadge: {
      backgroundColor: "#C7FF4F",
      color: "black",
    },
  })
);

export const TopPanel = (props: TopPanelProps) => {
  let navigate = useNavigate();
  const [onFocus, setOnFocus] = useState(false);
  const [menuUser, setMenuUser] = useState(false);
  const [value, setValue] = useState("");

  const [profileOpen, setProfileOpen] = useState(false);
  const [, , removeCookie] = useCookies(["token"]);
  let date = new Date();
  let day = format(date, "dd");
  let month = format(date, "LLLL", { locale: es });
  let weekDay = format(date, "EEEE", { locale: es });

  let dispatch = useAppDispatch();

  let clientName = useAppSelector(selectClientName);
  let productTitle = useAppSelector(selectProductTitle);
  let roles = useAppSelector(selectRoles);
  let role = roles.length > 0 ? roles[0] : "";
  let username = useAppSelector(selectUsername);
  let activeServices = useAppSelector(selectActiveServices);
  let menuEm = useAppSelector(selectMenuEmergente);

  let loading = useAppSelector(selectGeneralLoading);
  let alerts = useAppSelector(selectAlerts);

  let [isOpen, setIsOpen] = useState(false);

  let userEmail = useAppSelector(selectUserEmail);
  const closeModal = () => {
    setIsOpen(false);
  };
  const classes = useStyles();

  useEffect(() => {
    if (alerts.numAlerts === null && loading !== "pending") {
      dispatch(getNumAlerts());
    }

    return () => {};
  }, [alerts]);

  return (
    <div
      className="flex-row"
      style={{
        height: "75px",
      }}
    >
      <div
        className="flex-col"
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "20%",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <h2
          className="text-bold text-xx-large primary-color-35"
          style={{
            margin: 0,
            textTransform: "uppercase",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/intro");
          }}
        >
          {clientName}
        </h2>
      </div>
      <div
        className="flex-row"
        style={{
          width: "10%",
          background: "rgba(0,0,0,0.1)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="flex-col calendar-background"
          style={{
            height: "30px",
            width: "30px",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "2px",
            marginRight: "9px",
          }}
        >
          <h6
            className="text-normal text-large primary-color"
            style={{
              marginLeft: 0,
              marginTop: "2px",
              marginRight: "0",
              marginBottom: "0",
            }}
          >
            {day}
          </h6>
        </div>
        <div className="flex-col" style={{ justifyContent: "space-between" }}>
          <h5 style={{ visibility: "hidden", margin: 0 }}>Month</h5>
          <h5
            className="text-bold text-small primary-color"
            style={{ margin: 0, textTransform: "uppercase" }}
          >
            {month}
          </h5>
          <h6
            className="text-normal text-x-small primary-color"
            style={{ margin: 0 }}
          >
            {weekDay}
          </h6>
        </div>
      </div>
      <div
        className="flex-col"
        style={{
          width: onFocus ? "15%" : "35%",
          background: "rgba(0,0,0,0.2)",
          justifyContent: "center",
        }}
      >
        <div style={{ marginLeft: "4%" }}>
          <p
            className="text-xxx-small text-normal primary-color-10"
            style={{ margin: 0 }}
          >
            SE ENCUENTRA EN
          </p>
          <h4
            className="text-xx-large text-bold primary-color"
            style={{ margin: 0, marginTop: "-5px" }}
          >
            {productTitle}
          </h4>
        </div>
      </div>
      {/* <div
        style={{
          width: onFocus ? "25%" : "5%",
          background: "rgba(0,0,0,0.2)",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        {onFocus ? (
          <div
            className="flex-row"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              borderLeft: "1px solid rgba(255,255,255,0.1) ",
              borderRight: "1px solid rgba(255, 255, 255,0.1)",
            }}
          >
            <input
              className="text-x-thin text-x-large primary-color"
              autoFocus
              onFocus={() => setOnFocus(true)}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              type="text"
              style={{
                textAlign: "center",
                height: "85%",
                width: "80%",
                background: "rgba(0,0,0,0.3)",
                border: 0,
              }}
            />
            <div
              className="flex-col"
              style={{
                height: "90%",
                background: "rgba(0,0,0,0.3)",
                justifyContent: "center",
                paddingRight: "10px",
              }}
            >
              <SearchIcon
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/search/${value}`);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              borderLeft: "1px solid rgba(255,255,255,0.1) ",
              // alignItems: "end",
            }}
          >
            <button
              className="flex-col"
              onClick={(e) => {
                e.preventDefault();
                setOnFocus(true);
              }}
              style={{
                padding: "0% 2% 0% 2%",
                height: "100%",
                borderLeft: "1px solid rgba(255,255,255,0.1) ",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                background: "rgba(0,0,0,0.0)",
              }}
            >
              <SearchIcon
                style={{
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  width: "100%",
                }}
              />
            </button>
          </div>
        )}

        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          style={customStylesModal}
          contentLabel="Error de búsqueda"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              Solicitud ingresada no encontrada
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              Intente nuevamente
            </button>
          </div>
        </Modal>
      </div> */}
      <div
        style={{
          width: "5%",
          background: "rgba(0,0,0,0.2)",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Alertas"));
            navigate("/services/alerts");
          }}
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: "1px solid rgba(255,255,255,0.1) ",
            borderRight: "1px solid rgba(255, 255, 255,0.1)",
            cursor: "pointer",
          }}
        >
          {alerts && alerts.numAlerts && alerts.numAlerts > 0 ? (
            <Badge
              badgeContent={alerts.numAlerts}
              classes={{ badge: classes.customBadge }}
              showZero
            >
              <NotificationIconV2 />
            </Badge>
          ) : (
            <NotificationIcon />
          )}
        </div>
      </div>
      {/* ----------------------MENU-EMERGENTE--------------------------- */}
      <div
        style={{
          width: "11%",
          background: menuEm ? "rgb(26 79 100)" : "rgba(0,0,0,0.2)",
          paddingTop: "15px",
          paddingBottom: menuEm ? "" : "15px",
          height: menuEm ? "min-content" : "",
          zIndex: "100",

          borderBottomLeftRadius: menuEm ? "10px" : "0",
          borderBottomRightRadius: menuEm ? "10px" : "0",
        }}
      >
        <div
          className="flex-col"
          style={{
            height: "90%",
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid rgba(255, 255, 255,0.1)",
          }}
        >
          <div
            className="flex-col"
            onClick={() => {
              dispatch(changeMenuEmergente(!menuEm));
            }}
            style={{
              width: menuEm ? "100%" : "",
              padding: menuEm ? " 2.5% 1% 0% 3%" : "10% 14.5% 0% 3%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div className="flex-row">
              <div className="flex-col" style={{ paddingTop: "10%" }}>
                <ArrowMenuIcon />
              </div>
              <div className="flex-col" style={{ paddingLeft: "5%" }}>
                <h4
                  className="text-xx-large text-bold primary-color"
                  style={{ margin: 0, paddingTop: "8%", paddingBottom: 0 }}
                >
                  MENÚ
                </h4>
                <p
                  className="text-xxx-small text-norma primary-color-30"
                  style={{ margin: 0, padding: 0 }}
                >
                  {"|PRINCIPAL|"}
                </p>
              </div>
            </div>

            {menuEm ? (
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  // background: "rgb(26 79 100)",
                  zIndex: "100",
                  justifyContent: "space-evenly",
                  alignItems: "start",
                }}
              >
                <ul
                  style={{ listStyle: "none", margin: 0, paddingLeft: "13px" }}
                >
                  {activeServices &&
                    activeServices.map((service, i) => {
                      if (service == "Pagares") {
                        if (
                          roles.includes("pagare.view") ||
                          roles.includes("Admin")
                        ) {
                          return (
                            <li
                              key={i}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(getServicePath(service));
                              }}
                            >
                              <p
                                className={`text-medium text-normal menu-item ${
                                  productTitle === getServiceName(service)
                                    ? "text-bold primary-color"
                                    : "primary-color-30"
                                }`}
                              >
                                {getServiceName(service)}
                              </p>
                            </li>
                          );
                        }
                      } else {
                        return (
                          <li
                            key={i}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(getServicePath(service));
                            }}
                          >
                            <p
                              className={`text-medium text-normal menu-item ${
                                productTitle === getServiceName(service)
                                  ? "text-bold primary-color"
                                  : "primary-color-30"
                              }`}
                            >
                              {getServiceName(service)}
                            </p>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      <div
        className="flex-col"
        style={{
          width: "15%",
          height: menuUser ? "min-content" : "",
          zIndex: "100",
          // background: "rgba(0,0,0,0.2)",
          background: menuUser ? "rgb(26 79 100)" : "rgba(0,0,0,0.2)",
          justifyContent: "center",
          borderBottomLeftRadius: menuUser ? "10px" : "0",
          borderBottomRightRadius: menuUser ? "10px" : "0",
          // alignItems: "end"
        }}
      >
        {/* ------------------------MENU-ESTANDAR------------------------------------- */}
        <div
          className="flex-col"
          style={{
            paddingTop: "1.5em",
            cursor: "pointer",
            // paddingRight: "10%",
            alignItems: "end",
            // paddingBottom: "5%",
          }}
        >
          <div
            onClick={() => setMenuUser(!menuUser)}
            className="flex-col"
            style={{ width: "100%", alignItems: "end" }}
          >
            <h5
              className="text-bold text-small primary-color"
              style={{
                margin: 0,
                textTransform: "uppercase",
                paddingRight: "10%",
                paddingBottom: "2%",
              }}
            >
              {username}
            </h5>
            <p
              className="text-normal text-x-small primary-color text-capitalized"
              style={{
                margin: 0,
                paddingRight: "10%",
                paddingBottom: "5%",
              }}
            >
              {role}
            </p>
          </div>
          {menuUser ? (
            <div
              style={{
                textAlignLast: "end",
                width: "100%",
                // background: "rgb(26 79 100)",
                zIndex: "100",
              }}
            >
              <ul style={{ listStyle: "none", padding: "0em 0.4em 0em 0em" }}>
                <li>
                  <button
                    onClick={() => setProfileOpen(!profileOpen)}
                    className="flex-row"
                    style={{
                      background: "none",
                      border: "none",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                      marginBottom: "5%",
                    }}
                  >
                    <p
                      className="text-normal text-x-small menu-item"
                      style={{ margin: "0" }}
                    >
                      Perfil
                    </p>
                    <UserIcon style={{ width: "20%" }} />
                  </button>
                  {/* ------------------------------------------------------------------------------
                  AGERGAR CONDICIONAL CUANDO ESTA AUTENTICADO PARA QUE MUESTRE LA INFO DEL USUARIO
                  ------------------------------------------------------------------------------ */}
                  {profileOpen ? (
                    <div style={{ textAlignLast: "start", paddingLeft: "2em" }}>
                      <li style={{ marginBottom: "5px" }}>
                        <p
                          className="text-xx-small text-normal primary-color-30"
                          style={{ margin: "0" }}
                        >
                          Perfil
                        </p>
                        <p
                          className="text-xxx-small text-normal primary-color"
                          style={{ margin: "0" }}
                        >
                          {username}
                        </p>
                      </li>

                      <li style={{ marginBottom: "5px" }}>
                        <p
                          className="text-xx-small text-normal primary-color-30"
                          style={{ margin: "0" }}
                        >
                          Correo
                        </p>
                        <p
                          className="text-xxx-small text-normal primary-color"
                          style={{ margin: "0" }}
                        >
                          {userEmail}
                        </p>
                      </li>
                      <li style={{ marginBottom: "5px" }}>
                        <p
                          className="text-xx-small text-normal primary-color-30"
                          style={{ margin: "0" }}
                        >
                          Roles
                        </p>
                        <p
                          className="text-xxx-small text-normal primary-color"
                          style={{ margin: "0" }}
                        >
                          {role}
                        </p>
                      </li>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <button
                    className="flex-row"
                    style={{
                      background: "none",
                      border: "none",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                      marginBottom: "5%",
                    }}
                  >
                    <p
                      className="text-normal text-x-small menu-item"
                      style={{ margin: "0" }}
                    >
                      Configuración
                    </p>
                    <SettingIcon style={{ width: "20%" }} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={async (e) => {
                      e.preventDefault();
                      dispatch(resetState());
                      removeCookie("token", {
                        path: "/",
                        sameSite: "lax",
                        secure: true,
                      });
                      delete axiosApiInstance.defaults.headers.common[
                        "Authorization"
                      ];
                      await localforage.clear();
                    }}
                    className="flex-row"
                    style={{
                      background: "none",
                      border: "none",
                      marginTop: "2em",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <p
                      className="text-small text-normal"
                      style={{ margin: 0, color: "white" }}
                    >
                      CERRAR SESIÓN
                    </p>
                    <LeftIcon style={{ width: "20%" }} />
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {/* ----------------- */}
      </div>
    </div>
  );
};
