export const getActionString = (action: string) => {
  switch (action) {
    case "Created":
      return "Creado";
    case "FillBefore":
      return "Prellenado";
    case "Signed":
      return "Firmado";
    case "Bloqueado por endoso":
      return "Bloqueado por endoso";
    case "Endosado":
      return "Endosado";
    case "Filled":
      return "Diligenciado";
    default:
      return "";
  }
};

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
