import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changePageState,
  getFilteredSignatures,
  selectActiveFirmaComponent,
  selectCurrentFilterRequest,
  selectCurrentNumPagesFirma,
  selectCurrentPageFirma,
  selectFirmas,
  selectLoading,
} from "../firmaSlice";
import { useEffect } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { getStatusName } from "../helpers/utils";
export const Signatures = () => {
  let firmas = useAppSelector(selectFirmas);
  let currentPage = useAppSelector(selectCurrentPageFirma);
  let currentNumPages = useAppSelector(selectCurrentNumPagesFirma);
  let loadingStatus = useAppSelector(selectLoading);
  let activeComponent = useAppSelector(selectActiveFirmaComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  useEffect(() => {
    if (activeComponent !== "Firmas") {
      dispatch(changeActiveComponent("Firmas"));
    }

    dispatch(getFilteredSignatures({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {firmas && firmas.signatures.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {firmas.signatures ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Identificación</th>
                      <th>Fecha y Hora</th>
                      <th>Email</th>
                      <th>Estado</th>
                      <th>Alertas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {firmas.signatures.map((firma, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/electronicSignature/signatures/${firma.signatureId}`
                            );
                          }}
                        >
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {firma.nombreApellido}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {firma.identification}
                          </td>
                          <td>{formatDateStandar(firma.fechaHora)}</td>
                          <td>{firma.email}</td>
                          <td>{getStatusName(firma.status)}</td>
                          <td>
                            {firma.alertas === 0 ? "Ninguna" : firma.alertas}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más firmas en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ marginRight: "100px", fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {currentNumPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más firmas en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
