import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import {
  changeActiveComponent,
  emptyCurrentAttatchment,
  getAttatchmentData,
  getRegistrationById,
  selectRegistrationImagesLoading,
  selectCoreIdLoading,
  selectCurrentAttatchment,
  selectCurrentRegistration,
  getImagesRegistration,
  selectAttatchmentsLoadingStatus,
  selectAttatchmentDownloadingStatus,
  getAttatchments,
  selectRegistrationAlerts,
  selectRegistrationAlertsLoading,
  getAlertsByRegistrationId,
} from "../coreIdSlice";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import Modal, { Styles } from "react-modal";
import { TitleSubtitleBox } from "../../components/Squares";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { ExternalQueryResult, RegistrationDetail } from "../coreIdAPI";
import { Attatchment } from "../../magicforms/magicFormsAPI";
import {
  BiometryImages,
  DocumentImages,
  Generalmages,
} from "./components/CoreIdImages";
import { getWholeName } from "./components/helpers/utils";
import {
  changeMenuEmergente,
  LoadingStatus,
} from "../../../common/commonSlice";
import { UnifiedAlerts } from "../../alerts/alertsAPI";
import fileDownload from "js-file-download";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "40%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const customStylesListDetail: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "25%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");
export const Registration = () => {
  let loading = useAppSelector(selectCoreIdLoading);
  let loadingImages = useAppSelector(selectRegistrationImagesLoading);
  let loadingAttatchments = useAppSelector(selectAttatchmentsLoadingStatus);
  let downloadingAttatchment = useAppSelector(
    selectAttatchmentDownloadingStatus
  );
  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let currentAttatchment = useAppSelector(selectCurrentAttatchment);
  let registrationAlerts = useAppSelector(selectRegistrationAlerts);
  let registrationAlertsLoading = useAppSelector(
    selectRegistrationAlertsLoading
  );

  const getStatusName = (status: string) => {
    switch (status) {
      case "ACCEPTED":
        return "Aprobado";

      case "IN_PROGRESS":
        return "En progreso";
      case "REJECTED":
        return "Rechazado";
      case "REVISION":
        return "Por revisar";
      default:
        return "";
    }
  };

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { id } = useParams();
  const [currentSection, setCurrentSection] = useState<
    | "General"
    | "Biometria"
    | "Document"
    | "Estado"
    | "Alerta"
    | "Adjuntos"
    | "Metadata"
    | "none"
  >("General");
  const [selectedDownload, setSelectedDownload] = useState(-1);

  const [currentAlert, setCurrentAlert] = useState<UnifiedAlerts>({
    clientId: "",
    dateTime: "",
    description: "",
    id: "",
    itemId: "",
    resolved: false,
    service: "",
    type: "",
    userId: "",
    details: [],
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  const [listDetailOpen, setListDetailOpen] = useState(false);

  const [listDetail, setListDetail] = useState<Array<string>>([]);

  const [popUpDetail, setPopUpDetail] = useState<
    "" | "Localization" | "Devices" | "Lists"
  >("");

  let getListasRestrictivas = (listInfo: Array<ExternalQueryResult>) => {
    let numLists = 0;
    let numAlerts = 0;
    let inRisk: {
      name: string;
      dato: string;
      risk: string;
      hasDetails: boolean;
      details: Array<string>;
    }[] = [];
    for (const element of listInfo) {
      numLists += element.numConsultedLists;
      numAlerts += element.numResultsWithRisk;
      for (const listResult of element.listResults) {
        inRisk.push({
          name: listResult.listName,
          dato: element.idConsultado,
          risk: listResult.risk ? "Si" : "No",
          hasDetails: listResult.resultDetails.length > 0,
          details: listResult.resultDetails,
        });
      }
    }
    return { numLists, numAlerts, inRisk };
  };

  let downloadAtatchment = (attatchment: Attatchment, selected: number) => {
    setSelectedDownload(selected);
    dispatch(
      getAttatchmentData({
        filledFormId: attatchment.filledFormId,
        stitchTypeId: attatchment.stitchTypeId,
      })
    );
  };

  let showAttatchments = (
    currentRegistration: RegistrationDetail,
    loadingStatus: LoadingStatus,
    downloadStatus: LoadingStatus
  ) => {
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        );

      case "rejected":
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar adjuntos
          </div>
        );
      case "resolved":
        if (
          !currentRegistration.attatchments ||
          currentRegistration.attatchments.length === 0
        ) {
          return (
            <div
              className="flex-col text-normal text-medium primary-color"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Este registro no tiene adjuntos
            </div>
          );
        } else {
          return (
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
              }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre adjunto</th>
                    <th>Nombre archivo</th>
                    <th>Descargar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {currentRegistration.attatchments &&
                    currentRegistration.attatchments.map((attatchment, i) => {
                      return (
                        <tr key={i} style={{ height: "30px" }}>
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {attatchment.fieldName || ""}
                          </td>
                          <td>{attatchment.fileName || ""}</td>
                          <td>
                            {downloadStatus === "pending" &&
                            selectedDownload === i ? (
                              <LoadingOverlay
                                active={downloadStatus === "pending"}
                                spinner={
                                  <ClipLoader color="white" size="20px" />
                                }
                              />
                            ) : (
                              <DownloadIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAtatchment(attatchment, i);
                                }}
                              />
                            )}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        }
      default:
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Adjuntos
          </div>
        );
    }
  };

  let getPopupDetail = (
    currentDetailedPopup: "" | "Localization" | "Devices" | "Lists",
    currentRegistration: RegistrationDetail
  ) => {
    switch (currentDetailedPopup) {
      case "Localization":
        return (
          <>
            {currentRegistration.location ? (
              <div className="flex-col" style={{ justifyContent: "start" }}>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.address && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Dirección:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.address}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.locality && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Ciudad:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.locality}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.country && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          País:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.country}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.state && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Estado/Departamento:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.state}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.adminArea && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Barrio:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        ></div>
                      </>
                    )}
                </div>

                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(false);
                      setPopUpDetail("");
                    }}
                    className="flex-col text-medium text-bold primary-color"
                    style={{
                      background: "#1A617D",
                      width: "20%",
                      height: "30px",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    Cerrar
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-col">
                Usuario no permitió validar localización
              </div>
            )}
          </>
        );
      case "Lists":
        return (
          <div
            className="flex-col"
            style={{ justifyContent: "start", height: "40em" }}
          >
            <table style={{ borderCollapse: "collapse" }}>
              <thead
                className="text-normal text-medium"
                style={{
                  background: "rgba(0,0,0,0.2)",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th></th>
                  <th>Lista</th>
                  <th>Dato</th>
                  <th>Riesgo</th>
                  <th>Detalles</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                className="text-normal text-small"
                style={{
                  textAlign: "center",
                }}
              >
                {getListasRestrictivas(
                  currentRegistration.externalQueryResults
                ).inRisk.map((list, i) => {
                  return (
                    <tr
                      key={i}
                      className="list-hover"
                      style={{ height: "30px" }}
                    >
                      <td></td>
                      <td>{list.name}</td>
                      <td>{list.dato}</td>
                      <td>{list.risk}</td>
                      <td
                        className="flex-col"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      >
                        {list.hasDetails ? (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setListDetail(list.details);
                              setListDetailOpen(true);
                            }}
                            className="primary-color flex-col"
                            style={{
                              boxSizing: "border-box",
                              height: "20px",
                              background: "#1A617D",
                              borderRadius: "8px",
                              width: "50%",
                              cursor: "pointer",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <EyeIcon />
                          </div>
                        ) : (
                          "No"
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setPopUpDetail("");
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "20%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        );
      case "Devices":
        return (
          <div className="flex-col" style={{ justifyContent: "start" }}>
            {currentRegistration.devices.map((device, j) => {
              return (
                <div key={j}>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Device ID:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.deviceId || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Dirección IP:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.ip || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      No. CPUs:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.numCpus || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Altura de pantalla:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.screenHeigh
                        ? `${device.screenHeigh}px`
                        : "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Ancho de pantalla:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.screenWidth
                        ? `${device.screenWidth}px`
                        : "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      De confianza:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.trusted ? "Si" : "No"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Tipo acceso:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.type || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Sistema Operativo:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black", textAlign: "end" }}
                    >
                      {device.os || "No Capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                        setPopUpDetail("");
                      }}
                      className="flex-col text-medium text-bold primary-color"
                      style={{
                        background: "#1A617D",
                        width: "20%",
                        height: "30px",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Cerrar
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      default:
        return (
          <div
            className="flex-col text-medium text-bold"
            style={{
              alignItems: "center",
              justifyContent: "center",
              color: "black",
            }}
          >
            No has seleccionado ningun detalle
          </div>
        );
    }
  };

  useEffect(() => {
    if ((!currentRegistration || currentRegistration.id !== id) && id !== "") {
      dispatch(getRegistrationById(id || ""));
    }
    if (currentAttatchment.fileBase64 !== "") {
      let url = `data:application/pdf;base64,${currentAttatchment.fileBase64}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, currentAttatchment.fileName);
        });
      dispatch(emptyCurrentAttatchment());
    }

    if (currentRegistration && loadingImages === "idle") {
      dispatch(getImagesRegistration(currentRegistration.id));
    }

    if (currentRegistration && loadingAttatchments === "idle") {
      dispatch(getAttatchments(currentRegistration.id));
    }
    if (currentRegistration && registrationAlertsLoading === "idle") {
      dispatch(getAlertsByRegistrationId(currentRegistration.id));
    }

    dispatch(changeActiveComponent("RegistrationDetail"));
  }, [currentRegistration, id, currentAttatchment, dispatch]);

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => {
          setIsOpen(false);
          setPopUpDetail("");
        }}
        style={customStylesModal}
        contentLabel="Detalle"
        shouldCloseOnOverlayClick={true}
      >
        {getPopupDetail(popUpDetail, currentRegistration!)}
      </Modal>
      <Modal
        isOpen={listDetailOpen}
        style={customStylesListDetail}
        onRequestClose={() => {
          setListDetailOpen(false);
          setListDetail([]);
        }}
      >
        <div className="flex-col">
          <div className="text-large text-bold" style={{ textAlign: "center" }}>
            Detalle de la lista
          </div>
          {listDetail.map((detail, i) => {
            return (
              <div
                key={i}
                className="text-small text-normal"
                style={{
                  borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                }}
              >
                {detail}
              </div>
            );
          })}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setListDetailOpen(false);
                setListDetail([]);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {currentRegistration && loading === "resolved" ? (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            paddingLeft: "1%",
          }}
        >
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  alignContent: "center",
                  height: "28px",
                }}
              ></div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex-row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <LeftArrow style={{ marginRight: "15px" }} />
                <div className="text-bold text-xx-large primary-color">
                  Regresar a lista de verificaciones
                </div>
              </div>
            </div>
          </div>
          <ExpandibleContent
            title="General"
            isActive={currentSection === "General"}
            onClickFunction={() => {
              setCurrentSection("General");
            }}
            content={
              <div
                className="flex-col"
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    height: "15%",
                    width: "100%",
                    alignItems: "start",
                    justifyContent: "start",
                    marginBottom: "5%",
                  }}
                >
                  <TitleSubtitleBox
                    title="Estado de verificación"
                    styles={{ marginRight: "1%" }}
                    content={getStatusName(currentRegistration.status)}
                  />
                  <TitleSubtitleBox
                    title="Día de registro"
                    styles={{ marginRight: "1%" }}
                    content={formatDateStandar(
                      currentRegistration.startDatetime
                    )}
                  />
                  <TitleSubtitleBox
                    title="Alertas"
                    styles={{ marginRight: "1%" }}
                    content={
                      registrationAlertsLoading === "idle"
                        ? "Esperando"
                        : registrationAlerts &&
                          registrationAlerts.alerts.length > 0
                        ? registrationAlerts.alerts.length
                        : "Ninguna"
                    }
                  />
                </div>
                <div
                  className="flex-row"
                  style={{
                    height: "60%",
                    width: "100%",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      width: "40%",
                      height: "100%",
                      marginRight: "5%",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "29px",
                        textAlignLast: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="text-normal text-medium primary-color-35">
                        Datos del usuario
                      </div>
                    </div>
                    <div
                      className="flex-col"
                      style={{
                        textAlign: "center",
                        color: "white",
                        background: "rgba(0,0,0,0.2)",
                        justifyContent: "center",
                        alignItems: "start",
                        boxSizing: "border-box",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="text-normal text-medium primary-color">
                          Nombre:
                        </div>
                        <div className="text-normal text-medium primary-color">
                          {currentRegistration.name}
                        </div>
                      </div>
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="text-normal text-medium primary-color">
                          Apellido:
                        </div>
                        <div className="text-normal text-medium primary-color">
                          {currentRegistration.lastName}
                        </div>
                      </div>
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="text-normal text-medium primary-color">
                          ID de registro:
                        </div>
                        <div className="text-normal text-medium primary-color">
                          {currentRegistration.id}
                        </div>
                      </div>
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="text-normal text-medium primary-color">
                          E-mail:
                        </div>
                        <div className="text-normal text-medium primary-color">
                          {currentRegistration.email}
                        </div>
                      </div>
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="text-normal text-medium primary-color">
                          No. de celular:
                        </div>
                        <div className="text-normal text-medium primary-color">
                          {currentRegistration.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      width: "40%",
                      height: "100%",
                      marginRight: "5%",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "29px",
                        textAlignLast: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="text-normal text-medium primary-color-35">
                        Imagenes prueba
                      </div>
                    </div>
                    <Generalmages
                      currentRegistration={currentRegistration}
                      loadingImages={loadingImages}
                    />
                  </div>
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Biometría"
            isActive={currentSection === "Biometria"}
            onClickFunction={() => {
              setCurrentSection("Biometria");
            }}
            content={
              <div
                className="flex-row"
                style={{
                  height: "100%",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    alignItems: "start",
                    justifyContent: "center",
                    marginRight: "10%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "29px",
                      width: "282px",
                      textAlignLast: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Imagenes prueba
                    </div>
                  </div>
                  <BiometryImages
                    currentRegistration={currentRegistration}
                    loadingImages={loadingImages}
                  />
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "space-evenly",
                    width: "15%",
                    marginTop: "10%",
                    height: "30%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Face Match
                    </div>
                    <div className="text-medium text-normal primary-color">
                      {`${currentRegistration.faceMatchPercent.toFixed(1)}%`}
                    </div>
                  </div>
                  {/* <div
                    className="flex-row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Liveness
                    </div>
                    <div className="text-medium text-normal primary-color">
                      {`${currentRegistration.livenessPercent.toFixed(1)}%`}
                    </div>
                  </div> */}
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Documento"
            isActive={currentSection === "Document"}
            onClickFunction={() => {
              setCurrentSection("Document");
            }}
            content={
              <div
                className="flex-row"
                style={{ height: "100%", justifyContent: "space-evenly" }}
              >
                <div
                  className="flex-col"
                  style={{
                    alignItems: "start",
                    justifyContent: "start",
                    marginRight: "5%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "29px",
                      width: "300px",
                      textAlignLast: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Imagenes prueba
                    </div>
                  </div>
                  <DocumentImages
                    currentRegistration={currentRegistration}
                    loadingImages={loadingImages}
                  />
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "start",
                    width: "15%",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    marginRight: "2%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !getWholeName(currentRegistration.userDocument) ||
                        getWholeName(
                          currentRegistration.userDocument
                        ).trim() === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Nombre y Apellido
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%" }}
                    >
                      {currentRegistration.userDocument &&
                        getWholeName(currentRegistration.userDocument)}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.documentNumber ||
                        currentRegistration.userDocument.documentNumber === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Número de documento
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%" }}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.documentNumber}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.birthDate ||
                        currentRegistration.userDocument.birthDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha de nacimiento
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.birthDate}
                    </div>
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expeditionDate ||
                        currentRegistration.userDocument.expeditionDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha y lugar de expedición
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.expeditionDate}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expirationDate ||
                        currentRegistration.userDocument.expirationDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha de expiración
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.expirationDate}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expeditionCity ||
                        currentRegistration.userDocument.expeditionCity === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Lugar de expedición
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {`${
                        (currentRegistration.userDocument &&
                          currentRegistration.userDocument.expeditionCity) ||
                        "No Encontrado"
                      } ${
                        currentRegistration.userDocument &&
                        currentRegistration.userDocument.country
                      }`}
                    </div>
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.gender ||
                        currentRegistration.userDocument.gender === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Sexo
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {(currentRegistration.userDocument &&
                        currentRegistration.userDocument.gender) ||
                        "No Encontrado"}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.bloodType ||
                        currentRegistration.userDocument.bloodType === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Tipo de sangre (RH)
                    </div>
                    <div
                      className="text-medium text-normal primary-color"
                      style={{ marginLeft: "10%", textTransform: "uppercase" }}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.bloodType}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    height: "60%",
                    width: "50%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      height: "50%",
                      background: "rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      width: "70%",
                      boxSizing: "border-box",
                      padding: "20px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        OCR
                      </div>
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.unifiedChecks.ocrCheck.active
                          ? currentRegistration.unifiedChecks.ocrCheck.result
                            ? "Ok"
                            : "No OK"
                          : "Desactivado"}
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Validez
                      </div>
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.unifiedChecks.documentValidityCheck
                          .active
                          ? currentRegistration.unifiedChecks
                              .documentValidityCheck.result
                            ? "Ok"
                            : "No Ok"
                          : "Desactivado"}
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Match entidad gubernamental
                      </div>
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.unifiedChecks
                          .governmentDataMatchCheck.active
                          ? currentRegistration.unifiedChecks
                              .governmentDataMatchCheck.result
                            ? "Ok"
                            : "No Ok"
                          : "Desactivado"}
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Captura
                      </div>
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.unifiedChecks.documentCaptureCheck
                          .active
                          ? currentRegistration.unifiedChecks
                              .documentCaptureCheck.result
                            ? "Ok"
                            : "No Ok"
                          : "Desactivado"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Estado del registro"
            isActive={currentSection === "Estado"}
            onClickFunction={() => {
              setCurrentSection("Estado");
            }}
            content={
              <div
                className="flex-row"
                style={{
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "20px",
                    justifyContent: "space-evenly",
                    height: "30%",
                    width: "40%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Localización
                    </div>
                    <div className="flex-row">
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.location
                          ? `${currentRegistration.location.state || ""}, ${
                              currentRegistration.location.country || ""
                            }`
                          : ""}
                      </div>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setPopUpDetail("Localization");
                          setIsOpen(true);
                        }}
                        className="flex-col text-medium text-bold primary-color-50"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: "90px",
                          height: "25px",
                          textAlign: "center",
                          borderRadius: "8px",
                          background: "rgba(0,0,0,0.5)",
                          cursor: "pointer",
                        }}
                      >
                        Ver más
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Dispositivos registrados
                    </div>
                    <div className="flex-row">
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {currentRegistration.devices.length}
                      </div>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setPopUpDetail("Devices");
                          setIsOpen(true);
                        }}
                        className="flex-col text-medium text-bold primary-color-50"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: "90px",
                          height: "25px",
                          textAlign: "center",
                          borderRadius: "8px",
                          background: "rgba(0,0,0,0.5)",
                          cursor: "pointer",
                        }}
                      >
                        Ver más
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Listas restrictivas
                    </div>
                    <div className="flex-row">
                      <div
                        className="text-medium text-normal primary-color-35"
                        style={{ marginRight: "10px" }}
                      >
                        {`${
                          getListasRestrictivas(
                            currentRegistration.externalQueryResults
                          ).numLists
                        } resultados, ${
                          getListasRestrictivas(
                            currentRegistration.externalQueryResults
                          ).numAlerts
                        } riesgosos`}
                      </div>{" "}
                      {getListasRestrictivas(
                        currentRegistration.externalQueryResults
                      ).numLists > 0 ? (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            setPopUpDetail("Lists");
                            setIsOpen(true);
                            dispatch(changeMenuEmergente(false));
                          }}
                          className="flex-col text-medium text-bold primary-color-50"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "90px",
                            height: "25px",
                            textAlign: "center",
                            borderRadius: "8px",
                            background: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        >
                          Ver más
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    padding: "20px",
                    width: "30%",
                    height: "70%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      OCR
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.ocrCheck.active
                        ? currentRegistration.unifiedChecks.ocrCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Face Match
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.facematchCheck.active
                        ? currentRegistration.unifiedChecks.facematchCheck
                            .result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      OTP
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.otpCheck.active
                        ? currentRegistration.unifiedChecks.otpCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Correo
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.emailCheck.active
                        ? currentRegistration.unifiedChecks.emailCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      GPS
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.gpsCheck.active
                        ? currentRegistration.unifiedChecks.gpsCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Validación documento
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.documentValidityCheck
                        .active
                        ? currentRegistration.unifiedChecks
                            .documentValidityCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Match Documento
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.documentMatchCheck
                        .active
                        ? currentRegistration.unifiedChecks.documentMatchCheck
                            .result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Goverment Data Match
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks
                        .governmentDataMatchCheck.active
                        ? currentRegistration.unifiedChecks
                            .governmentDataMatchCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Listas
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.listsCheck.active
                        ? currentRegistration.unifiedChecks.listsCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Device ID
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.deviceIdCheck.active
                        ? currentRegistration.unifiedChecks.deviceIdCheck.result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      borderBottom: "1px solid rgba(255,255,255,0.3)",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Captura de documento
                    </div>
                    <div
                      className="text-medium text-normal primary-color-35"
                      style={{ marginRight: "10px" }}
                    >
                      {currentRegistration.unifiedChecks.documentCaptureCheck
                        .active
                        ? currentRegistration.unifiedChecks.documentCaptureCheck
                            .result
                          ? "OK"
                          : "No OK"
                        : "Desactivado"}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Alertas"
            isActive={currentSection === "Alerta"}
            onClickFunction={() => {
              setCurrentSection("Alerta");
            }}
            content={
              registrationAlerts &&
              registrationAlerts.alerts &&
              registrationAlerts.alerts.length > 0 ? (
                <div className="flex-col" style={{ height: "100%" }}>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "center",
                      alignItems: "start",
                      height: "40%",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{ width: "60%", overflow: "auto" }}
                    >
                      <table style={{ borderCollapse: "collapse" }}>
                        <thead
                          className="text-normal text-medium primary-color-35"
                          style={{
                            background: "rgba(0,0,0,0.4)",
                            height: "30px",
                            textAlign: "center",
                          }}
                        >
                          <tr>
                            <th></th>
                            <th>Servicio</th>
                            <th>Fecha y Hora</th>
                            <th>Alertas</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-normal text-x-small primary-color"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {registrationAlerts?.alerts.map((alert, i) => {
                            return (
                              <tr
                                key={i}
                                className="list-hover"
                                style={{ height: "30px", cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCurrentAlert(alert);
                                }}
                              >
                                <td></td>
                                <td style={{ textTransform: "uppercase" }}>
                                  {alert.service}
                                </td>
                                <td>{formatDateStandar(alert.dateTime)}</td>
                                <td style={{ textTransform: "uppercase" }}>
                                  {alert.type}
                                </td>
                                <td></td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      width: "60%",
                      height: "60%",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        alignItems: "start",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "80%",
                      }}
                    >
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          marginRight: "3%",
                        }}
                      >
                        <div className="text-normal text-small primary-color">
                          Descripción de la alerta
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "rgba(0,0,0,0.2)",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            paddingBottom: "6px",
                            borderRadius: "8px",
                            paddingLeft: "5%",
                            paddingTop: "2%",
                            paddingRight: "2%",
                          }}
                        >
                          <div
                            className="text-normal primary-color-35 "
                            style={{ fontSize: "13px", height: "100%" }}
                          >
                            {currentAlert.description}
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          overflow: "auto",
                        }}
                      >
                        <div className="text-normal text-x-small primary-color">
                          Detalle de la alerta
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "rgba(0,0,0,0.2)",
                            justifyContent: "space-evenly",
                            alignItems: "start",
                            boxSizing: "border-box",

                            paddingBottom: "6px",
                            borderRadius: "8px",
                            overflow: "auto",
                            paddingLeft: "5%",
                            paddingTop: "2%",
                            paddingRight: "2%",
                          }}
                        >
                          {currentAlert.details.map((detail, i) => {
                            return (
                              <div
                                key={i}
                                className="flex-col"
                                style={{
                                  marginBottom: "5px",
                                }}
                              >
                                <div
                                  className="flex-col text-normal text-x-small"
                                  style={{ color: "#C7FF4F" }}
                                >
                                  {detail.title}
                                </div>
                                <div
                                  className="flex-col text-normal text-x-small primary-color"
                                  style={{}}
                                >
                                  {detail.description}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="flex-col"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1 className="text-huge text-bold primary-color">
                    No se encontraron alertas para este registro
                  </h1>
                </div>
              )
            }
          />
          <ExpandibleContent
            title="Adjuntos"
            isActive={currentSection === "Adjuntos"}
            contentHeight="50%"
            onClickFunction={() => {
              setCurrentSection("Adjuntos");
            }}
            content={
              <div className="flex-col">
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    alignItems: "end",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    paddingRight: "10px",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Datos del usuario
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    boxSizing: "border-box",
                  }}
                >
                  {showAttatchments(
                    currentRegistration,
                    loadingAttatchments,
                    downloadingAttatchment
                  )}
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Metadata"
            isActive={currentSection === "Metadata"}
            onClickFunction={() => {
              setCurrentSection("Metadata");
            }}
            content={
              <div className="flex-col" style={{ height: "100%" }}>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "start",
                    height: "40%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{ width: "100%", overflow: "auto" }}
                  >
                    <table style={{ borderCollapse: "collapse" }}>
                      <thead
                        className="text-normal text-medium primary-color-35"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "30px",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <th></th>
                          <th>Llave</th>
                          <th>Valor</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-normal text-x-small primary-color"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {Object.keys(currentRegistration.customData).map(
                          (metaData, i) => {
                            return (
                              <tr
                                key={i}
                                style={{ height: "30px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <td></td>
                                <td style={{ textTransform: "uppercase" }}>
                                  {metaData}
                                </td>
                                <td>
                                  {currentRegistration?.customData[metaData]}
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                        )}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
