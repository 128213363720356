import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct } from "../../common/commonSlice";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";

import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import {
  changeCurrentFilters,
  changeCurrentFlowStatus,
  changeStatusById,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectCurrentFlow,
  selectCurrentFlowStatus,
  selectFilteredOptions,
  selectIsFlowDetail,
} from "./smartFlowSlice";
import { StatusChangeSidePanel } from "../components/sidePanel/StatusChangeSidePanel";
import { StatusChangeRadio } from "../components/sidePanel/StatusChangeOptions/StatusChangeRadio";

export const SmartFlow = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let isFlowDetail = useAppSelector(selectIsFlowDetail);
  let currentFlowStatus = useAppSelector(selectCurrentFlowStatus);
  let currentFlow = useAppSelector(selectCurrentFlow);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getCurrentStatus = (
    statusConst: "APPROVED" | "PENDING" | "IN_PROGRESS" | "REJECTED" | string
  ) => {
    switch (statusConst) {
      case "APPROVED":
        return "Aprobado";
      case "IN_PROGRESS":
        return "En Progreso";
      case "PENDING":
        return "PENDIENTE";
      case "REJECTED":
        return "Rechazado";
      default:
        return "En Progreso";
    }
  };

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let searchInput = (
      <SearchInputFilter
        key={"SearchRegistrations"}
        onChange={(e) => {
          if (!e.target.value || e.target.value === "") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: null,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: e.target.value,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "80%", alignSelf: "center" }}
        placeHolder="No. de documento"
      />
    );
    return (
      <>
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                endDate: null,
                startDate: null,
              })
            );
          }}
          items={[searchInput]}
        />
      </>
    );
  };

  useEffect(() => {
    if (!filterOptions) {
      dispatch(getFilterOptions());
    }
  }, [filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
