import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct } from "../../common/commonSlice";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";

import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import {
  changeCurrentFilters,
  changeCurrentFlowStatus,
  changeStatusById,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectCurrentFlow,
  selectCurrentFlowStatus,
  selectFilteredOptions,
  selectIsFlowDetail,
} from "./hyperFlowSlice";
import { StatusChangeSidePanel } from "../components/sidePanel/StatusChangeSidePanel";
import { StatusChangeRadio } from "../components/sidePanel/StatusChangeOptions/StatusChangeRadio";
import { RadioButton } from "../../common/components/Btns/RadioButton";

export const HyperFlow = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let isFlowDetail = useAppSelector(selectIsFlowDetail);
  let currentFlowStatus = useAppSelector(selectCurrentFlowStatus);
  let currentFlow = useAppSelector(selectCurrentFlow);
let [search, setSearch]=useState(false);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getCurrentStatus = (
    statusConst: "APPROVED" | "PENDING" | "IN_PROGRESS" | "REJECTED" | string
  ) => {
    switch (statusConst) {
      case "APPROVED":
        return "Aprobado";
      case "IN_PROGRESS":
        return "En Progreso";
      case "PENDING":
        return "PENDIENTE";
      case "REJECTED":
        return "Rechazado";
      default:
        return "En Progreso";
    }
  };

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let searchInput = (
      <SearchInputFilter
        key={"SearchHyperFlow"}
        onChange={(e) => {
          if (!e.target.value || e.target.value === "") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: null,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: e.target.value,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "80%", alignSelf: "center" }}
        placeHolder=""
      />
    );
    return (
      <>
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                endDate: null,
                startDate: null,
              })
            );
          }}
          items={[ <div
            key={0}
            className="text-normal text-small primary-color"
            style={{
              alignSelf: "start",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            Por categoría
          </div>,
          <div key={1} style={{ alignSelf: "center" }}>
            <RadioButton
              label="Nombre"
              checked={currentFilteredRequest.type === "NOMBRE"}
              onClick={() => {
                if (currentFilteredRequest.type === "NOMBRE") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: null,
                    })
                  );
                }
              }}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    type: "NOMBRE",
                  })
                );
              }}
            />
            <RadioButton
              label="ID Registro"
              checked={currentFilteredRequest.type === "IDREGISTRO"}
              onClick={() => {
                if (currentFilteredRequest.type === "IDREGISTRO") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: null,
                    })
                  );
                }
              }}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    type: "IDREGISTRO",
                  })
                );
              }}
            />
            <RadioButton
              label="Fecha Registro"
              onClick={() => {
                if (currentFilteredRequest.type === "FECHAREGISTRO") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentFilteredRequest.type === "FECHAREGISTRO"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    type: "FECHAREGISTRO",
                  })
                );
              }}
            />
            <RadioButton
              label="Nombre Flujo"
              onClick={() => {
                if (currentFilteredRequest.type === "NOMBREFLUJO") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentFilteredRequest.type === "NOMBREFLUJO"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    type: "NOMBREFLUJO",
                  })
                );
              }}
            />
            <RadioButton
              label="Estado"
              onClick={() => {
                if (currentFilteredRequest.type === "ESTADO") {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      type: null,
                    })
                  );
                }
              }}
              checked={currentFilteredRequest.type === "ESTADO"}
              onChange={() => {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    type: "ESTADO",
                  })
                );
              }}
            />
          </div>,searchInput]}
        />
      </>
    );
  };

  useEffect(() => {
    if (!search) {
      dispatch(getFilterOptions());
      setSearch(true);
    }
  }, [filterOptions,setSearch]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        
          <SidePanel
            filter={getFilterItemsByActiveComponent()}
            categories={
              <CategoriesComponent
                categories={[
                  <Category
                    key={0}
                    name="Registros"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(changeCurrentProduct("HyperFlow"));
                      navigate("/services/hyperFlow/flows");
                    }}
                    selected={true}
                  />,
                ]}
              />
            }
          />
        

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
