import { useAppSelector } from "../../../../app/hooks";
import { ReactComponent as Dot } from "../../../../Assets/common/images/Dot.svg";
import { getServiceName } from "../../../../helpers/utils";
import {
  selectActiveServices,
  selectInactiveServices,
} from "../../commonSlice";

export const FooterServices = () => {
  let activeComponents = useAppSelector(selectActiveServices);
  let inactiveComponents = useAppSelector(selectInactiveServices);
  return (
    <div className="flex-col" style={{ height: "10%" }}>
      <div
        className="flex-row"
        style={{
          marginLeft: "2%",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <div
          className="text-xxx-small text-normal primary-color-30"
          style={{ textTransform: "uppercase" }}
        >
          | Servicios Activos |
        </div>
        <div className="flex-row" style={{ alignItems: "center" }}>
          {activeComponents.map((activeService, i) => {
            return (
              <div
                className="flex-row"
                style={{ alignItems: "center" }}
                key={i}
              >
                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                  <Dot />
                </div>
                <div className="text-x-bold text-huge primary-color-20">
                  {getServiceName(activeService)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="flex-row"
        style={{
          marginLeft: "2%",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <div className="flex-row" style={{ alignItems: "center" }}>
          {inactiveComponents.map((inactiveService, i) => {
            return (
              <a
                className="flex-row"
                key={i}
                style={{
                  cursor: "pointer",
                  justifyContent: "end",
                  alignItems: "end",
                  textDecorationLine: "none",
                }}
                href="https://www.anteia.com.co/productactivation"
                target="_blank"
              >
                <div
                  className="text-x-bold text-medium primary-color-10 hover-service"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                >
                  {getServiceName(inactiveService)}
                </div>
                <div
                  className="text-x-thin primary-color-10 hover-service"
                  style={{ textTransform: "uppercase", fontSize: "8px" }}
                >
                  | Activar Servicio |
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
