import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  changeCurrentSigner,
  getGeneratedDocumentsNew,
  getSignatureById,
  selectCurrentDocuments,
  selectCurrentFirma,
  selectDocumentsLoading,
  selectLoading,
} from "../firmaNewSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { formatDateStandar } from "../../../../helpers/utils";
import { LoadingStatus } from "../../../common/commonSlice";
import {
  MediumSquare,
  SmallSquare,
} from "../../firma/detail/components/Squares";
import { getStatusName } from "../../firma/helpers/utils";
import { GeneratedDocuments, VerificationImage } from "../../firma/firmaAPI";
import fileDownload from "js-file-download";

export const DetailFirmaNew = () => {
  let loadingStatus = useAppSelector(selectLoading);
  let documentsLoading = useAppSelector(selectDocumentsLoading);
  let currentSignature = useAppSelector(selectCurrentFirma);
  let documents = useAppSelector(selectCurrentDocuments);

  const [documentToVisualize, setDocumentToVisualize] = useState<{
    documentBase64: string;
    documentName: string;
  }>({
    documentBase64: "",
    documentName: "",
  });

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let getDocument = (
    loadingDocuments: LoadingStatus,
    generatedDocuments: GeneratedDocuments,
    documentType: "Certificate" | "SignedDocument",
    status: string,
    setDocumentVisualizer: React.Dispatch<
      React.SetStateAction<{
        documentBase64: string;
        documentName: string;
      }>
    >
  ) => {
    switch (loadingDocuments) {
      case "pending":
        return (
          <LoadingOverlay
            active={documentsLoading === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          ></LoadingOverlay>
        );
      case "resolved":
        if (!generatedDocuments.finishedSignature) {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          if (documentType === "Certificate") {
            return (
              <>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.certificate.documentBase64,
                      documentName: generatedDocuments.certificate.documentName,
                    });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.certificate.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(blob, "Certificado.pdf");
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  className="flex-row"
                  onClick={(e) => {
                    e.preventDefault();
                    setDocumentVisualizer({
                      documentBase64:
                        generatedDocuments.document.documentBase64,
                      documentName: generatedDocuments.document.documentName,
                    });
                  }}
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginBottom: "5%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Visualizar</div>
                  <EyeIcon />
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    let url = `data:application/pdf;base64,${generatedDocuments.document.documentBase64}`;

                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          generatedDocuments.document.documentName
                        );
                      });
                  }}
                  className="flex-row"
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    width: "60%",
                    height: "30%",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>Descargar</div>
                  <DownArrow />
                </div>
              </>
            );
          }
        }
      case "rejected":
        if (status !== "Signed") {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No se ha terminado el proceso de firma
            </div>
          );
        } else {
          return (
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              No pudimos cargar los documentos
            </div>
          );
        }
      default:
        return (
          <>
            <div
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                marginBottom: "2%",
              }}
            >
              <div style={{ marginRight: "5%" }}>Visualizar</div>
              <EyeIcon />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
              className="flex-row"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "60%",
                height: "30%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <div style={{ marginRight: "5%" }}>Descargar</div>
              <EyeIcon />
            </div>
          </>
        );
    }
  };

  let { id } = useParams();

  useEffect(() => {
    if (
      (!currentSignature || currentSignature.documentId !== id) &&
      id !== ""
    ) {
      dispatch(getSignatureById(id || ""));
    }

    if (currentSignature && documentsLoading === "idle") {
      dispatch(getGeneratedDocumentsNew(currentSignature.documentId));
    }

    dispatch(changeActiveComponent("none"));

    return () => {};
  }, [id, currentSignature]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {currentSignature &&
      currentSignature.signers.length > 0 &&
      loadingStatus === "resolved" ? (
        <div className="flex-col" style={{ height: "100%", overflow: "auto" }}>
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar a lista de verificaciones
              </div>
            </div>
          </div>
          <div
            className="flex-col"
            style={{
              height: "85%",
              width: "100%",
              justifyContent: "start",
              padding: "1%",
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: "start",
                height: "12%",
              }}
            >
              <SmallSquare
                parentWidth="23%"
                title="Estado de la firma"
                content={<div>{getStatusName(currentSignature.status)}</div>}
              />
              <SmallSquare
                parentWidth="23%"
                title="Fecha de la firma"
                content={
                  <div>
                    {formatDateStandar(currentSignature.documentStartedDate)}
                  </div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="Alertas"
                content={
                  <div>
                    {currentSignature.numAlerts === 0
                      ? "Ninguna"
                      : currentSignature.numAlerts}
                  </div>
                }
              />
              <SmallSquare
                parentWidth="23%"
                title="No. de firmantes"
                content={<div>{currentSignature.numSigners}</div>}
              />
            </div>
            <div
              className="flex-row"
              style={{
                height: "60%",
                justifyContent: "start",
              }}
            >
              <div
                className="flex-col"
                style={{ width: "95%", marginRight: "1%" }}
              >
                <div
                  className="flex-col text-medium text-normal primary-color-35"
                  style={{
                    marginTop: "3%",
                    background: "rgba(0,0,0,0.4)",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    height: "8%",
                  }}
                >
                  Datos de los firmantes
                </div>
                <div
                  className="flex-col text-medium text-normal primary-color"
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "space-evenly",
                    alignItems: "start",
                    height: "85%",
                    marginBottom: "3%",
                    boxSizing: "border-box",
                    padding: "5%",
                    overflow: "auto",
                  }}
                >
                  {currentSignature.signers.map((signer, index) => {
                    return (
                      <div
                        className="flex-col"
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(changeCurrentSigner(signer));
                          navigate(
                            `/services/electronicSignatureNew/signatures/${currentSignature?.documentId}/signer/${signer.signerId}`
                          );
                        }}
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "40%",
                          height: "100%",
                          borderRadius: "8px",
                          paddingLeft: "2%",
                          cursor: "pointer",
                          justifyContent: "space-evenly",
                          paddingTop: "3%",
                          paddingBottom: "3%",
                        }}
                      >
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Nombre : {signer.name}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Apellido : {signer.lastName}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          User ID : {signer.userId}
                        </div>
                        <div style={{ fontSize: "14px", color: "white" }}>
                          Email : {signer.email}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                height: "20%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  width: "50%",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <MediumSquare
                  parentWidth="46%"
                  title="Documento Firmado"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "SignedDocument",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
                <MediumSquare
                  parentWidth="46%"
                  title="Certificado de firma"
                  content={getDocument(
                    documentsLoading,
                    documents!,
                    "Certificate",
                    currentSignature.status,
                    setDocumentToVisualize
                  )}
                />
              </div>
            </div>
          </div>
          {documentToVisualize.documentBase64 !== "" ? (
            <div
              className="flex-col"
              style={{
                position: "absolute",
                left: "1%",
                top: "10%",
                height: "100%",
                width: "100%",
              }}
            >
              <button
                onClick={() =>
                  setDocumentToVisualize({
                    documentBase64: "",
                    documentName: "",
                  })
                }
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "25px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                  color: "white",
                }}
              >
                Cerrar
              </button>
              <object
                data={`data:application/pdf;base64,${documentToVisualize.documentBase64}`}
                type="application/pdf"
                width="100%"
                height="100%"
              ></object>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
