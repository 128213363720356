import axiosApiInstance from "../../../helpers/interceptor";
import { Attatchment, BioResponse, Device, ExternalQueryResult, GovernmentData, InitialData, MultiFactorMatrix, Pictures, RegistrationDetail, UnifiedChecks, UserDocument } from "../coreid/coreIdAPI";
import { BackOfficeSigner, GetSignatureResponse } from "../firmanew/firmaNewAPI";
import { hyperFlow } from "../hyperFlow/hyperFlowAPI";
import { FilledForm } from "../magicforms/magicFormsAPI";

let getSearch = "search";

let getSearchQuery = async (
  parameters: SearchQuery
): Promise<{ registrations: SearchQueryData | null; error: string }> => {
  let registrationsResponse = await axiosApiInstance.post(
    `/${getSearch}`,
    parameters  );
  if (registrationsResponse.status !== 200) {
    return { registrations: null, error: "" };
  } else {
    var data={
      "coreIdResults": [
        {
          "id": "string",
          "projectId": "string",
          "clientId": "string",
          "userId": "string",
          "userIdType": "string",
          "userIdentification": "string",
          "isNaturalPerson": true,
          "name": "string",
          "lastName": "string",
          "nit": "string",
          "startDatetime": "2022-08-26",
          "email": "string",
          "phone": "string",
          "endDatetime": "2022-08-26",
          "videoPath": "string",
          "result": true,
          "bioResponses": [
            {
              "registrationId": "string",
              "requirementId": "string",
              "data": "string",
              "dataPath": "string",
              "result": true
            }
          ],
          "devices": [
            {
              "os": "string",
              "osVersion": "string",
              "deviceId": "string",
              "userId": "string",
              "screenHeigh": 0,
              "screenWidth": 0,
              "trusted": true,
              "type": "string",
              "ip": "string",
              "numCpus": "string",
              "regId": "string"
            }
          ],
          "location": {
            "lat": 0,
            "lon": 0,
            "trusted": true,
            "userId": "string",
            "locality": "string",
            "adminArea": "string",
            "state": "string",
            "country": "string",
            "postalCode": "string",
            "address": "string",
            "regId": "string"
          },
          "externalQueryResults": [
            {
              "result": true,
              "externalEntityName": "string",
              "externalEntityId": "string",
              "idConsultado": "string",
              "tipoIdConsultado": "string",
              "risk": true,
              "warning": true,
              "numConsultedLists": 0,
              "numResultsWithRisk": 0,
              "listResults": [
                {
                  "listName": "string",
                  "resultDetails": [
                    "string"
                  ],
                  "result": true,
                  "risk": true,
                  "warning": true,
                  "errorString": "string",
                  "isBlocking": true,
                  "nameMatch": true,
                  "documentMatch": true,
                  "blocking": true
                }
              ]
            }
          ],
          "hasAlerts": true,
          "faceMatchPercent": 0,
          "country": "string",
          "governmentData": {
            "entityName": "string",
            "document": "string",
            "fullName": "string",
            "firstName": "string",
            "middleName": "string",
            "lastName": "string",
            "secondLastName": "string",
            "expirationDate": "2022-08-26",
            "expeditionDate": "2022-08-26",
            "sex": "string",
            "birthDate": "2022-08-26"
          },
          "customData": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "initialData": {
            "identification": "string",
            "lastName": "string",
            "phoneNumber": "string",
            "email": "string"
          },
          "unifiedChecks": {
            "ocrCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "gpsCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "otpCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "emailCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "facematchCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "documentValidityCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "documentMatchCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "governmentDataMatchCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "listsCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "deviceIdCheck": {
              "result": true,
              "active": true,
              "description": "string"
            },
            "documentCaptureCheck": {
              "result": true,
              "active": true,
              "description": "string"
            }
          },
          "unifiedChecksOk": true,
          "rut": "string",
          "acceptedTyC": true,
          "userDocument": {
            "country": "string",
            "type": "string",
            "documentNumber": "string",
            "expirationDate": "string",
            "expeditionDate": "string",
            "birthDate": "string",
            "firstName": "string",
            "middleName": "string",
            "lastName": "string",
            "secondLastName": "string",
            "aditionalData": {
              "additionalProp1": "string",
              "additionalProp2": "string",
              "additionalProp3": "string"
            },
            "birthPlace": "",
            "bloodType": "",
            "gender": "string",
            "hasPicture": true,
            "expeditionCity": "string"
          },
          "livenessPercent": 0,
          "multiFactorMatrix": {
            "faceMatch": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "validity": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "veracity": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "lists": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "liveness": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "geolocation": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "deviceId": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "initialDataMatch": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "govEntity": {
              "score": 0,
              "blockingThreshold": 0,
              "blocking": true,
              "passes": true,
              "weight": 0,
              "active": true
            },
            "finalScore": 0
          },
          "naturalPerson": true
        }
      ],
      "magicFormsResult": [
        {
          "id": "string",
          "userId": "string",
          "formTemplateId": "string",
          "clientId": "string",
          "data": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "requirements": [
            {
              "stitchTypeId": "string",
              "name": "string",
              "source": "string",
              "conditionalSourceId": "string",
              "conditionalDestinationId": [
                "string"
              ]
            }
          ],
          "conditionals": {
            "additionalProp1": {
              "id": "string",
              "status": true,
              "valueMapper": {
                "additionalProp1": true,
                "additionalProp2": true,
                "additionalProp3": true
              }
            },
            "additionalProp2": {
              "id": "string",
              "status": true,
              "valueMapper": {
                "additionalProp1": true,
                "additionalProp2": true,
                "additionalProp3": true
              }
            },
            "additionalProp3": {
              "id": "string",
              "status": true,
              "valueMapper": {
                "additionalProp1": true,
                "additionalProp2": true,
                "additionalProp3": true
              }
            }
          },
          "electronicSignatureId": "string",
          "metadata": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "done": true,
          "redirectionUrl": "string",
          "startDatetime": "2022-08-26",
          "pdfName": "string",
          "attatchments": [
            {
              "fileName": "string",
              "stitchTypeId": "string",
              "fieldName": "string",
              "filledFormId": ""
            }
          ]
        }
      ],
      "hyperFlowResult": [
        {
          "clientId": "string",
          "hyperFlowId": "string",
          
          "stateId": "string",
          "userId": "string",
          "flowStatus": "AWAITING_MANUAL_INTERACTION",
          "metadata": {
            "additionalProp1": "string",
            "additionalProp2": "string",
            "additionalProp3": "string"
          },
          "currentAssignees": [
            "string"
          ],
          "currentStepId": "string"
        }
      ],
      "elecSigResult": [
        {
          "status": "string",
          "dateTime": "2022-08-26",
          "alerts": [
            "string"
          ],
          "verificationMethod": "string",
          "userId": "string",
          "userPhotoUrl": "string",
          "signedDocumentUrl": "string",
          "signatureCertificateUrl": "string"
        }
      ]
    };
    return {
      error: "",
      registrations: registrationsResponse.data,
    };
  }
};


export interface SearchQuery {
  query: string | null | undefined;

}

export interface SearchQueryData {
  coreIdResults: Array<RegistrationDetailBusqueda>;
  magicFormsResult: Array<FilledForm>;
  hyperFlowResult: Array<hyperFlow>;
  elecSigResult: Array<GetSignatureResponseBusqueda>;
}

export interface RegistrationDetailBusqueda {
  id: string;
  projectId: string;
  clientId: string;
  userId: string;
  userIdType: string;
  userIdentification: string;
  isNaturalPerson: boolean;
  name: string;
  lastName: string;
  nit: string;
  startDatetime: string;
  email: string;
  phone: string;
  endDatetime: string;
  videoPath: string;
  result: boolean;
  bioResponses: BioResponse[];
  devices: Device[];
  location: Location;
  externalQueryResults: ExternalQueryResult[];
  hasAlerts: boolean;
  faceMatchPercent: number;
  country: string;
  governmentData: GovernmentData;
  customData: { [key: string]: string };
  initialData: InitialData;
  unifiedChecks: UnifiedChecks;
  unifiedChecksOk: boolean;
  rut: string;
  acceptedTyC: boolean;
  userDocument: UserDocument;
  naturalPerson: boolean;
  pictures?: Pictures;
  livenessPercent: number;
  attatchments?: Array<Attatchment>;
  multiFactorMatrix?: MultiFactorMatrix | null;
}
export interface GetSignatureResponseBusqueda {
  status: string;
  documentStartedDate?: string;
  numAlerts?: number;
  numSigners?: number;
  signers?: Array<BackOfficeSigner>;
  documentId?: string;
  dateTime?: string;
  alerts?: string[];
  verificationMethod?: string;
  userId?: string;
  userPhotoUrl?: string;
  signedDocumentUrl?: string;
  signatureCertificateUrl?:string;
}

export {
  getSearchQuery
};
