export interface AlertDashboardElementProps {
  dot: JSX.Element;
  title: string;
  subtitle: string;
  last?: boolean;
}

export const AlertDashboardElement = (props: AlertDashboardElementProps) => {
  return (
    <div
      className="flex-col"
      style={{
        justifyContent: "space-evenly",
        alignItems: "start",
        width: "12%",
        marginRight: `${props.last ? "0" : "5%"}`,
      }}
    >
      <div
        className="flex-row"
        style={{ justifyContent: "space-evenly", alignItems: "center" }}
      >
        <div
          className="text-medium text-bold primary-color"
          style={{ marginRight: "5px", textTransform: "uppercase" }}
        >
          {props.title}
        </div>
        {props.dot}
      </div>
      <div className="text-small text-normal primary-color-35">
        {props.subtitle}
      </div>
    </div>
  );
};
