import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import "./magicFormsStyles.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeEndDate,
  changeStartDate,
  selectActiveMagicComponent,
  selectEndDateMagic,
  selectFilteredFormRequest,
  selectStartDateMagic,
  changeCurrentFilters,
  selectFilterOptionsFilledForms,
  getFilterOptions,
} from "./magicFormsSlice";
import { changeCurrentProduct } from "../../common/commonSlice";
import { useEffect } from "react";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { DropDown } from "../../common/components/Btns/DropDown";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";

export const MagicForms = () => {
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let startDate = useAppSelector(selectStartDateMagic);
  let endDate = useAppSelector(selectEndDateMagic);
  let currentFilteredRequest = useAppSelector(selectFilteredFormRequest);
  let filterOptionsFilledforms = useAppSelector(selectFilterOptionsFilledForms);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Filledforms") {
      let templateOptions: { value: string; label: string }[] = [];
      let templates = filterOptionsFilledforms?.templates;
      if (templates) {
        for (const template in templates) {
          let label = templates[template];
          let value = template;
          templateOptions.push({ label, value });
        }
      }
      let templateDropdown = (
        <DropDown
          key={"Tipo de documento"}
          title="Tipo de documento"
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                templateId: e?.value!,
              })
            );
          }}
          options={templateOptions}
        />
      );
      return (
        <FilterComponent
          key={0}
          hasCalendar={false}
          endDate={endDate ? new Date(endDate) : null}
          startDate={startDate ? new Date(startDate) : null}
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(changeEndDate(date.toISOString()));
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(changeStartDate(date.toISOString()));
              }
            }
          }}
          resetFunction={() => {
            dispatch(changeStartDate(null));
            dispatch(changeEndDate(null));
          }}
          items={[templateDropdown]}
        />
      );
    } else if (activeComponent === "Templates") {
      return;
    } else {
      return;
    }
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Magic Forms"));
    if (!filterOptionsFilledforms) {
      dispatch(getFilterOptions());
    }
  }, [activeComponent, filterOptionsFilledforms]);

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={
            <CategoriesComponent
              categories={[
                <Category
                  key={0}
                  name="Formularios Realizados"
                  selected={activeComponent === "Filledforms"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/services/magicForms/filledForms");
                  }}
                />,
                <Category
                  key={1}
                  name="Tipo de documentos"
                  last
                  selected={activeComponent === "Templates"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/services/magicForms/templates");
                  }}
                />,
              ]}
            />
          }
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
