import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import "../servicesCommonStyles.scss";
import { ReactComponent as ExpandMore } from "../../../Assets/common/images/iconExpand.svg";
import { ReactComponent as ExpandLess } from "../../../Assets/common/images/iconExpand2.svg";

export interface ExpandibleContentProps {
  title: string;
  isActive: boolean;
  content: JSX.Element;
  onClickFunction?: Function;
  contentHeight?: string;
}

export const ExpandibleContent = (props: ExpandibleContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });
  useEffect(() => {
    setIsExpanded(props.isActive);
  }, [props.isActive, setIsExpanded]);

  return (
    <>
      <button
        className="flex-row"
        {...getToggleProps({
          onClick: (e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
            if (props.onClickFunction) {
              props.onClickFunction();
            }
          },
        })}
        style={{
          background: "rgba(0,0,0,0.1)",
          border: "none",
          width: "100%",
          justifyContent: "space-around",
          height: "28px",
          boxSizing: "border-box",
          paddingLeft: "30px",
          paddingRight: "15px",
          marginBottom: "1px",
        }}
      >
        <div className="flex-col text-normal text-medium primary-color" 
         style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", width: "97%" }}>
          {props.title}
        </div>
        <div
          className="flex-col text-normal text-xx-large primary-color"
          style={{marginLeft: "auto", width: "3%", alignItems: "end", alignSelf: "end", padding:"1%" }}

        >
          {isExpanded ? <ExpandLess/> :<ExpandMore/>}
        </div>
      </button>
      <section
        className="flex-col"
        {...getCollapseProps({
          style: {
            background: "rgba(0,0,0,0.3)",
            width: "100%",
            height: props.contentHeight ? props.contentHeight : "100%",
            paddingTop: "2%",
            overflow: "auto",
          },
        })}
      >
        {props.content}
      </section>
    </>
  );
};
