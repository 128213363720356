import "bootstrap/dist/css/bootstrap.min.css";
import fileDownload from "js-file-download";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as HyperFlowAlert } from "../../../../Assets/alerts/hyperFlowAlerts.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import {
  changeStatusById,
  getFlowById,
  getFlowFilledFormPDF,
  selectCurrentFlow,
  selectDocumentLoadingFlow,
  selectLoadingFlowStatus,
  setIsFlowDetail,
} from "../smartFlowSlice";
import {
  getAttatchmentData,
  selectCurrentRegistration,
} from "../../coreid/coreIdSlice";
import { getRegistrationById } from "../../coreid/coreIdSlice";
import {
  BiometryImages,
  Generalmages,
} from "./../../hyperFlow/detail/components/CoreIdImages";
import { ExpandibleComponent } from "./../../hyperFlow/detail/ExpandibleComponent";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { LoadingStatus } from "../../../common/commonSlice";
import { FilledForm } from "../../magicforms/magicFormsAPI";
import { StateComponent } from "../../hyperFlow/detail/components/StateComponent";
import { ExpandibleComponentList } from "../../hyperFlow/detail/ExpandibleComponentList";
import { Attatchment, RegistrationDetail } from "../../coreid/coreIdAPI";
import { File } from "../../firma/firmaAPI";

export const Flow = () => {
  let loadingStatus = useAppSelector(selectLoadingFlowStatus);
  let documentLoading = useAppSelector(selectDocumentLoadingFlow);
  let currentFlow = useAppSelector(selectCurrentFlow);

  let currentRegistration = useAppSelector(selectCurrentRegistration);

  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const [certificado, setCertificado] = useState(false);
  const [currentSection, setCurrentSection] = useState<string>("");

  const [selectedDownload, setSelectedDownload] = useState(-1);
  const [servicealerts, setservicealerts] = useState<string[]>([]);
  const [currentSectionCoreId, setCurrentSectionCoreId] = useState<
    | "DOCUMENTO DE IDENTIDAD"
    | "BIOMETRÍA"
    | "ESTADO DEL REGISTRO"
    | "MATRIZ DE VERIFICACIÓN"
  >("DOCUMENTO DE IDENTIDAD");
  const [currentSectionMagic, setCurrentSectionMagic] = useState<
    "FORMULARIO" | "CAMPOS"
  >("FORMULARIO");
  const [currentSectionFirma, setCurrentSectionFirma] = useState<
    "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES"
  >("DOCUMENTOS");

  //------------------------------GET ROW INFO CLIENT------------------------------------------

  let getRowInfoClient = (name: String, value: String) => {
    return (
      <div className="flex-row">
        <div className="flex-col" style={{ width: "50%" }}>
          {name}
        </div>
        <div className="flex-col" style={{ width: "50%" }}>
          {value}
        </div>
      </div>
    );
  };

  //-----------------------------GET CURRENT SECTION CONTENT----------------------------------------

  let getCurrentSectionContent = (name: String) => {
    switch (name) {
      case "CORE_ID":
        return getCurrentContentCoreId();
      case "MAGIC_FORMS":
        return getCurrentContentMagic();
      case "ELECTRONIC_SIGNATURE":
        return getCurrentContentFirma();
    }
  };

  //-------------------------------GET DOCUMENT SQUARE(MAGIC)------------------------------------------
  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm
  ) => {
    if (filledForm) {
      switch (loadingPDFStatus) {
        case "idle":
          return (
            <div className="primary-color text-xx-large text-bold">
              Formulario lleno
            </div>
          );
        case "pending":
          return (
            <LoadingOverlay
              active={loadingPDFStatus === "pending"}
              spinner={<ClipLoader color="white" size="100px" />}
            ></LoadingOverlay>
          );
        case "rejected":
          return (
            <div className="primary-color text-xx-large text-bold">
              Hubo un problema cargando del documento
            </div>
          );
        case "resolved":
          return (
            <object
              data={`data:application/pdf;base64,${filledForm.document}`}
              type="application/pdf"
              width="100%"
              height="350px"
            ></object>
          );
        default:
          return (
            <div className="primary-color text-xx-large text-bold">
              Formulario lleno
            </div>
          );
      }
    } else {
      return (
        <div className="primary-color text-xx-large text-bold">
          No se ha llenado el formulario
        </div>
      );
    }
  };

  let { id } = useParams();
  useEffect(() => {
    if ((!currentRegistration || currentRegistration.id !== id) && id !== "") {
      dispatch(getRegistrationById(id || ""));
    }
  }, [currentRegistration, id]);

  useEffect(() => {
    if ((!currentFlow || currentFlow.id !== id) && id !== "") {
      dispatch(getFlowById(id || ""));
    }
    if (currentFlow && currentFlow.filledFormId && documentLoading === "idle") {
      dispatch(getFlowFilledFormPDF(currentFlow.filledFormId));
    }

    dispatch(setIsFlowDetail(true));

    return () => {};
  }, [id, currentFlow, documentLoading]);

  //-----------------------------GET DETAIL DATA------------------------------------------
  let getDetailData = (value: string) => {
    return (
      <div
        className="flex-col"
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className="text-medium text-bold primary-color-30"
          style={{ width: "145px", textAlign: "center", fontSize: "x-small" }}
        >
          Scope Op. 1
        </div>
        <div className="text-huge text-bold primary-color">{value}</div>
      </div>
    );
  };

  //-----------------------------TEXT_VALUE------------------------------------------
  let getTextValue = (name: String, value: String, alert: boolean) => {
    return (
      <>
        <div className="flex-col">
          <div className="flex-col text-small primary-color-40">{name}</div>
          <div
            className={`flex-row text-small ${
              alert ? "text-bold alert-color" : "primary-color"
            }`}
          >
            <div className="col-2">{alert ? <HyperFlowAlert /> : ""}</div>
            <div className="col-10">{value}</div>
          </div>
        </div>
      </>
    );
  };
  //-------------------------------GET ALERT------------------------------------------
  let getAlert = (name: String) => {
    switch (name) {
      case "CAMPOS":
        return servicealerts.includes("MagicForms");
      case "DOCUMENTO DE IDENTIDAD":
        return servicealerts.includes("coreId");
    }

    return false;
  };

  //-------------------------------GET MENU ITEM CORE_ID------------------------------------------
  let getMenuItemCoreId = (
    name:
      | "DOCUMENTO DE IDENTIDAD"
      | "BIOMETRÍA"
      | "ESTADO DEL REGISTRO"
      | "MATRIZ DE VERIFICACIÓN",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionCoreId(name);
        }}
        style={{
          background:
            currentSectionCoreId === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionCoreId) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET MENU ITEM MAGIC------------------------------------------
  let getMenuItemMagic = (
    name: "FORMULARIO" | "CAMPOS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionMagic(name);
        }}
        style={{
          background:
            currentSectionMagic === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionMagic) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET MENU ITEM FIRMAS------------------------------------------
  let getMenuItemFirmas = (
    name: "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionFirma(name);
        }}
        style={{
          background:
            currentSectionFirma === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionFirma) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  //-------------------------------GET CURRENT SECTION MENU------------------------------------------
  let getCurrentSectionMenu = (name: String) => {
    switch (name) {
      case "CORE_ID":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "100%" }}
          >
            {getMenuItemCoreId("DOCUMENTO DE IDENTIDAD", true, false)}
            {getMenuItemCoreId("BIOMETRÍA", false, false)}
            {getMenuItemCoreId("ESTADO DEL REGISTRO", false, false)}
            {getMenuItemCoreId("MATRIZ DE VERIFICACIÓN", false, true)}
          </div>
        );
      case "MAGIC_FORMS":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemMagic("FORMULARIO", true, false)}
            {getMenuItemMagic("CAMPOS", false, false)}
          </div>
        );
      case "ELECTRONIC_SIGNATURE":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemFirmas("DOCUMENTOS", true, false)}
            {getMenuItemFirmas("FIRMANTES", false, false)}
            {getMenuItemFirmas("HISTORIAL DE ACCIONES", false, true)}
          </div>
        );
      case "Adjuntos":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          ></div>
        );
    }
  };

  let getDocumentSign = (loadingPDFStatus: LoadingStatus, sign: File) => {
    switch (loadingPDFStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={`data:application/pdf;base64,${sign.documentBase64}`}
            type="application/pdf"
            width="100%"
            height="320vh"
          ></object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">Firma</div>
        );
    }
  };
  //-------------------------------GET CURRENT CONTENT CORE_ID------------------------------------------
  let getCurrentContentCoreId = () => {
    switch (currentSectionCoreId) {
      case "DOCUMENTO DE IDENTIDAD":
        return (
          currentFlow?.registration && (
            <div className="flex-row content-table">
              <div
                className="col-4 data-colum"
                style={{ padding: "20px", paddingBottom: "10px" }}
              >
                {getTextValue(
                  "Fecha de nacimiento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.birthDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Número de documento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.documentNumber
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Fecha de expedición",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.expeditionDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Nombre y apellido",
                  currentFlow?.registration
                    ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                    : "No encontrado",
                  currentFlow.registration.multiFactorMatrix
                    ? currentFlow.registration.multiFactorMatrix?.veracity
                        .alerts.length > 0
                    : false
                )}
                {getTextValue(
                  "Lugar de nacimiento",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.birthPlace
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Sexo",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.gender
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Tipo de sange (RH)",
                  currentFlow?.registration.userDocument
                    ? currentFlow?.registration.userDocument.bloodType
                    : "No encontrado",
                  false
                )}
              </div>
              <div className="col-4 data-colum">
                <div
                  className="text-bold text-medium primary-color"
                  style={{ textAlign: "center" }}
                >
                  Documento
                  <br />
                </div>
                {currentFlow?.registration && (
                  <Generalmages
                    currentRegistration={currentFlow?.registration}
                    loadingImages={loadingStatus}
                  />
                )}

                <div
                  className="text-medium text-normal primary-color"
                  style={{ padding: "10px", textAlign: "center" }}
                >
                  {`OCR: ${
                    currentFlow?.registration.unifiedChecks.ocrCheck.active
                      ? currentFlow?.registration.unifiedChecks.ocrCheck.result
                        ? "OK"
                        : "NO OK"
                      : "Desactivado"
                  }`}
                </div>
              </div>
              <div className="col-4 data-colum" style={{ padding: "10px" }}>
                {currentFlow?.registration.unifiedChecks.documentValidityCheck
                  .active ? (
                  <ExpandibleComponent
                    alert={false}
                    description={
                      currentFlow?.registration.unifiedChecks
                        .documentValidityCheck.description
                    }
                    isActive={false}
                    detail={""}
                    title="Autenticidad de documento"
                    content={
                      <div className="flex-row">
                        <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                          {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.veracity.blockingThreshold}`}
                        </div>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks
                            .documentValidityCheck.active
                            ? currentFlow?.registration.multiFactorMatrix
                              ? `${(currentFlow?.registration.multiFactorMatrix.veracity.score).toFixed(
                                  1
                                )}%`
                              : ""
                            : "Desactivado"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}

                {currentFlow?.registration.unifiedChecks.documentMatchCheck
                  .active ? (
                  <ExpandibleComponent
                    alert={false}
                    description={
                      currentFlow?.registration.unifiedChecks.documentMatchCheck
                        .description
                    }
                    isActive={false}
                    detail={""}
                    title="Match Datos Iniciales"
                    content={
                      <div className="flex-row">
                        <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                          {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.initialDataMatch.blockingThreshold}`}
                        </div>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks
                            .documentMatchCheck.active
                            ? `${currentFlow?.registration.faceMatchPercent.toFixed(
                                1
                              )}%`
                            : "Desactivado"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
                {currentFlow?.registration.unifiedChecks
                  .governmentDataMatchCheck.active ? (
                  <ExpandibleComponentList
                    alert={
                      currentFlow.registration.multiFactorMatrix
                        ? currentFlow.registration.multiFactorMatrix?.veracity
                            .alerts.length > 0 ||
                          currentFlow.registration.multiFactorMatrix?.govEntity
                            .alerts.length > 0
                        : false
                    }
                    alerts={
                      currentFlow.registration.multiFactorMatrix
                        ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.concat(
                            currentFlow.registration.multiFactorMatrix
                              ?.govEntity.alerts
                          )
                        : []
                    }
                    isActive={false}
                    title="Match Gubernamental"
                    content={
                      <div className="flex-row">
                        <div className="flex-col text-medium text-normal primary-color-35 pdd-left">
                          {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.govEntity.blockingThreshold}`}
                        </div>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks
                            .governmentDataMatchCheck.active
                            ? currentFlow?.registration.multiFactorMatrix
                              ? `${(currentFlow?.registration.multiFactorMatrix.govEntity.score).toFixed(
                                  1
                                )}%`
                              : ""
                            : "Desactivado"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        );
      case "BIOMETRÍA":
        return currentFlow?.registration ? (
          <div
            className="flex-row content-table"
            style={{ width: "80%", margin: "10px" }}
          >
            <div className="col-6">
              {currentFlow?.registration ? (
                <BiometryImages
                  currentRegistration={currentFlow?.registration}
                  loadingImages={loadingStatus}
                />
              ) : (
                <div />
              )}
            </div>
            <div className="col-6" style={{ padding: "2%", marginLeft: "10%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.documentValidityCheck
                    .description
                }
                isActive={false}
                detail={""}
                title="FACE MATCH"
                content={
                  <div className="flex-col">
                    <div className="flex-row">
                      <div className="flex-col" style={{ width: "50%" }}>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks.facematchCheck
                            .active
                            ? `${currentFlow?.registration.faceMatchPercent.toFixed(
                                1
                              )}%`
                            : "Desactivado"
                        )}
                      </div>
                      <div className="flex-col" style={{ width: "50%" }}>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks.facematchCheck
                            .active
                            ? `${currentFlow?.registration.faceMatchPercent.toFixed(
                                1
                              )}%`
                            : "Desactivado"
                        )}
                      </div>
                    </div>
                    <br />
                    <div
                      className="flex-col text-medium primary-color-35 pdd-left"
                      style={{
                        textAlign: "center",
                        justifySelf: "center",
                        padding: "1%",
                      }}
                    >
                      {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.faceMatch.blockingThreshold}`}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );

      case "ESTADO DEL REGISTRO":
        return currentFlow?.registration ? (
          <div className="flex-row content-table" style={{ width: "100%" }}>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.listsCheck.description
                }
                isActive={false}
                detail={""}
                title="Listas"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.listsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.gpsCheck.description
                }
                isActive={false}
                detail={""}
                title="GPS"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.gpsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.deviceIdCheck
                    .description
                }
                isActive={false}
                detail={""}
                title="Device ID"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.deviceIdCheck
                      .result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case "MATRIZ DE VERIFICACIÓN":
        return (
          currentFlow?.registration && (
            <div
              className="flex-row content-table text-normal text-small"
              style={{ width: "100%" }}
            >
              <div className="flex-col" style={{ width: "30%" }}>
                <div
                  className="flex-col primary-color"
                  style={{
                    width: "70%",
                    borderRadius: "8px",
                    background: "rgba(0,0,0,0.3)",
                    padding: "3%",
                    marginLeft: "10px",
                    marginTop: "10%",
                  }}
                >
                  <div className="flex-row" style={{ paddingLeft: "20px" }}>
                    {`Umbral: ${currentFlow.registration.multiFactorMatrix?.veracity.blockingThreshold}`}
                  </div>
                  <div className="flex-row" style={{ paddingLeft: "20px" }}>
                    {`Score Total: ${currentFlow.registration.multiFactorMatrix?.finalScore}`}
                  </div>
                </div>

                <table
                  style={{
                    width: "90%",
                    borderCollapse: "collapse",
                    margin: "10px",
                    overflow: "hidden",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <thead
                    className="text-normal text-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OCR"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.ocrCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OTP"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.otpCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Correo"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.emailCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>
                        {"Dispositivos registrados"}
                      </td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.deviceIdCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Localización"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.gpsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Listas"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.listsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex-col" style={{ width: "70%", padding: "2%" }}>
                <table
                  style={{
                    width: "80%",
                    marginLeft: "10%",
                    borderCollapse: "collapse",
                    overflow: "hidden",

                    borderRadius: "12px",
                    padding: "10px",
                  }}
                >
                  <thead
                    className="text-normal text-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th className="p-1">Factor</th>
                      <th className="p-1">Score (0-100)</th>
                      <th className="p-1">Umbral de Bloqueo</th>
                      <th className="p-1">Admisión</th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color-30`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr className="p-1">
                      <td>{"Face match"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.faceMatch
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Validez-Autenticidad"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.validity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Veracidad documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.veracity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Listas"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.lists
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>

                    <tr className="p-1">
                      <td>{"GPS(Geofencin)"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.geolocation.passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Device ID"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.deviceId
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Match datos iniciales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.initialDataMatch.passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                    <tr className="p-1">
                      <td>{"Alertas entidades gubernamentales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.govEntity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  //-------------------------------GET CURRENT CONTENT MAGIC FORMS------------------------------------------
  let getCurrentContentMagic = () => {
    switch (currentSectionMagic) {
      case "FORMULARIO":
        return (
          currentFlow &&
          currentFlow?.filledForm && (
            <div className="flex-row content-table">
              <div
                className="flex-col"
                style={{ width: "30%", paddingLeft: "20px" }}
              >
                <div className="text-normal text-small primary-color-35">
                  {` Nombre: ${
                    currentFlow && currentFlow?.filledForm
                      ? currentFlow?.filledForm.pdfName
                      : ""
                  }`}
                </div>
                <div className="text-normal text-small primary-color-35">
                  {` ID Documento: ${
                    currentFlow && currentFlow?.filledForm
                      ? currentFlow?.filledForm.id
                      : ""
                  }`}
                </div>
                <div className="text-normal text-small primary-color-35">
                  {` Descripción: ${
                    currentFlow && currentFlow?.filledForm
                      ? currentFlow?.filledForm.formTemplateId
                      : ""
                  }`}
                </div>
                <div style={{ marginTop: "auto", padding: "1%" }}>
                  <button
                    className="flex-row text-small primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (
                        currentFlow &&
                        currentFlow?.filledForm &&
                        currentFlow?.filledForm.document
                      ) {
                        let url = `data:application/pdf;base64,${currentFlow?.filledForm.document}`;

                        fetch(url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              currentFlow?.filledForm?.pdfName
                                ? currentFlow?.filledForm?.pdfName
                                : "Document.pdf"
                            );
                          });
                      }
                    }}
                  >
                    {"Descargar "}
                    <div style={{ padding: "2%" }}>
                      <DownloadIcon style={{ margin: "4px" }} />
                    </div>
                  </button>
                </div>
              </div>

              <div className="flex-col" style={{ width: "70%" }}>
                {getDocument(loadingStatus, currentFlow?.filledForm)}
              </div>
            </div>
          )
        );
      case "CAMPOS":
        return (
          currentFlow &&
          currentFlow?.filledForm && (
            <div
              className="flex-col primary-color-35"
              style={{ width: "100%", height: "350px", overflow: "auto" }}
            >
              <table
                style={{
                  width: "90%",
                  borderCollapse: "collapse",
                  margin: "5%",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Información Solicitada</th>
                    <th>Respuesta</th>
                    <th>Tipo de Campo</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  {currentFlow?.filledForm.requirements.map((e) => {
                    return (
                      <tr className="list-hover">
                        <td>{e.source}</td>
                        <td>{e.name}</td>
                        <td>{e.stitchTypeId}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );
      default:
        return <></>;
    }
  };
  //--------------------------------GET CURRENT CONTENT ELECTRONIC_SIGNATURE-----------------------
  let getCurrentContentFirma = () => {
    switch (currentSectionFirma) {
      case "DOCUMENTOS":
        return (
          currentFlow?.signaturedata && (
            <div className="flex-row content-table">
              <div
                className="flex-col"
                style={{ width: "30%", paddingLeft: "20px" }}
              >
                <div className="text-normal text-small primary-color-35">
                  {` ID Documento: ${currentFlow?.signaturedata?.documentId}`}
                </div>
                <div className="text-normal text-small primary-color-35">
                  {` Descripción: ${currentFlow?.signaturedata.status}`}
                </div>
                <div style={{ marginTop: "2%", padding: "1% 0% 0% 22%" }}>
                  <button
                    className="flex-row text-small primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCertificado(false);
                    }}
                  >
                    {"Documento Firmado"}
                  </button>
                </div>
                <div style={{ marginTop: "auto", padding: "1% 0% 0% 22%" }}>
                  <button
                    className="flex-row text-small primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCertificado(true);
                    }}
                  >
                    {"Certificado de Firma"}
                  </button>
                </div>

                <div style={{ marginTop: "15%", padding: "1%" }}>
                  <button
                    className="flex-row text-small primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {"Descargar "}
                    <div style={{ padding: "2%" }}>
                      <DownloadIcon
                        style={{ margin: "4px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            currentFlow?.signaturedata &&
                            currentFlow?.signaturedata.documents &&
                            currentFlow?.signaturedata.documents
                              .finishedSignature
                          ) {
                            let url = `data:application/pdf;base64,${
                              certificado
                                ? currentFlow?.signaturedata.documents
                                    ?.certificate.documentBase64
                                : currentFlow?.signaturedata.documents?.document
                                    .documentBase64
                            }`;

                            if (certificado) {
                              fetch(url)
                                .then((res) => res.blob())
                                .then((blob) => {
                                  fileDownload(blob, "Certificado.pdf");
                                });
                            } else {
                              fetch(url)
                                .then((res) => res.blob())
                                .then((blob) => {
                                  fileDownload(
                                    blob,
                                    currentFlow?.signaturedata?.documents
                                      ?.document.documentName!!
                                  );
                                });
                            }
                          }
                        }}
                      />
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex-col" style={{ width: "70%" }}>
                {currentFlow?.signaturedata &&
                  currentFlow?.signaturedata.documents &&
                  currentFlow?.signaturedata.documents.finishedSignature &&
                  getDocumentSign(
                    loadingStatus,
                    certificado
                      ? currentFlow.signaturedata.documents?.certificate
                      : currentFlow.signaturedata.documents?.document
                  )}
              </div>
            </div>
          )
        );
      case "FIRMANTES":
        return (
          currentFlow &&
          currentFlow?.signaturedata && (
            <div
              className="flex-col primary-color-35"
              style={{ width: "100%" }}
            >
              <table
                style={{
                  width: "90%",
                  borderCollapse: "collapse",
                  margin: "5%",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Nombre y Apellido</th>
                    <th>Fecha de la Firma</th>
                    <th>User ID</th>
                    <th>Estado</th>
                    <th>Método</th>
                    <th>Alerta</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  {currentFlow?.signaturedata.signers.map((e) => {
                    return (
                      <tr className="list-hover">
                        <td>{`${e.name} ${e.lastName}`}</td>
                        <td>{e.startedDate}</td>
                        <td>{e.userId}</td>
                        <td>{e.status}</td>
                        <td>{e.validationMethod}</td>
                        <td>{e.numAlerts}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );
      case "HISTORIAL DE ACCIONES":
        return (
          currentFlow &&
          currentFlow?.signaturedata && (
            <div
              className="flex-col primary-color-35"
              style={{ width: "100%" }}
            >
              <div
                className="text-normal text-medium primary-color"
                style={{ alignSelf: "center" }}
              >
                Historial del Documento
              </div>
              <table
                style={{
                  width: "90%",
                  borderCollapse: "collapse",
                  margin: "5%",
                }}
              >
                <thead
                  className="text-normal text-medium primary-color"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  {currentFlow?.signaturedata.history &&
                    currentFlow?.signaturedata.history.map((e) => {
                      return (
                        <tr className="list-hover">
                          <td>{e.action}</td>
                          <td>{e.date}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="100px" />}
    >
      {!currentFlow || currentFlow.flowId !== "" ? (
        <div
          className="flex-col"
          style={{
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="flex-row"
            style={{
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <LeftArrow style={{ marginRight: "15px" }} />
            <div className="text-bold text-x-large primary-color">
              Regresar a lista de verificaciones
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              padding: "10px",
              paddingLeft: "50px",
            }}
          >
            <div className="text-bold text-x-large primary-color">
              Info Cliente
            </div>
          </div>
          <div className="flex-row">
            <div
              className="text-medium primary-color col-3"
              style={{
                background: "rgba(0,0,0,0.4)",
                width: "50%",
                padding: "1%",
                paddingLeft: "50px",
              }}
            >
              {getRowInfoClient(
                "Nombre y apellido:",
                currentFlow?.registration && currentFlow.registration.name
                  ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                  : ""
              )}
              {getRowInfoClient(
                "ID de registro:",
                currentFlow?.registration && currentFlow.registration.id
                  ? currentFlow.registration.id
                  : ""
              )}
              {getRowInfoClient(
                "Email:",
                currentFlow?.registration && currentFlow.registration.email
                  ? currentFlow.registration.email
                  : ""
              )}
              {getRowInfoClient(
                "Nº Celular:",
                currentFlow?.registration && currentFlow.registration.phone
                  ? currentFlow.registration.phone
                  : ""
              )}
              {getRowInfoClient(
                "Fecha de registro:",
                currentFlow?.registration &&
                  currentFlow.registration.startDatetime
                  ? currentFlow.registration.startDatetime
                  : ""
              )}
            </div>
            <div
              className="col"
              style={{ margin: "10px", padding: "0% 1% 0% 15%" }}
            >
              <StateComponent
                eventChangeState={(e: string) => {
                  dispatch(changeStatusById(e));
                }}
                state={currentFlow ? currentFlow.status : ""}
                updateRe={id === "flowsrevision" ? "" : id ? id : ""}
              />
            </div>
          </div>
          <div className="flex-row" style={{ height: "62%" }}>
            <div
              className="col"
              style={{ height: "100%", width: "100%", margin: " 2% 2% 0% 2%" }}
            >
              {getCurrentSectionMenu(currentSection ? currentSection : "")}
            </div>

            <div
              className="col-3"
              style={{ width: "75%", margin: " 2% 2% 0% 0%" }}
            >
              <ExpandibleContent
                title="Core ID"
                isActive={currentSection === "CORE_ID"}
                onClickFunction={() => {
                  setCurrentSection("CORE_ID");
                }}
                content={
                  <div>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className="flex-col text-normal text-medium primary-color"
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "5%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Este paso aún no se ha completado
                      </div>
                    ) : (
                      getCurrentSectionContent("CORE_ID")
                    )}
                  </div>
                }
                contentHeight={"auto"}
              />

              <ExpandibleContent
                title="Magic Forms"
                isActive={currentSection === "MAGIC_FORMS"}
                onClickFunction={() => {
                  setCurrentSection("MAGIC_FORMS");
                }}
                contentHeight={
                  currentSectionMagic === "CAMPOS" ? "80%" : "auto"
                }
                content={
                  <div>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className="flex-col text-normal text-medium primary-color"
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          paddingBottom: "5%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Este paso aún no se ha completado
                      </div>
                    ) : (
                      <div> {getCurrentSectionContent("MAGIC_FORMS")}</div>
                    )}
                  </div>
                }
              />
              <ExpandibleContent
                title="Firma"
                isActive={currentSection === "ELECTRONIC_SIGNATURE"}
                onClickFunction={() => {
                  setCurrentSection("ELECTRONIC_SIGNATURE");
                }}
                contentHeight={"auto"}
                content={
                  <div>
                    {!currentFlow?.filledFormId ? (
                      <div
                        className="flex-col text-normal text-medium primary-color"
                        style={{
                          paddingTop: "10px",
                          margin: "15px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        El paso de firma electrónica aún no se ha completado
                      </div>
                    ) : (
                      <div>
                        {" "}
                        {getCurrentSectionContent("ELECTRONIC_SIGNATURE")}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "idle" ? "" : "No se encontró el flujo"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
