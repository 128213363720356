import axiosApiInstance from "../../../helpers/interceptor";
import {  format } from 'date-fns';

let filteredAlertsMethod = "alerts/getFiltered";
let getFilterByMethod = "alerts/getByItemId";
let getNumAlerts = "alerts/numAlerts";

let getUnifiedAlertsFilteredAPI = async (
  params: UnifiedAlertsFilteredRequest
): Promise<{ error: string; alerts: UnifiedAlertsFiltered | null }> => {

  var data_params=params;
  data_params.endDate= data_params.endDate?format(new Date(data_params.endDate), 'dd-MM-yyyy'):null;
  data_params.startDate= data_params.startDate?format(new Date(data_params.startDate), 'dd-MM-yyyy'):null;


  let unifiedAlerts = await axiosApiInstance.post(
    `/${filteredAlertsMethod}`,
    data_params
  );

  if (unifiedAlerts.status !== 200) {
    return { error: "error", alerts: null };
  } else {
    return { error: "", alerts: unifiedAlerts.data };
  }
};

let getAlertsByItemIdAPI = async (
  itemId: string
): Promise<{ error: string; alerts: UnifiedAlertsFiltered | null }> => {
  let unifiedAlerts = await axiosApiInstance.get(
    `/${getFilterByMethod}/${itemId}`
  );

  if (unifiedAlerts.status !== 200) {
    return { error: "error", alerts: null };
  } else {
    return { error: "", alerts: unifiedAlerts.data };
  }
};
let getNumberAlerts = async (
): Promise<{ error: string; alerts: NumAlerts | null }> => {
  let unifiedAlerts = await axiosApiInstance.get(
    `/${getNumAlerts}`
  );

  if (unifiedAlerts.status !== 200) {
    return { error: "error", alerts: null };
  } else {
    return { error: "", alerts: unifiedAlerts.data };
  }
};
export interface NumAlerts {
  numAlerts: number | null;
}

export interface UnifiedAlertsFilteredRequest {
  page: number;
  type: string | null;
  service: string | null;
  startDate: string | null;
  endDate: string | null;
}

export interface UnifiedAlertsFiltered {
  numPages: number;
  currentPage: number;
  alerts: Array<UnifiedAlerts>;
}
export interface UnifiedAlerts {
  id: string;
  userId: string;
  clientId: string;
  type: string;
  resolved: boolean;
  service: string;
  dateTime: string;
  description: string;
  itemId: string;
  details: Array<AlertDetail>;
}

export interface AlertDetail {
  title: string;
  description: string;
}

export { getUnifiedAlertsFilteredAPI, getAlertsByItemIdAPI, getNumberAlerts };
