import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ProductTitleText,
  SecondaryProductTitle,
  TerciaryProductTitle,
} from "../common/components/texts/ProductTitles";
import "./loginStyles.scss";
import {
  selectPassword,
  selectUsername,
  changePassword,
  changeUsername,
  tryLogin,
  selectLoginLoading,
  selectTokenString,
  selectLoginErrorMessage,
  emptyErrorMessage,
  selectEmail,
  changeEmail,
  changeEmailRecovery,
  selectEmailRecovery,
  recovery,
} from "./loginSlice";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { addHours } from "date-fns";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

const Login = () => {

  const [, setCookie] = useCookies(["token"]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContraIsOpen, setIsContraOpen] = useState(false);

  const navigate = useNavigate();
  // Variables
  let username = useAppSelector(selectUsername);
  let password = useAppSelector(selectPassword);
  let loading = useAppSelector(selectLoginLoading);
  let tokenString = useAppSelector(selectTokenString);
  let errorMessage = useAppSelector(selectLoginErrorMessage);
  let email = useAppSelector(selectEmail);
  let emailRecovery = useAppSelector(selectEmailRecovery);
  // Dispatcher
  let dispatch = useAppDispatch();
  // functions
  let usernameChange = (inputText: string) => {
    dispatch(changeUsername(inputText));
  };

  let passwordChange = (inputText: string) => {
    dispatch(changePassword(inputText));
  };

  let login = () => {
    dispatch(tryLogin());
  };
  let recoveryEmail = () => {
    dispatch(recovery());
    
  };

  
  const closeModal = () => {
    setIsOpen(false);
    dispatch(emptyErrorMessage());
  };
  const listProducts =[
    "HyperFlow",
    "Core ID",
    "Firma electrónica",
    "Smart Contracts",
    "Biometrix Pay",
    "Authenticator"
  ]

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      //Save cookie
      let now = new Date();
      let inAnHour = addHours(now, 1);
      setCookie(
        "token",
        { token: tokenString, expiry: inAnHour.getTime() },
        {
          path: "/",
          expires: inAnHour,
          sameSite: "lax",
          secure: true,
        }
      );
      navigate("/intro");
    }
    if (errorMessage !== "") {
      setIsOpen(true);
    }
  }, [tokenString, navigate, setCookie, errorMessage]);

  return (
    <LoadingOverlay
      active={loading}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de login"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{ color: "black", marginBottom: "10px", paddingLeft: "25%" , paddingRight: "25%"}}
          >
            {errorMessage}
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Intente nuevamente
          </button>
        </div>
      </Modal>
      <div className="flex-row">
        <div className="flex-col title-section">
          <div className="titles-section-list">
          {
            listProducts.map((e,ind)=>{
              return <ProductTitleText text={e} order={5-ind} multiple={true} list={"list"}  />

            })
          }
            <ProductTitleText text="back" bootom={"bottom"} list="titles-section-list-back" />
            
            
          </div>
        </div>
        <div className="flex-col login-section">
          <div className="titles-section-text-left">
          <TerciaryProductTitle />
          </div>
          <div className="titles-section-center">
            <SecondaryProductTitle />
            </div>
         
            <div className="text-normal text-xxx-small primary-color-40 paragraphdescription ">
              <p>
              <b>anteia</b> ha creado la primera plataforma que automatiza de extremo a extremo procesos de vinculación, 
              registros y ventas, teniendo como cerebro la identidad digital avanzada y un sólido modelo de ciberseguridad
              </p>
            </div>

          {
            modalContraIsOpen&&
              <div
              className="flex-col login-section-input"
              style={{
              }}
            >
              <div className="flex-row email-label-div">
                <label className="text-normal text-small primary-color email-label">
                Ingrese su correo electrónico para recuperar la contraseña
                </label>
              </div>

              <input
                type="email"
                value={emailRecovery}
                
                disabled={loading}
                onChange={(e) => {
                  dispatch(changeEmailRecovery(e.target.value));
                }}
                className="text-normal text-small secondary-color-80 email-input"
              />
              <div className="button-email-div">
                <button
                  className="text-bold text-medium primary-color email-button"
                  onClick={recoveryEmail}
                  disabled={loading}
                >
                  ENVIAR
                </button>
              </div>
            </div>
            
          }


          <div className="login-section-form">
            <div className="flex-col login-section-input">
              <div className="flex-col login-label-div">
                <label className="text-normal text-small primary-color login-label">
                Usuario
                </label>
              </div>

              <input
                type="email"
                value={username}
                disabled={loading}
                onChange={(e) => {
                  usernameChange(e.target.value);
                }}
                className="text-normal text-small primary-color-80 login-input"
              />
            </div>
            <div
              className="flex-col login-section-input"
              style={{
                marginTop: "8%",
              }}
            >
              <div className="flex-row login-label-div">
                <label className="text-normal text-small login-label-password primary-color-30"
                onClick={(e)=>{
                  setIsContraOpen(!modalContraIsOpen);
                }
                }
                >
                ¿Olvidaste tu contraseña?
                </label>

                <label className="text-normal text-small primary-color login-label">
                  Contraseña
                </label>
              </div>

              <input
                type="password"
                value={password}
                disabled={loading}
                onChange={(e) => {
                  passwordChange(e.target.value);
                }}
                className="text-normal text-small primary-color-80 login-input"
              />
              <div className="login-button-div">
                <button
                  className="text-bold text-medium primary-color login-button"
                  onClick={login}
                  disabled={loading}
                >
                  INGRESAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Login;
