import { ActionMeta, SingleValue } from "react-select";
import Select from "react-select";

export interface DropDownProps {
  options: { value: string; label: string }[];
  title: string;
  invisible?: Boolean;
  defaultValue?: { value: string; label: string };
  onChange: (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => void;
}
export const DropDown = (props: DropDownProps) => {
  return (
    <div
      className="flex-col"
      style={{
        width: "100%",
        alignItems: "start",
        justifyContent: "space-evenly",
        marginTop: "5px",
        marginBottom: "10px",
      }}
    >
      <div
        className="text-normal text-medium primary-color"
        style={{ width: "min-content", marginLeft: "5px", marginBottom: "5px" }}
      >
        {props.title}
      </div>
      <Select
        maxMenuHeight={100}
        styles={{
          control: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
            color: "white",
            marginLeft: "5px",
            marginRight: "5px",
            width: "100%",
          }),
          menu: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
          }),
          menuList: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.5)",
            borderColor: "rgba(0,0,0,0.5)",
            color: "white",
            width: "180%",
            height: "150%",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white",
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.5)",
            "&:hover": {
              background: "rgba(0,0,0,0.8)",
            },
          }),
        }}
        options={props.options}
        onChange={props.onChange}
        defaultValue={
          props.defaultValue
            ? props.defaultValue
            : { label: "Seleccione", value: "" }
        }
      />
    </div>
  );
};

export const DropDownPagare = (props: DropDownProps) => {
  return (
    <div
      className="flex-col"
      style={{
        width: "100%",
        alignItems: "start",
        justifyContent: "space-evenly",
        marginTop: "5px",
        marginBottom: "10px",
        visibility: props.invisible ? "hidden" : "visible",
      }}
    >
      <div
        style={{
          marginLeft: "5px",
          marginBottom: "5px",
          fontSize: "12px",
          color: "white",
          fontWeight: "600",
        }}
      >
        {props.title}
      </div>
      <Select
        maxMenuHeight={100}
        styles={{
          control: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
            color: "white",
            marginLeft: "5px",
            marginRight: "5px",
            width: "155px",
          }),
          menu: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
          }),
          menuList: (base) => ({
            ...base,
            background: "rgba(0,0,0,0.2)",
            borderColor: "rgba(0,0,0,0.2)",
            color: "white",
            width: "155px",
            height: "100%",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "white",
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.2)",
            "&:hover": {
              background: "rgba(0,0,0,0.6)",
            },
          }),
        }}
        options={props.options}
        onChange={props.onChange}
        defaultValue={
          props.defaultValue
            ? props.defaultValue
            : { label: "Seleccione", value: "" }
        }
      />
    </div>
  );
};
