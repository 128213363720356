import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct } from "../../common/commonSlice";
import { FooterServices } from "../../common/components/texts/FooterServices";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { ReactComponent as Score } from "../../../Assets/common/images/score.svg";
import LoadingOverlay from "react-loading-overlay-ts";

import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import {
  changeCurrentFilters,
  changeCurrentFlowStatus,
  changeStatusById,
  getFilterOptions,
  selectCurrentFilterRequest,
  selectCurrentFlow,
  selectCurrentFlowStatus,
  selectFilteredOptions,
  selectIsFlowDetail,
  selectLoadingFlowStatus,
} from "./hyperFlowSlice";
import { StatusChangeSidePanel } from "../components/sidePanel/StatusChangeSidePanel";
import { StatusChangeRadio } from "../components/sidePanel/StatusChangeOptions/StatusChangeRadio";
import { getServiceName, getServicePath, getServicePathData, getStatusFlow } from "../../../helpers/utils";
import { CategoriesServicesComponent } from "../components/sidePanel/categories/CategoriesServicesComponent";
import { Badge } from "@material-ui/core";
import {  makeStyles, createStyles } from '@material-ui/core/styles';
import { ClipLoader } from "react-spinners";


export const useStyles = makeStyles(() =>
createStyles({
  customBadge: {
    backgroundColor: "transparent",
    color: "white",
    fontSize: "30px"
  },
}));
export const HyperFlowDetail = () => {
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let isFlowDetail = useAppSelector(selectIsFlowDetail);
  let loadingStatus = useAppSelector(selectLoadingFlowStatus);
  let currentFlow = useAppSelector(selectCurrentFlow);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const classes = useStyles();

  let getCurrentStatus = (
    statusConst: "APPROVED" | "PENDING" | "IN_PROGRESS" | "REJECTED" | string
  ) => {
    switch (statusConst) {
      case "APPROVED":
        return "Aprobado";
      case "IN_PROGRESS":
        return "En Progreso";
      case "PENDING":
        return "PENDIENTE";
      case "REJECTED":
        return "Rechazado";
      default:
        return "En Progreso";
    }
  };

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    let searchInput = (
      <SearchInputFilter
        key={"SearchRegistrations"}
        onChange={(e) => {
          if (!e.target.value || e.target.value === "") {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: null,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                searchQuery: e.target.value,
              })
            );
          }
        }}
        style={{ marginTop: "5px", width: "80%", alignSelf: "center" }}
        placeHolder="No. de documento"
      />
    );
    return (
      <>
        <FilterComponent
          key={0}
          hasCalendar={true}
          endDate={
            currentFilteredRequest.endDate
              ? new Date(currentFilteredRequest.endDate)
              : null
          }
          startDate={
            currentFilteredRequest.startDate
              ? new Date(currentFilteredRequest.startDate)
              : null
          }
          setEndDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          setStartDate={(date: Date) => {
            if (date) {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }
          }}
          resetFunction={() => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                endDate: null,
                startDate: null,
              })
            );
          }}
          items={[searchInput]}
        />
      </>
    );
  };

  useEffect(() => {
    if (!filterOptions) {
      dispatch(getFilterOptions());
    }
  }, [filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "2%",
        paddingTop: "1%",
        height: "98%",
        width: "98%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "100%" }}
      >


         <SidePanel
         filter={(<LoadingOverlay
          active={loadingStatus === "pending"}
          spinner={<ClipLoader color="white" size="150px" />}
        >
       {currentFlow&&currentFlow?.registration&&    <div>
        <div className="text-small primary-color text-medium text-bold" style={{textAlign: "center"}}> Score TOTAL</div>
<div style={{textAlign: "center", alignContent: "center", alignSelf: "center", alignItems: "center", width: "100%", padding: "20px"}}>
         {currentFlow&&currentFlow?.registration&&currentFlow?.registration?.multiFactorMatrix&& <Badge 
         badgeContent={`${currentFlow?.registration?.multiFactorMatrix?.finalScore.toFixed(1)}%`}          
         classes={{ badge: classes.customBadge }}
  showZero
  >
          <Score/>
</Badge>}</div>
         {<div className="text-small primary-color" style={{textAlign: "center"}}>{`Estado de verificación: ${currentFlow&&currentFlow?.flowStatus?getStatusFlow(currentFlow?.flowStatus):""}`}</div>
         }
        <div className="text-small primary-color text-medium text-bold" style={{textAlign: "center"}}> Info Cliente</div>
        </div>}</LoadingOverlay>
)}
        
          categories={
            <CategoriesServicesComponent
              categories={
                ["Core ID", "Magic Forms", "Firma", "Adjuntos"].map((service, index) => {
                  return (
                <Category
                  key={index}
                  name={service}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(getServicePathData(service));
                  }}
                  selected={"Core ID" === service}
                />)})}
            />
          }
        />
        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />

          <FooterServices />
        </div>
      </div>
    </div>
  );
};
