import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import Login from "./features/login/Login";
import { selectBackgroundImageUrl } from "./features/customization/customizationSlice";
import "./features/common/commonStyles.scss";
import { useCookies } from "react-cookie";
import { FirmaElectronica } from "./features/services/firma/FirmaElectronica";
import { MagicForms } from "./features/services/magicforms/MagicForms";
import { Page404 } from "./features/Page404/Page404";
import { useEffect } from "react";
import {
  resetState,
  selectRoles,
  selectTokenExpiryTimestamp,
  setTokenInfo,
} from "./features/common/commonSlice";
import { TemplatesComponent } from "./features/services/magicforms/magicComponents/TemplatesComponent";
import { FilledFormsComponent } from "./features/services/magicforms/magicComponents/FilledForms";
import { FilledFormsDetail } from "./features/services/magicforms/detail/FilledFormsDetail";
import { TemplateDetail } from "./features/services/magicforms/detail/TemplateDetail";
import { Signatures } from "./features/services/firma/firmaComponents/Signatures";
import { CoreId } from "./features/services/coreid/CoreId";
import { Registrations } from "./features/services/coreid/coreIdComponents/Registrations";
import { DetailFirma } from "./features/services/firma/detail/FirmaDetail";
import { Registration } from "./features/services/coreid/detail/Registration";
import { UnifiedAlerts } from "./features/services/alerts/UnifiedAlerts";
import { CoreIdAlerts } from "./features/services/coreid/coreIdComponents/CoreIdAlerts";
import { FirmaElectronicaNew } from "./features/services/firmanew/FirmaElectronicaNew";
import { FirmasNew } from "./features/services/firmanew/firmaComponents/Firmas";
import { DetailFirmaNew } from "./features/services/firmanew/detail/FirmaNewDetail";
import { HyperFlow } from "./features/services/hyperFlow/HyperFlow";
import { SignerDetail } from "./features/services/firmanew/detail/SignerDetail";
import axiosApiInstance from "./helpers/interceptor";
import localforage from "localforage";
import { ConsultaManual } from "./features/services/coreid/coreIdComponents/ConsultaManual";
import { Introv2 } from "./features/intro/introd";
import { FlowsList } from "./features/services/hyperFlow/hyperFlowComponents/FlowsList";
//import { Flowv2 } from "./features/services/hyperFlow/detail/Flowv2";
import { HyperFlowDetail } from "./features/services/hyperFlow/HyperFlowDetail";
//import { Flowv3 } from "./features/services/hyperFlow/detail/Flowv3";
import { ListBusquedas } from "./features/services/components/ListBusquedas";
import { IntroSearch } from "./features/intro/introSearch";
import { Flowv2 } from "./features/services/hyperFlow/detail/Flowv2";
import { SmartFlow } from "./features/services/smartflow/SmartFlow";
import { Flows } from "./features/services/smartflow/smartFlowComponents/Flows";
import { Flow } from "./features/services/smartflow/detail/Flow";
import { Pagares } from "./features/services/pagares/Pagares";
import { PagaresList } from "./features/services/pagares/pagareComponents/PagaresList";
import { DetailPagare } from "./features/services/pagares/detail/PagareDetail";
import { PagareFill } from "./features/services/pagares/detail/PagareFill";
import { PagareEndoso } from "./features/services/pagares/detail/PagareEndoso";
import { PagareCreate } from "./features/services/pagares/detail/PagareCreate";

function App() {
  let dispatch = useAppDispatch();
  let backgroundImage = useAppSelector(selectBackgroundImageUrl);
  let expiryTimestamp = useAppSelector(selectTokenExpiryTimestamp);
  let roles = useAppSelector(selectRoles);
  const [cookies, _, removeCookie] = useCookies(["token"]);
  let checkCookie = async () => {
    if (cookies.token && expiryTimestamp !== -1) {
      if (expiryTimestamp < new Date().getTime()) {
        dispatch(resetState());
        delete axiosApiInstance.defaults.headers.common["Authorization"];
        await localforage.clear();
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    } else if (cookies.token && expiryTimestamp === -1) {
      dispatch(setTokenInfo(cookies.token));
    } else if (
      (!cookies || !cookies.token || cookies.token === "") &&
      expiryTimestamp !== -1
    ) {
      dispatch(resetState());
      delete axiosApiInstance.defaults.headers.common["Authorization"];
      await localforage.clear();
      if (cookies.token === "") {
        removeCookie("token", {
          path: "/",
          sameSite: "lax",
          secure: true,
        });
      }
    }
  };

  useEffect(() => {
    const intervalCookies = setInterval(() => {
      checkCookie();
    }, 3000);
    return () => clearInterval(intervalCookies);
  }, [cookies, expiryTimestamp]);
  return (
    <div
      style={{
        background: `url(${backgroundImage})`,
        height: "100vh",
        width: "100vw",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        backgroundSize: "cover",
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              cookies.token && cookies.token !== "" ? (
                <Navigate to="/intro" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={
              !cookies.token || cookies.token === "" ? (
                <Login />
              ) : (
                <Navigate to="/intro" />
              )
            }
          />
          <Route
            path="/intro"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <Introv2 />
              )
            }
          >
            <Route path="intro" element={<Introv2 />} />
          </Route>
          <Route
            path="/search/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <IntroSearch />
              )
            }
          >
            <Route path=":query" element={<ListBusquedas />} />
          </Route>
          <Route
            path="/services"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/intro" />
              )
            }
          />
          <Route
            path="/services/coreId/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <CoreId />
              )
            }
          >
            <Route path="registrations" element={<Registrations />} />
            <Route path="registrations/:id" element={<Registration />} />
            {/* <Route path="flow" element={<Flujos />} /> */}
            <Route path="alerts" element={<CoreIdAlerts />} />
          </Route>
          <Route
            path="/services/magicForms/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <MagicForms />
              )
            }
          >
            <Route path="templates" element={<TemplatesComponent />} />
            <Route path="filledForms" element={<FilledFormsComponent />} />
            <Route path="filledForms/:id" element={<FilledFormsDetail />} />
            <Route path="templates/:id" element={<TemplateDetail />} />
          </Route>
          <Route
            path="/services/electronicSignature/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <FirmaElectronica />
              )
            }
          >
            <Route path="signatures" element={<Signatures />} />
            <Route path="signatures/:id" element={<DetailFirma />} />
          </Route>
          <Route
            path="/services/electronicSignatureNew/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <FirmaElectronicaNew />
              )
            }
          >
            <Route path="signatures" element={<FirmasNew />} />
            <Route path="signatures/:id" element={<DetailFirmaNew />} />
            <Route
              path="signatures/:id/signer/:signerId"
              element={<SignerDetail />}
            />
          </Route>
          <Route
            path="/services/pagares/"
            element={redirectBasedOnRolesOrCookies(
              cookies,
              roles,
              ["pagare.view"],
              <Pagares />
            )}
          >
            <Route path="list" element={<PagaresList />} />
            <Route path="list/:id" element={<DetailPagare />} />
            <Route path="fill/:id" element={<PagareFill />} />
            <Route path="endosar/:id" element={<PagareEndoso />} />
            <Route path="create" element={<PagareCreate />} />
          </Route>
          <Route
            path="/services/hyperFlow/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <HyperFlow />
              )
            }
          >
            <Route path="flows" element={<FlowsList />} />
          </Route>

          <Route
            path="/services/hyperFlow/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <HyperFlowDetail />
              )
            }
          >
            <Route path="flows/:id" element={<Flowv2 />} />
          </Route>
          <Route
            path="/services/smartFlow/"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <SmartFlow />
              )
            }
          >
            <Route path="flows" element={<Flows />} />
            <Route path="flows/:id" element={<Flow />} />
          </Route>
          <Route
            path="services/alerts"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <UnifiedAlerts />
              )
            }
          />
          <Route
            path="/*"
            element={
              !cookies.token || cookies.token === "" ? (
                <Navigate to="/login" />
              ) : (
                <Page404 />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

function redirectBasedOnRolesOrCookies(
  cookies: { token?: any },
  roles: string[],
  allowedRoles: string[],
  routeComponent: JSX.Element
) {
  if (!cookies.token || cookies.token === "") {
    return <Navigate to="/login" />;
  }
  for (const allowedRole of allowedRoles) {
    if (roles.includes(allowedRole)) {
      break;
    }
  }
  return routeComponent;
}

export default App;
