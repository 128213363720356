import "bootstrap/dist/css/bootstrap.min.css";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeActiveComponent,
  confirmOtp,
  endosar,
  getDocumentsPagare,
  getPagareById,
  resetFields,
  resetPagareDetail,
  selectActivePagareComponent,
  selectConfirmationId,
  selectCurrentPagare,
  selectEndosado,
  selectEndosamiento,
  selectLoadingDocuments,
  selectLoadingFields,
  selectLoadingPagares,
  selectOtpLoading,
  selectSendAgain,
  selectSentOtp,
  sendOtpConfirm,
  setEndosamiento,
} from "../pagareSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { SmallSquare } from "../../firma/detail/components/Squares";
import {
  getStatusPagareName,
  getTipoPagareName,
} from "../../firma/helpers/utils";
import OtpInput from "react-otp-input-rc-17";
import Modal, { Styles } from "react-modal";
import { DropDownPagare } from "../../../common/components/Btns/DropDown";
import { InputText } from "./components/EndosoInputs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "30%",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "2%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const PagareEndoso = () => {
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let documentsLoading = useAppSelector(selectLoadingDocuments);
  let loadingStatusFields = useAppSelector(selectLoadingFields);
  let currentPagare = useAppSelector(selectCurrentPagare);
  let sentOtp = useAppSelector(selectSentOtp);
  let otpLoading = useAppSelector(selectOtpLoading);
  let sendAgain = useAppSelector(selectSendAgain);
  let confirmationId = useAppSelector(selectConfirmationId);
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let endosamiento = useAppSelector(selectEndosamiento);
  let endosado = useAppSelector(selectEndosado);

  const [otpValue, setOtpValue] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [beginCounter, setBeginCounter] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    dispatch(resetPagareDetail());
    navigate(-1);
  };

  const [disabledInputs, setDisabledInputs] = useState(false);

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let { id } = useParams();

  useEffect(() => {
    if ((!currentPagare || currentPagare.id !== id) && id !== "") {
      dispatch(getPagareById(id || ""));
    }

    if (currentPagare && documentsLoading === "idle") {
      dispatch(getDocumentsPagare(currentPagare.id));
    }

    if (sentOtp) {
      setDisabledInputs(true);
    }

    if (confirmationId && confirmationId !== "") {
      dispatch(endosar(confirmationId));
    }

    if (endosado) {
      setModalIsOpen(true);
    }

    let timer = setTimeout(() => {
      if (beginCounter) {
        setShowResend(true);
      }
    }, 16000);
    if (activeComponent == "none") {
      dispatch(changeActiveComponent("none"));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [id, showResend, beginCounter, confirmationId, sentOtp]);

  return (
    <LoadingOverlay
      active={
        loadingStatus === "pending" ||
        loadingStatusFields === "pending" ||
        otpLoading === "pending"
      }
      spinner={<ClipLoader color="white" size="100px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de búsqueda"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Se ha iniciado el proceso de endoso del pagaré.
          </div>
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
          >
            Para revisar el estado del endoso cierra esta ventana y revisa el
            estado del pagaré.
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
      {currentPagare && loadingStatus === "resolved" ? (
        <div
          className="flex-col"
          style={{ height: "100%", overflow: "auto", width: "100%" }}
        >
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetFields());
                navigate(-1);
              }}
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <LeftArrow style={{ marginRight: "15px" }} />
              <div className="text-bold text-xx-large primary-color">
                Regresar al detalle del pagaré
              </div>
            </div>
          </div>
          <div className="flex-row" style={{ height: "100%", width: "100%" }}>
            <div
              className="flex-col"
              style={{
                height: "85%",
                width: "44%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  justifyContent: "start",
                  height: "14%",
                }}
              >
                <SmallSquare
                  parentWidth="42%"
                  title="Tipo de Pagaré"
                  content={<div>{getTipoPagareName(currentPagare.type)}</div>}
                />
                <SmallSquare
                  parentWidth="26%"
                  title="Estado"
                  content={
                    <div>{getStatusPagareName(currentPagare.status)}</div>
                  }
                />
                <SmallSquare
                  parentWidth="26%"
                  title="Valor"
                  content={
                    <div>
                      {`${
                        currentPagare.value === "0"
                          ? "N/A"
                          : currentPagare.value
                      } ${
                        currentPagare.currency && currentPagare.currency !== ""
                          ? currentPagare.currency
                          : ""
                      }`}
                    </div>
                  }
                />
              </div>
              <div
                className="flex-col"
                style={{
                  width: "96%",
                  height: "35%",
                  marginRight: "1%",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    textAlignLast: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Datos del deudor
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    textAlign: "center",
                    color: "white",
                    background: "rgba(0,0,0,0.2)",
                    justifyContent: "center",
                    alignItems: "start",
                    boxSizing: "border-box",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "7%",
                    paddingRight: "7%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{ width: "40%", alignItems: "start" }}
                    >
                      <div className="text-x-thin text-medium  primary-color">
                        Nombre:
                      </div>
                      <div className="text-x-thin text-medium  primary-color">
                        Apellido:
                      </div>
                      <div className="text-x-thin text-medium  primary-color">
                        No. de documento:
                      </div>
                      <div className="text-x-thin text-medium  primary-color">
                        No. de celular:
                      </div>
                      <div className="text-x-thin text-medium  primary-color">
                        Email:
                      </div>
                      <div className="text-x-thin text-medium  primary-color">
                        User ID:
                      </div>
                    </div>
                    <div
                      className="flex-col"
                      style={{ width: "55%", alignItems: "start" }}
                    >
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].nombre}
                      </div>
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].apellido}
                      </div>
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].identification}
                      </div>
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].phoneNumber}
                      </div>
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].email}
                      </div>
                      <div className="text-x-thin text-medium primary-color">
                        {currentPagare.deudores[0].userId}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-col"
              style={{
                height: "95%",
                width: "54%",
                justifyContent: "start",
                padding: "1%",
              }}
            >
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "2%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    textAlignLast: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    borderRadius: "8px 8px 0px 0px",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Datos del endosatario
                  </div>
                </div>
                <div
                  style={{
                    color: "white",
                    background: "rgba(0,0,0,0.2)",
                    boxSizing: "border-box",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "95%",
                    borderRadius: "0px 0px 8px 8px ",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gridAutoRows: "minmax(min-content, max-content)",
                      overflow: "auto",
                      height: "100%",
                    }}
                  >
                    <DropDownPagare
                      title="Tipo de documento"
                      defaultValue={{
                        value: "",
                        label: "Seleccione una opción",
                      }}
                      options={[
                        { label: "Cedula de ciudadanía", value: "cc" },
                        { label: "NIT", value: "nit" },
                      ]}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            identificationType: e?.value!,
                          })
                        );
                      }}
                    />
                    <InputText
                      label="No. de Documento"
                      value={endosamiento.identification}
                      disabled={disabledInputs}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            identification: e.target.value,
                          })
                        );
                      }}
                    />
                    <DropDownPagare
                      title="Tipo de persona"
                      defaultValue={{
                        value: "",
                        label: "Seleccione una opción",
                      }}
                      options={[
                        { label: "Natural", value: "Natural" },
                        { label: "Jurídica", value: "Juridica" },
                      ]}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            naturalPerson: e?.value == "Natural" ? true : false,
                          })
                        );
                      }}
                    />

                    <InputText
                      label="Valor de compra"
                      disabled={disabledInputs}
                      value={endosamiento.sellValue.toString()}
                      onChange={(e) => {
                        var reg = new RegExp(/^\d+$/);
                        if (
                          reg.test(e.target.value) === true ||
                          e.target.value == ""
                        ) {
                          dispatch(
                            setEndosamiento({
                              ...endosamiento,
                              sellValue:
                                e.target.value == ""
                                  ? 0
                                  : Number.parseFloat(e.target.value),
                            })
                          );
                        }
                      }}
                    />
                    <div className="flex-col">
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "white",

                          marginRight: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        Con responsabilidad
                      </div>
                      <div className="flex-row" style={{ marginLeft: "10px" }}>
                        <FormControlLabel
                          style={{ height: "17px" }}
                          control={<Radio size="small" onClick={() => {}} />}
                          label={
                            <div className="text-small text-normal primary-color">
                              Si
                            </div>
                          }
                          checked={endosamiento.withResponsability}
                          onChange={(e) => {
                            dispatch(
                              setEndosamiento({
                                ...endosamiento,
                                withResponsability: true,
                              })
                            );
                          }}
                        />
                        <FormControlLabel
                          style={{ height: "17px" }}
                          control={<Radio size="small" onClick={() => {}} />}
                          label={
                            <div className="text-small text-normal primary-color">
                              No
                            </div>
                          }
                          checked={!endosamiento.withResponsability}
                          onChange={(e) => {
                            dispatch(
                              setEndosamiento({
                                ...endosamiento,
                                withResponsability: false,
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                    <InputText
                      label="Correo electrónico"
                      disabled={disabledInputs}
                      value={endosamiento.email}
                      invisible={!endosamiento.naturalPerson}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            email: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "rgba(255,255,255,0.5)",
                      visibility: !endosamiento.naturalPerson
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Datos Representante legal
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gridAutoRows: "minmax(min-content, max-content)",
                      overflow: "auto",
                      height: !endosamiento.naturalPerson ? "100%" : "0%",
                      visibility: !endosamiento.naturalPerson
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <DropDownPagare
                      title="Tipo de documento"
                      invisible={endosamiento.naturalPerson}
                      defaultValue={{
                        value: "",
                        label: "Seleccione una opción",
                      }}
                      options={[
                        { label: "Cedula de ciudadanía", value: "cc" },
                        { label: "NIT", value: "nit" },
                      ]}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            idTypeLegalRepresentative: e?.value!,
                          })
                        );
                      }}
                    />
                    <InputText
                      label="No. de Documento"
                      invisible={endosamiento.naturalPerson}
                      value={endosamiento.identification}
                      disabled={disabledInputs}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            idLegalRepresentative: e.target.value,
                          })
                        );
                      }}
                    />
                    <InputText
                      label="Correo electrónico"
                      disabled={disabledInputs}
                      value={endosamiento.email}
                      invisible={endosamiento.naturalPerson}
                      onChange={(e) => {
                        dispatch(
                          setEndosamiento({
                            ...endosamiento,
                            email: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      visibility: sentOtp ? "visible" : "hidden",
                      height: !showResend ? "0%" : "",
                    }}
                  >
                    <OtpInput
                      value={otpValue}
                      onChange={(otp: any) => {
                        setOtpValue(otp);
                      }}
                      numInputs={5}
                      inputStyle={{
                        background: "rgba(0,0,0,0.2)",
                        border: "none",
                        height: "47px",
                        width: "45px",
                        borderRadius: "6px",
                        marginRight: "7px",
                        color: "white",
                      }}
                      containerStyle={{
                        marginBottom: "8px",
                        marginLeft: "2%",
                      }}
                      isInputNum={true}
                    />
                    <div
                      style={{
                        marginLeft: "2%",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "white",
                      }}
                    >
                      Ingrese el código que llegará a tu correo electrónico
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        justifyContent: "end",
                        alignItems: "center",
                        visibility: showResend ? "visible" : "hidden",
                      }}
                    >
                      <div
                        style={{
                          color: "rgba(255,255,255,0.35)",
                          fontSize: "14px",
                          fontWeight: "700",
                          textAlign: "center",
                          maxWidth: "220px",
                          marginBottom: "9px",
                        }}
                      >
                        Si el código no ha llegado solicita re-eenviar el código
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            sendOtpConfirm({
                              pagareRootId: currentPagare?.id!,
                              sendAgain,
                            })
                          );
                        }}
                        style={{
                          border: "none",
                          background: "rgba(0,0,0,0.2)",
                          borderRadius: "6px",
                          color: "white",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "700",
                          height: "35px",
                          width: "155px",
                        }}
                      >
                        Reenviar código
                      </button>
                    </div>
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "rgba(0,0,0,0.2)",
                        borderRadius: "6px",
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "35px",
                        width: "155px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (sentOtp) {
                          dispatch(
                            confirmOtp({
                              code: otpValue,
                              pagareRootId: currentPagare?.id!,
                            })
                          );
                        } else {
                          dispatch(
                            sendOtpConfirm({
                              pagareRootId: currentPagare?.id!,
                              sendAgain,
                            })
                          );
                          setBeginCounter(true);
                        }
                      }}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
