import { getServicePath, getServicePathData } from "../../../helpers/utils";
import { ProductTitle, Service } from "../../common/commonSlice";
import "../introStyles.scss";
import { Outlet, useNavigate } from "react-router-dom";

export interface ListServiceProps {
  serviceName: ProductTitle;
  margin: string;
  size: string; //text-xx-large
  color: string; //primary-color
  activo: boolean; 
}

export const ListService = (props: ListServiceProps) => {
  let navigate = useNavigate();

  return (
    <div className="flex-col single-inactive-service"
    style={{ marginTop: props.margin}}
    >
      <h6
        className={`text-x-bold ${props.size} ${props.color} `}
        style={{ marginBottom: "1px", cursor: "pointer"}}
        onClick={(e) => {
          e.preventDefault();
          navigate(getServicePathData(props.serviceName));
        }}
      >
        {props.serviceName}
      </h6>
      <div
        className={`${props.activo?"activate-service":"inactivate-service"} text-bold text-xx-small primary-color`}
        onClick={(e) => {
          e.preventDefault();
          const link = document.createElement("a");
          link.href = "https://www.anteia.com.co/productactivation";
          link.target = "_blank";
          link.click();
        }}
        style={{ cursor: "pointer" }}
      >
{props.activo?"SERVICIO ACTIVO":"ACTIVAR SERVICIO"}
      </div>
    </div>
  );
};
